import { get, merge } from 'lodash';
import router from '../router';

const solveErrors = (response) => {
  if (process.env.NODE_ENV === 'development') {
    console.log('\n\nRESPONSE:', response, '\n\n');
  }

  const contentType = response.headers.get('content-type');
  const result =
    contentType && contentType.includes('json') ?
      response.json() :
      response.text();

  return result.then((parsed) => {
    if (response.status >= 200 && response.status < 300) {
      return Promise.resolve(parsed);
    }

    if (response.status === 401) {
      // window.location = '/';
      router.push('/login');
    }

    const error = {
      status: response.status,
      errorCode: get(parsed, 'error_code'),
      detail: get(parsed, 'detail'),
      name: parsed.error.name,
      message: parsed.error.message
    };

    return Promise.reject(error);
  });
};

const apiRequest = (apiPath, options) => {
  const mainOptions = {
    headers: {
      Accept: 'application/json'
    },
    mode: 'cors'
  };

  const finalOptions = merge(mainOptions, options);

  if (finalOptions.body) {
    finalOptions.body = JSON.stringify(finalOptions.body);
    finalOptions.headers['Content-Type'] = 'application/json';
  }

  // default URL is local host
  let url = 'http://localhost:1337';

  // if ZDROJE URL is defined, use it instead
  if (process.env.VUE_APP_BACKEND_URL) {
    url = process.env.VUE_APP_BACKEND_URL;
  }
  const requestUrl = url + apiPath;

  if (process.env.NODE_ENV === 'development') {
    console.log(`\n\nAPI REQUEST to URL: ${requestUrl}
      with option: `, finalOptions, '\n\n');
  }

  return fetch(requestUrl, finalOptions).then((response) =>
    solveErrors(response)
  );
};

export default apiRequest;
