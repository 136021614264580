import 'regenerator-runtime/runtime';
import apiRequest from './apiRequest';

export const authCustomService = {
  forgotPassword,
  resetPassword
};

/**
 * Sends to backend request for generating email with the link for reset password.
 *
 * @param {*} email user's email.
 * @returns
 */
function forgotPassword(email) {
  const request = {
    method: 'POST',
    body: {
      email: email
    }
  };
  return apiRequest('/api/auth-custom/forgot-password', request);
};

/**
 * Sends to backend request for store new password.
 *
 * @param {*} token token identifying the user whose password will be changed,
 * @param {*} newPassword a new user's password.
 * @returns
 */
function resetPassword(token, newPassword) {
  const request = {
    method: 'PUT',
    body: {
      token: token,
      newPassword: newPassword
    }
  };
  return apiRequest('/api/auth-custom/reset-password', request);
};
