<template>
    <v-container class="my-16" style="max-width:1024px;">
      <v-row justify="center">
        <v-card  class="pa-10" outlined flat rounded color="amber lighten-4" max-width="380">
          <v-card-title>
            <v-row justify="center">
              <v-img max-height="32" max-width="32" src="@/assets/logo-in-life.png" alt="In-Life logo"></v-img>
              <span class="mx-1 text--primary text-button">Zdroje</span>
            </v-row>
          </v-card-title>
          <v-form @submit.prevent="handleSubmit" class="mt-6">
            <p class="text--secondary text-body-2">
              Pro přístup ke zdrojům <b>In-Life</b> je nutné zadat uživatelské jméno a heslo.
            </p>
            <v-text-field
              v-model="username"
              label="Uživatelské jméno"
              :rules="[() => !!username || 'Pole je povinné']"
              required color="amber"
            />

            <v-text-field
              v-model="password"
              label="Heslo"
              :rules="[() => !!password || 'Pole je povinné']"
              required color="amber"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              @click:append="show = !show"
            />
            <v-row class="mt-n5 float-right">
              <v-btn href="/forgot-password" text plain x-small>Zapoměli jste heslo?</v-btn>
            </v-row>
            <v-row>
              <v-btn href="/register" x-small plain tile class="mt-10 ml-2 mx-auto">
                Registrovat
              </v-btn>
              <v-btn
                class="mt-8 float-right mr-2"
                elevation="0"
                :disabled="username && password ? false : true"
                @click="handleSubmit"
                color="amber lighten-2">
                Přihlásit
              </v-btn>
            </v-row>
          </v-form>
        </v-card>
      </v-row>
      <v-card class="text-center text--secondary mt-8 mx-auto" flat max-width="350">
        <small>Původní zdroje budou dočasně přístupné na adrese
        <a href="https://zdroje.in-life.org/" class="text--secondary font-weight-bold" target="_blank" style="white-space: nowrap">zdroje.in-life.org</a>.
        V&nbsp;případě potíží s&nbsp;přihlášením nebo s&nbsp;přístupen na tento portál pište na adresu
        <a href="mailto:admin@in-life.org" class="text--secondary font-weight-bold" target="_blank" style="white-space: nowrap">admin@in-life.org</a>.</small>
      </v-card>
      <v-divider class="mt-16 mb-8"></v-divider>
      <v-row justify="center">
        <h2 class="text-h5">Veřejně dostupné zdroje</h2>
      </v-row>
      <v-row class="mt-8">
        <v-col cols="12" md="4" lg="4" xl="4">
            <v-card color="amber lighten-4" flat outlined rounded>
              <v-card-title><v-icon class="mx-1">mdi-web</v-icon> Weby</v-card-title>
              <v-list>
                <v-list-item
                  href="https://www.in-life.org/archive"
                  target="_blank"  class="text--secondary"
                >
                  <v-icon color="amber">mdi-chevron-right</v-icon> Archiv In-Life
                </v-list-item>
                <v-list-item
                  href="https://pluska.org/nedelni-zamysleni/"
                  target="_blank"  class="text--secondary"
                >
                  <v-icon color="amber">mdi-chevron-right</v-icon> Archiv PLUSKY
                </v-list-item>
              </v-list>
            </v-card>
        </v-col>
        <v-col cols="12" md="4" lg="4" xl="4">
            <v-card color="amber lighten-4" flat outlined rounded>
              <v-card-title><v-icon class="mx-1">mdi-headphones</v-icon>Spotify</v-card-title>
              <v-list>
                <v-list-item
                  href="https://open.spotify.com/show/0bqhTs9s8UXFDNvWdbBfkz?si=80ec283f878245d1"
                  target="_blank"  class="text--secondary"
                >
                  <v-icon color="amber">mdi-chevron-right</v-icon> Podcast&nbsp;<b>tak ako teda?</b>
                </v-list-item>
                <v-list-item
                  href="https://open.spotify.com/show/1jJnNFkNQMOPDVBo0Dew77?si=e859768fc275479d"
                  target="_blank"  class="text--secondary"
                >
                  <v-icon color="amber">mdi-chevron-right</v-icon> Přednášky pro VŠ studenty
                </v-list-item>
                <v-list-item
                  href="https://open.spotify.com/show/0BaBbe5spQkkrN0ZKHV8XH?si=2df8bd7c7b654911"
                  target="_blank"  class="text--secondary"
                >
                  <v-icon color="amber">mdi-chevron-right</v-icon> Nedělní zamyšlení
                </v-list-item>
              </v-list>
            </v-card>
        </v-col>
        <v-col cols="12" md="4" lg="4" xl="4">
            <v-card color="amber lighten-4" flat outlined rounded>
              <v-card-title><v-icon class="mx-1">mdi-youtube</v-icon> Youtube</v-card-title>
              <v-list>
                <v-list-item
                  href="https://www.youtube.com/user/inlifevideo/featured"
                  target="_blank"  class="text--secondary"
                >
                  <v-icon color="amber">mdi-chevron-right</v-icon> Studentská videa
                </v-list-item>
                <v-list-item
                  href="https://www.youtube.com/channel/UCL2Z4Rx-pdjsY09eR3OSOzQ"
                  target="_blank"  class="text--secondary"
                >
                  <v-icon color="amber">mdi-chevron-right</v-icon> Videa z PLUSKY
                </v-list-item>
              </v-list>
            </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      username: '',
      password: '',
      show: false
    };
  },
  methods: {
    ...mapActions('account', ['login', 'logout']),
    handleSubmit(e) {
      const username = this.username;
      const password = this.password;

      if (username && password) {
        this.login({ username, password });
      }
    }
  },
  created() {
    this.logout();
  }
};
</script>
