import { userService } from '../../services';
import router from '../../router';
import { ERROR_ACTION, LOGIN, LOGOUT, REGISTER, SUCCESS_ACTION, UPDATE_USER } from '../actions';

import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  SET_USER
} from '../mutations';

import { getCurrentUser } from '../../utils/local-storage-getter';

const user = getCurrentUser();

const state = user ?
  { status: { loggedIn: true }, user } :
  { status: {}, user: null };

const actions = {
  [UPDATE_USER]({ commit }, newUser) {
    commit(SET_USER, newUser);
  },
  [LOGIN]({ dispatch, commit }, { username, password }) {
    commit(LOGIN_REQUEST, { username });

    return userService.login(username, password).then(
        (user) => {
          commit(LOGIN_SUCCESS, user);
          router.push('/');
          setTimeout(() => {
            dispatch('alert/'+SUCCESS_ACTION, 'Přihlášení proběhlo úpešně.', { root: true });
          });
        },
        (error) => {
          commit(LOGIN_FAILURE, error);
          dispatch('alert/'+ERROR_ACTION, 'Chybné jméno nebo heslo!', { root: true });
        }
    );
  },
  [LOGOUT]({ commit }) {
    userService.logout();
    commit(LOGOUT);
  },
  [REGISTER]({ dispatch, commit }, user) {
    commit(REGISTER_REQUEST, user);

    userService.register(user).then(
        (user) => {
          commit(REGISTER_SUCCESS, user);
          router.push('/registered');
          setTimeout(() => {
            // display success message after route change completes
            dispatch('alert/'+SUCCESS_ACTION, 'Registrace proběhla úspěšně', { root: true });
          });
        },
        (error) => {
          commit(REGISTER_FAILURE, error);
          dispatch('alert/'+ERROR_ACTION, 'Registrace se nezdařila: ' + error.message, { root: true });
        }
    );
  }
};

const mutations = {
  [LOGIN_REQUEST](state, user) {
    state.status = { loggingIn: true };
    state.user = user;
  },
  [LOGIN_SUCCESS](state, user) {
    state.status = { loggedIn: true };
    state.user = user;
  },
  [LOGIN_FAILURE](state) {
    state.status = {};
    state.user = null;
  },
  [LOGOUT](state) {
    state.status = {};
    state.user = null;
  },
  [REGISTER_REQUEST](state, user) {
    state.status = { registering: true };
  },
  [REGISTER_SUCCESS](state, user) {
    state.status = {};
  },
  [REGISTER_FAILURE](state, error) {
    state.status = {};
  },
  [SET_USER](state, user) {
    state.user = user;
  }
};

export const account = {
  namespaced: true,
  state,
  actions,
  mutations
};
