<template>
  <div class>
    <navigation-menu></navigation-menu>
    <context-menu-home></context-menu-home>
    <span class="text-h6 text-md-h5 text-lg-h4 font-weight-light d-flex justify-center mt-md-3 mt-lg-6 mb-lg-3">Vítej ve zdrojích In-Life</span>
    <!-- <v-expansion-panels
      flat
      class="my-n4 ml-6 justify-center"
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span class="text-h6 font-weight-light">Vítej v databázi zdrojů In-Life.</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="text--secondary font-weight-light">
          Databáze obsahuje video/audio záznamy přednášek a seminářů,
          prezentace, studia a další materiály. Pro vyhledádvání podle názvu nebo štítků stačí
          začít psát hledané slovo/frázi do pole <v-icon small>mdi-magnify</v-icon>. Chceš-li
          vyhledávání omezit tématem, řečníkem nebo datem, klikni na
          <i><v-icon small>mdi-cog</v-icon> Možnosti hledání</i>. Pokud bys chtěl seznam řečníků
          <v-icon small>mdi-account</v-icon> nebo témat <v-icon small>mdi-format-list-bulleted</v-icon>
          použij položku v menu.
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels> -->
    <search-bar></search-bar>
    <resource-container></resource-container>
  </div>
</template>

<script>
import SearchBar from './SearchBar.vue';
import ResourceContainer from './ResourceContainer.vue';
import NavigationMenu from './NavigationMenu.vue';
import ContextMenuHome from './ContextMenuHome.vue';

export default {
  components: {
    SearchBar,
    ResourceContainer,
    NavigationMenu,
    ContextMenuHome
  }
};
</script>

