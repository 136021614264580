<template>
  <div>
    <navigation-menu></navigation-menu>
    <v-container class="px-1 py-0" fluid>
      <h1 class="text-h6 ma-sm-3 text-sm-h4 font-weight-regular d-flex justify-center">Sdílení</h1>
      <v-divider class="amber lighten-4 mx-auto"></v-divider>
      <p class="text--disabled mt-4 text-body-2">
        Sdílení umožňuje <b>poskytnout dočasně přístup</b> ke konkrétním zdrojům osobám,
        které buď nejsou uživateli portálu <b>In-life::Zdroje</b> a nebo jsou registrovanými uživateli, ale nemají
        dostatečná práva. Chcete-li <b>zrušit sdílení</b> použijte <v-icon small title="Přestat sdílet ...">mdi-share-off</v-icon>.
      </p>
      <v-tabs
        v-model="tab"
        background-color="transparent"
        color="amber"
        grow class="mt-4"
      >
        <v-tab title="Sdíleno se mnou">
          <v-icon class="px-2">mdi-account-multiple-outline</v-icon>
          <span class="d-none d-sm-flex">
            Sdíleno se mnou ({{ sharedWithCount }})
          </span>
        </v-tab>
        <v-tab title="Sdílím ostatním">
          <v-icon class="px-2">mdi-share</v-icon>
          <span class="d-none d-sm-flex">
            Sdílím ostatním ({{ iShareCount }})
          </span>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item class="mt-4">
          <div v-if="sharedWith.length > 0">
            <sharing-item
              v-for="sharing in sharedWith" :key="sharing.id"
              :entry="sharing" type="sharedWithMe"
              @show-shared-resource="showSharedResource(sharing.token)"
              class="my-2"
            >
            </sharing-item>
          </div>
          <p v-else class="text--disabled pt-8 text-center">
            Nemáte přístup ke sdíleným zdrojům.
          </p>
        </v-tab-item>
        <v-tab-item class="mt-4">
          <div v-if="iShare.length > 0">
          <sharing-item
            v-for="sharing in iShare" :key="sharing.id"
            :entry="sharing" type="iShare"
            @unshare="unshare(sharing)"
            @show-resource="showResource(sharing.resID)"
            class="my-2"
          >
          </sharing-item>
          </div>
          <p v-else class="text--disabled pt-6 text-center">
            Nesdílíte žádné zdroje.
          </p>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { sharingService } from '../services/sharings.service';
import NavigationMenu from './NavigationMenu.vue';
import SharingItem from './SharingItem.vue';

export default {
  components: { NavigationMenu, SharingItem },
  data() {
    return {
      sharedWith: [],
      iShare: [],
      tab: null
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      sharingCounts: (state) => state.resources.sharingCounts
    }),
    iShareCount() {
      return this.iShare.length;
    },
    sharedWithCount() {
      return this.sharedWith.length;
    }
  },
  methods: {
    ...mapActions('alert', {
      successAction: 'successAction',
      errorAction: 'errorAction'
    }),
    ...mapActions('resources', {
      storeSharingCounts: 'storeSharingCounts'
    }),
    showResource(resID) {
      this.$router.push('/resource/'+resID); // přesměruje na detail zdroje
    },
    showSharedResource(token) {
      this.$router.push('/sharing?token='+token);
    },
    unshare(sharing) {
      sharingService.unshare(sharing.id)
          .then(() => {
            this.iShare.splice(this.iShare.indexOf(sharing), 1);
            this.successAction('Sdílení bylo zrušeno.');
          })
          .catch((response) => {
            this.errorAction('Nelze zrušit sdílení: '+response.status);
          });
    }
  },
  created() {
    sharingService.sharings()
        .then((result) => {
          const resIDs = [];
          result.data.forEach((sharing)=>{
            if (sharing.shareTo == this.user.email) {
              this.sharedWith.push({
                'id': sharing.id,
                'token': sharing.hash,
                'resID': sharing.resID,
                'resTitle': sharing.resource.title,
                'sharer': sharing.sharerUID,
                'validTo': new Date(sharing.validTo)
              });
            } else {
              this.iShare.push({
                'id': sharing.id,
                'resID': sharing.resID,
                'resTitle': sharing.resource.title,
                'shareTo': sharing.shareTo,
                'validTo': new Date(sharing.validTo)
              });
            }
            resIDs.push(sharing.resID);
          });
          const counts = {
            sharedWithMe: this.sharedWith.length,
            iShare: this.iShare.length
          };
          this.storeSharingCounts(counts);
        });
  }
};
</script>

<style>
</style>
