export const LOGIN = 'login';
export const LOGOUT = 'logout';
export const REGISTER = 'register';
export const UPDATE_USER = 'updateUser';
export const SUCCESS_ACTION = 'successAction';
export const ERROR_ACTION = 'errorAction';
export const CLEAR_ACTION = 'clearAction';
export const STORE_SELECTED = 'storeSelected';
export const CLEAN_SELECTED = 'cleanSelected';
export const STORE_IDS = 'storeIDs';
export const CLEAN_IDS = 'cleanIDs';
export const STORE_SHARING_COUNTS = 'storeSharingCounts';
export const CLEAN_SHARING_COUNTS = 'cleanSharingCounts';
