<template>
    <div>
      <navigation-menu></navigation-menu>
      <v-container class="px-1 py-0" fluid>
        <h1 class="text-h6 ma-sm-3 text-sm-h4 font-weight-regular d-flex justify-center">Materiály</h1>
        <v-divider class="amber lighten-4 mx-auto"></v-divider>
        <p class="text--disabled mt-4 text-body-2">
          Přehled materiálů a studíi dostupných na zdrojích. Na začatku je to radené podle 4 fáze duchvního růstu.
          Na konci stranky najdete další bonusové materiály
        </p>

        <h3 class="ml-5 my-7 text-sm-h5 font-weight-regular">4 fáze duchvního růstu</h3>
        <v-expansion-panels
          v-model="panel"
          multiple
        >
          <v-expansion-panel>
            <v-expansion-panel-header color="grey lighten-3">
              <v-row class="d-flex align-center">
                <v-col cols="12" md="4"  class="text-h6" >
                  Nemluvně (nový věřící)
                </v-col>
                <v-col cols="12" md="4">
                  Fáze: získán
                </v-col>
                <v-col cols="12" md="4">
                  Role vedoucího: laskavý, pečující (jako máma)
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="ma-3 ">
              <v-row cols="12">
                <v-col cols="12" md="3">
                  <v-card elevation="0" class="pa-2  mx-3" >
                    <v-col>
                      <v-row><b>Teologické pravdy (učení)</b></v-row>
                      <v-row>Osobnost Ježíše Krista</v-row>
                      <v-row>Víra</v-row>
                      <v-row>Spasení</v-row>
                      <v-row>Hřích a odpuštění</v-row>
                      <v-row>Duch svatý</v-row>
                      <v-row>Duchovní růst</v-row>
                      <v-row>Společenství</v-row>
                      <v-row>Svědectví</v-row>
                      <v-row>Křest</v-row>
                    </v-col>
                  </v-card>
                </v-col>
                <v-col cols="12" md="3">
                  <v-card elevation="0" class="pa-2  mx-3">
                    <v-col>
                      <v-row><b>Osobní vztah s Bohem</b></v-row>
                      <v-row>Modlitba - začít</v-row>
                      <v-row>Boží slovo - číst</v-row>
                      <v-row>Tichý čas - naučit se mít</v-row>
                      <v-row>Vyznávání hříchů</v-row>
                    </v-col>
                  </v-card>
                </v-col>
                <v-col cols="12" md="3">
                  <v-card elevation="0" class="pa-2  mx-3">
                    <v-col>
                      <v-row><b>Charakter (postoje) </b></v-row>
                      <v-row>Poslušnost Božímu slovu</v-row>
                      <v-row>Ochota učit se</v-row>
                      <v-row>Otevřenost pravdě</v-row>
                    </v-col>
                  </v-card>
                </v-col>
                <v-col cols="12" md="3">
                  <v-card elevation="0" class="pa-2  mx-3">
                    <v-col>
                      <v-row><b>Dovednosti ve službě </b></v-row>
                    </v-col>
                  </v-card>
                </v-col>
              </v-row>
              <v-row cols="12">
                <v-col cols="12" md="3">
                  <v-card elevation="0" class="pa-2  mx-3" >
                    <v-col>
                      <v-row>
                        <b>Studia</b>
                      </v-row>
                      <v-row>
                        <v-btn small href="/resource/1508" color="amber lighten-2" class="rounded-lg text-none px-1 mt-1" elevation="0" >
                          Evangelizační podle Jana
                        </v-btn>
                      </v-row>
                      <v-row>
                        <v-btn small href="/resource/1522" color="amber lighten-2" class="rounded-lg text-none px-1 mt-1" elevation="0" >
                          Základy křesťanského života
                        </v-btn>
                      </v-row>
                      <v-row>
                        <v-btn small href="/resource/1516" color="amber lighten-2" class="rounded-lg text-none px-1 mt-1" elevation="0" >
                          1. list Tesalonickým
                        </v-btn>
                      </v-row>
                      <v-row>
                        <v-btn small href="/resource/1524" color="amber lighten-2" class="rounded-lg text-none px-1 mt-1" elevation="0" >
                            Život ve světle
                        </v-btn>
                      </v-row>
                      <v-row>Kroky ke křesťanské dospělosti (0,1,2,3)</v-row>
                      <v-row>Zásady k předávání (1-3) </v-row>
                    </v-col>
                  </v-card>
                </v-col>
                <v-col cols="12" md="3">
                  <v-card elevation="0" class="pa-2  mx-3">
                    <v-col>
                      <v-row><b>Doporučené knihy</b></v-row>
                      <v-row>Josh McDowell: Více než tesař</v-row>
                      <v-row>C. S. Lewis: K jádru křesťanství</v-row>
                      <v-row>Paul Little: Víš, proč věříš?</v-row>
                      <v-row>Josh McDowell: Hodnota člověka</v-row>
                      <v-row>Corrie Ten Boom: Útočiště</v-row>
                      <v-row>Joni Eareckson: Joni</v-row>
                      <v-row>S. C. Lewis: Letopisy Narnie</v-row>
                      <v-row>John White: Duchovní bitva</v-row>
                      <v-row>Josh McDowell: Lásku dát, lásku brát, milovat</v-row>
                    </v-col>
                  </v-card>
                </v-col>
                <v-col cols="12" md="3">
                  <v-card elevation="0" class="pa-2  mx-3">
                    <v-col>
                      <v-row><b>Materiály </b></v-row>
                      <!-- <v-row>
                        <a href="/resource/30" style="color: black;">Apologetika</a>
                      </v-row> -->
                      <v-row>
                        <v-btn small href="/resource/1496" color="amber lighten-2" class="rounded-lg text-none px-1 mt-1" elevation="0" >
                          Apologetika
                        </v-btn>
                      </v-row>
                      <!-- <v-row>
                        <v-btn plain class="text-none ml-n4" href="/resource/30" >
                          Apologetika
                        </v-btn>
                      </v-row> -->
                      <v-row>
                        Nejčastější otázky o křesťanství
                      </v-row>
                    </v-col>
                  </v-card>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header color="grey lighten-3">
              <v-row class="d-flex align-center">
                <v-col cols="12" md="4"  class="text-h6" >
                  Dítě (učedník)
                </v-col>
                <v-col cols="12" md="4">
                  Fáze: budován
                </v-col>
                <v-col cols="12" md="4">
                  Role vedoucího: motivuje, povzbuzuje
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="ma-3 ">
              <v-row cols="12">
                <v-col cols="12" md="3">
                  <v-card elevation="0" class="pa-2  mx-3" >
                    <v-col>
                      <v-row><b>Teologické pravdy (učení)</b></v-row>
                      <v-row>Autorita Bible</v-row>
                      <v-row>Modlitba</v-row>
                      <v-row>Evangelizace</v-row>
                      <v-row>Místo ve společenství</v-row>
                      <v-row>Duchovní zbroj</v-row>
                      <v-row>Bůh jako Otec</v-row>
                      <v-row>Život v moci Ducha svatého</v-row>
                      <v-row>Identita v Kristu</v-row>
                    </v-col>
                  </v-card>
                </v-col>
                <v-col cols="12" md="3">
                  <v-card elevation="0" class="pa-2  mx-3">
                    <v-col>
                      <v-row><b>Osobní vztah s Bohem</b></v-row>
                      <v-row>Kroky víry</v-row>
                      <v-row>Modlitba (prav. os. i spol.)</v-row>
                      <v-row>Boží slovo (čte a rozjímá)</v-row>
                      <v-row>Učí se verše zpaměti</v-row>
                      <v-row>Je pokřtěný</v-row>
                    </v-col>
                  </v-card>
                </v-col>
                <v-col cols="12" md="3">
                  <v-card elevation="0" class="pa-2  mx-3">
                    <v-col>
                      <v-row>
                        <b>Charakter (postoje) </b>
                      </v-row>
                      <v-row>
                        Postoj následování
                      </v-row>
                      <v-row>
                        Vítězství nad hříchem
                      </v-row>
                      <v-row>
                        Úsilí o svatost
                      </v-row>
                      <v-row>
                        Touha žít z moci D. sv.
                      </v-row>
                      <v-row>
                        Láska k Bohu a lidem
                      </v-row>
                    </v-col>
                  </v-card>
                </v-col>
                <v-col cols="12" md="3">
                  <v-card elevation="0" class="pa-2  mx-3">
                    <v-col>
                      <v-row>
                        <b>Dovednosti ve službě </b>
                      </v-row>
                      <v-row>
                        Dokáže říct své svědectv=i
                      </v-row>
                      <v-row>
                        Dokáže vysvětlit evangelium
                      </v-row>
                      <v-row>
                        Praktické zodpovědnosti
                      </v-row>
                      <v-row>
                        Zapojení ve společenství
                      </v-row>
                    </v-col>
                  </v-card>
                </v-col>
              </v-row>
              <v-row cols="12">
                <v-col cols="12" md="3">
                  <v-card elevation="0" class="pa-2  mx-3" >
                    <v-col>
                      <v-row><b>Studia</b></v-row>
                      <v-row>
                        <v-btn small href="/resource/1519" color="amber lighten-2" class="rounded-lg text-none px-1 mt-1" elevation="0" >
                          Učednictví 1
                        </v-btn>
                      </v-row>
                      <v-row>
                        <v-btn small href="/resource/1524" color="amber lighten-2" class="rounded-lg text-none px-1 mt-1" elevation="0" >
                            Život ve světle
                        </v-btn>
                      </v-row>
                      <v-row>Kroky ke křesťanské dospělosti (2-10)</v-row>
                      <v-row>Zásady k předávání (4-9)</v-row>
                      <v-row>O Božím vedení - Jožka</v-row>
                    </v-col>
                  </v-card>
                </v-col>
                <v-col cols="12" md="3">
                  <v-card elevation="0" class="pa-2  mx-3">
                    <v-col>
                      <v-row><b>Další studia </b></v-row>
                      <v-row>Nejčastější otázky o křesťanství</v-row>
                      <v-row>Studia Edice 2:7 - 1. část </v-row>
                      <v-row>Studia Edice 2:7 - 2. část</v-row>
                      <v-row>Studia ke knize Boží otcovské srdce</v-row>
                      <v-row>Studia ke knize Zbožnost v praxi</v-row>
                      <v-row>Starozákonní postavy</v-row>
                      <v-row>Kázání na hoře</v-row>
                      <v-row>David, muž podle Božího srdce</v-row>
                      <v-row>Jozue</v-row>
                      <v-row>
                        <v-btn small href="/resource/1511" color="amber lighten-2" class="rounded-lg text-none px-1 mt-1" elevation="0" >
                          Milost je pro pokorné
                        </v-btn>
                      </v-row>
                      <v-row>
                        <v-btn small href="/resource/1507" color="amber lighten-2" class="rounded-lg text-none px-1 mt-1" elevation="0" >
                          Jak se zbavit starostí
                        </v-btn>
                      </v-row>
                    </v-col>
                  </v-card>
                </v-col>
                <v-col cols="12" md="3">
                  <v-card elevation="0" class="pa-2  mx-3">
                    <v-col>
                      <v-row>
                        <b>Doporučené knihy</b>
                      </v-row>
                      <v-row>
                        Colemann: Mistrův plán spasení
                      </v-row>
                      <v-row>
                        Verwer: Hlad po opravdovosti
                      </v-row>
                      <v-row>
                        Verver: Vyjít ze zóny bezpečí
                      </v-row>
                      <v-row>
                        Strobel: Kauza víra
                      </v-row>
                      <v-row>
                        Ortberg: Chcete-li kráčet po vodách, musíte...
                      </v-row>
                      <v-row>
                        McClung: Boží otcovské srdce
                      </v-row>
                      <v-row>
                        James Sire: Průvodce světovými názory
                      </v-row>
                      <v-row>
                        Neil Anderson: Vítězství nad temnotou
                      </v-row>
                      <v-row>
                        Max Lucado: Potlesk nebes
                      </v-row>
                      <v-row>
                        Max Lucado: On i dnes odvaluje kameny
                      </v-row>
                      <v-row>
                        Cloud, Townsend: Hranice v chození
                      </v-row>
                    </v-col>
                  </v-card>
                </v-col>
                <v-col cols="12" md="3">
                  <v-card elevation="0" class="pa-2  mx-3">
                    <v-col>
                      <v-row>
                        Josh McDowell: Lásku dát, lásku brát, milovat
                      </v-row>
                      <v-row>
                        <v-btn small href="/resource/1558" color="amber lighten-2" class="rounded-lg text-none px-1 mt-1" elevation="0" >
                          Myrna Alexander: Hle, Tvůj Bůh
                        </v-btn>
                      </v-row>
                      <v-row>
                        Kay Arthurová: Pane, chci tě znát (Boží jména)
                      </v-row>
                      <v-row>
                        Rick Warren: Proč jsme vůbec tady?
                      </v-row>
                      <v-row>
                        Jerry Bridges: Zbožnost v praxi
                      </v-row>
                      <v-row>
                        James Dobson: Být sám sebou
                      </v-row>
                      <v-row>
                        Timothy Keller: Svoboda sebezapomnění
                      </v-row>
                      <v-row>
                        H. a G. Taylorovi: Duchovní tajemství Hudsona Taylora
                      </v-row>
                      <v-row>
                        Joni Eareckson: Krok vpřed
                      </v-row>
                      <v-row>
                        Joni Eareckson Tada: Rozhodování - proměny
                      </v-row>
                    </v-col>
                  </v-card>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header color="grey lighten-3">
              <v-row class="d-flex align-center">
                <v-col cols="12" md="4"  class="text-h6" >
                  Mladík (služebník)
                </v-col>
                <v-col cols="12" md="4">
                  Fáze: školen
                </v-col>
                <v-col cols="12" md="4">
                  Role vedoucího: povzbuzuje a pomáhá
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="ma-3 ">
              <v-row cols="12">
                <v-col cols="12" md="3">
                  <v-card elevation="0" class="pa-2  mx-3" >
                    <v-col>
                      <v-row><b>Teologické pravdy (učení)</b></v-row>
                      <v-row>Učednictví - duch. násobení</v-row>
                      <v-row>Duchovní boj</v-row>
                      <v-row>Apologetika - základy</v-row>
                      <v-row>Dávání</v-row>
                    </v-col>
                  </v-card>
                </v-col>
                <v-col cols="12" md="3">
                  <v-card elevation="0" class="pa-2  mx-3">
                    <v-col>
                      <v-row><b>Osobní vztah s Bohem</b></v-row>
                      <v-row>Důvěra Bohu v těžkostech</v-row>
                      <v-row>Aktivně hledá Boží cesty</v-row>
                      <v-row>Studuje Bibli a ví, jak</v-row>
                      <v-row>Radost ze vztahu s Bohem</v-row>
                      <v-row>Pravidelná modlitba</v-row>
                      <v-row>Přímluvná modlitba</v-row>
                      <v-row>Rozhodnutí být rozmnožujícím učedníkem</v-row>
                    </v-col>
                  </v-card>
                </v-col>
                <v-col cols="12" md="3">
                  <v-card elevation="0" class="pa-2  mx-3">
                    <v-col>
                      <v-row><b>Charakter (postoje) </b></v-row>
                      <v-row>Postoj služebníka</v-row>
                      <v-row>Rozlišuje dobré a zlé</v-row>
                      <v-row>Dobře volí priority</v-row>
                      <v-row>Nese ovoce Ducha</v-row>
                      <v-row>Věrnost</v-row>
                    </v-col>
                  </v-card>
                </v-col>
                <v-col cols="12" md="3">
                  <v-card elevation="0" class="pa-2  mx-3">
                    <v-col>
                      <v-row><b>Dovednosti ve službě </b></v-row>
                      <v-row>Začíná vést skupinku</v-row>
                      <v-row>Aktivně evangelizuje</v-row>
                      <v-row>Zapojen ve sboru</v-row>
                      <v-row>Spoluzodpovědnost za větší akce</v-row>
                      <v-row>Apologetika</v-row>
                    </v-col>
                  </v-card>
                </v-col>
              </v-row>
              <v-row cols="12">
                <v-col cols="12" md="3">
                  <v-card elevation="0" class="pa-2  mx-3" >
                    <v-col>
                      <v-row><b>Studia</b></v-row>
                      <v-row>
                        <v-btn small href="/resource/1520" color="amber lighten-2" class="rounded-lg text-none px-1 mt-1" elevation="0" >
                          Učednictví 2
                        </v-btn>
                      </v-row>
                      <v-row>
                        <v-btn small href="/resource/1512" color="amber lighten-2" class="rounded-lg text-none px-1 mt-1" elevation="0" >
                          Nalezení a pochopení Božích záměrů - vize
                        </v-btn>
                      </v-row>
                      <v-row>Hartman, Sutherland: Průvodce učednictvím</v-row>
                      <v-row>Wise: Zbavit se starostí</v-row>
                      <v-row>Studia ke knize Úsilí o svatost</v-row>
                      <v-row>O Božím vedení - Jožka</v-row>
                    </v-col>
                  </v-card>
                </v-col>
                <v-col cols="12" md="3">
                  <v-card elevation="0" class="pa-2  mx-3">
                    <v-col>
                      <v-row>
                        <b>Materiály </b>
                      </v-row>
                      <v-row>Jak vést skupinku biblického studia</v-row>
                      <v-row>
                        <v-btn small href="/resource/1500" color="amber lighten-2" class="rounded-lg text-none px-1 mt-1" elevation="0" >
                          Čemu podléhám (test a studia)
                        </v-btn>
                      </v-row>
                      <v-row>
                        <v-btn small href="/resource/1505" color="amber lighten-2" class="rounded-lg text-none px-1 mt-1" elevation="0" >
                          Jak hřeším
                        </v-btn>
                      </v-row>
                      <v-row>
                        <v-btn small href="/resource/1518" color="amber lighten-2" class="rounded-lg text-none px-1 mt-1" elevation="0" >
                          Dotazník temperamenty
                        </v-btn>
                      </v-row>
                      <v-row>Deprese, její příčiny a léčba(z knihy Temperament a DS)</v-row>
                      <v-row>Jak zvítězit nad slabostmi (z knihy Temperament a DS)</v-row>
                      <v-row>
                        <v-btn small href="/resource/1513" color="amber lighten-2" class="rounded-lg text-none px-1 mt-1" elevation="0" >
                          Osobní cíle a plánování
                        </v-btn>
                      </v-row>
                      <v-row>
                        <v-btn small href="/resource/1525" color="amber lighten-2" class="rounded-lg text-none px-1 mt-1" elevation="0" >
                          DISC- na vyžádání
                        </v-btn>
                      </v-row>
                    </v-col>
                  </v-card>
                </v-col>
                <v-col cols="12" md="3">
                  <v-card elevation="0" class="pa-2  mx-3">
                    <v-col>
                      <v-row><b>Doporučené knihy</b></v-row>
                      <v-row>
                        <v-btn small href="/resource/1560" color="amber lighten-2" class="rounded-lg text-none px-1 mt-1" elevation="0" >
                          Henrichsen: K učedníctvu nie narodením..
                        </v-btn>
                      </v-row>
                      <v-row>A.W.Tozer: Následování Boha</v-row>
                      <v-row>Gordon Fee: Jak číst Bibli s porozuměním</v-row>
                      <v-row>Charles Thaxton: Duše vědy</v-row>
                      <v-row>Josh McDowell: Tajemství lásky</v-row>
                      <v-row>Ravi Zacharias: Lotos a kříž</v-row>
                      <v-row>G. Bergmann: Ježíš Kristus nebo Buddha, Mohamed</v-row>
                      <v-row>James Packer: Poznanie Boha</v-row>
                      <v-row>Kay Arthur: Jak studovat svou Bibli</v-row>
                    </v-col>
                  </v-card>
                </v-col>
                <v-col cols="12" md="3">
                  <v-card elevation="0" class="pa-2  mx-3">
                    <v-col>
                      <v-row>Watchmann. Nee: Osvobození Ducha</v-row>
                      <v-row>Jerry Bridges: Úsilí o svatost</v-row>
                      <v-row>John Stott: Křest a plnost (o Duchu svatém)</v-row>
                      <v-row>McCulleyová Carolyn: Svobodná a v pohodě</v-row>
                      <v-row>Timothy Keller: Falešní bohovia</v-row>
                      <v-row>Timothy Keller: Proč Bůh</v-row>
                      <v-row>Timothy Keller: Smysl manželství</v-row>
                      <v-row>A.W.Tozer: Poznání svatého</v-row>
                      <v-row>Larry Crab: Modlitba</v-row>
                      <v-row>Andrew Murray: Pokora</v-row>
                    </v-col>
                  </v-card>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header color="grey lighten-3">
              <v-row class="d-flex align-center">
                <v-col cols="12" md="4"  class="text-h6" >
                  Otec (rozmnožovatel)
                </v-col>
                <v-col cols="12" md="4">
                  Fáze: vyslán
                </v-col>
                <v-col cols="12" md="4">
                  Role vedoucího: přítel a partner
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="ma-3 ">
              <v-row cols="12">
                <v-col cols="12" md="3">
                  <v-card elevation="0" class="pa-2  mx-3" >
                    <v-col>
                      <v-row><b>Teologické pravdy (učení)</b></v-row>
                      <v-row>Duchovní vůdcovství</v-row>
                      <v-row>Apologetika</v-row>
                      <v-row>Duchovní hnutí</v-row>
                      <v-row>Zná nepřítele</v-row>
                      <v-row>Práce a odpočinek</v-row>
                    </v-col>
                  </v-card>
                </v-col>
                <v-col cols="12" md="3">
                  <v-card elevation="0" class="pa-2  mx-3">
                    <v-col>
                      <v-row><b>Osobní vztah s Bohem</b></v-row>
                      <v-row>Delší časy s Bohem</v-row>
                      <v-row>Samostatně studuje Bibli</v-row>
                      <v-row>Žije s perspektivou věčnosti</v-row>
                      <v-row>Láska k Bohu</v-row>
                      <v-row>Závislost na Bohu</v-row>
                      <v-row>Hluboký modlitební život a uctívání</v-row>
                    </v-col>
                  </v-card>
                </v-col>
                <v-col cols="12" md="3">
                  <v-card elevation="0" class="pa-2  mx-3">
                    <v-col>
                      <v-row><b>Charakter (postoje) </b></v-row>
                      <v-row>Motivován láskou k Bohu</v-row>
                      <v-row>Vytrvalost</v-row>
                      <v-row>Pokora Vize Naděje</v-row>
                      <v-row>Touha rozdávat, co sám přijal (rozmnož. učedník)</v-row>
                      <v-row>Sebeovládání</v-row>
                      <v-row>Ochota přinášet oběti</v-row>
                      <v-row>Vděčnost za všech okolností</v-row>
                      <v-row>Vyučuje Boží slovo</v-row>
                      <v-row>Vysoko si cení pravdu</v-row>
                      <v-row>Soucit nad ztraceným světem – misie</v-row>
                      <v-row>Radostný dárce</v-row>
                      <v-row>Zralost a čistota ve vztazích s opačným pohlavím</v-row>
                    </v-col>
                  </v-card>
                </v-col>
                <v-col cols="12" md="3">
                  <v-card elevation="0" class="pa-2  mx-3">
                    <v-col>
                      <v-row><b>Dovednosti ve službě </b></v-row>
                      <v-row>Radí na základě Bible</v-row>
                      <v-row>Bojuje dobře v duch. boji</v-row>
                      <v-row>Vede druhé a ví kam</v-row>
                      <v-row>Povzbuzuje a napomíná</v-row>
                      <v-row>Organizuje větší akce</v-row>
                      <v-row>Motivuje</v-row>
                      <v-row>Čte a má přehled o dobré křesť. literatuře</v-row>
                      <v-row>Rovnováha v životě</v-row>
                    </v-col>
                  </v-card>
                </v-col>
              </v-row>
              <v-row cols="12">
                <v-col cols="12" md="3">
                  <v-card elevation="0" class="pa-2  mx-3" >
                    <v-col>
                      <v-row><b>Studia</b></v-row>
                      <v-row>
                        <v-btn small href="/resource/1521" color="amber lighten-2" class="rounded-lg text-none px-1 mt-1" elevation="0" >
                          Vůdcovství
                        </v-btn>
                      </v-row>
                      <v-row>
                        <v-btn small href="/resource/1501" color="amber lighten-2" class="rounded-lg text-none px-1 mt-1" elevation="0" >
                          1. Samuelova - Myrna Alexander
                        </v-btn>
                      </v-row>
                      <v-row>Poznávám a činím Boží vůli: Blackaby, King</v-row>
                    </v-col>
                  </v-card>
                </v-col>
                <v-col cols="12" md="3">
                  <v-card elevation="0" class="pa-2  mx-3">
                    <v-col>
                      <v-row><b>Materiály </b></v-row>
                      <v-row>Jak poradit na základě Božího slova</v-row>
                    </v-col>
                  </v-card>
                </v-col>
                <v-col cols="12" md="3">
                  <v-card elevation="0" class="pa-2  mx-3">
                    <v-col>
                      <v-row><b>Doporučené knihy</b></v-row>
                      <v-row>Tozer: Podmaněn Bohem</v-row>
                      <v-row>Yancey: Nekončící milost</v-row>
                      <v-row>Donald: Uspořádej svůj svět</v-row>
                      <v-row>Hession: Kalvárie, nebo Sinaj</v-row>
                      <v-row>Murray: Pokora</v-row>
                      <v-row>Ravi Zacharias: Lotos a kříž</v-row>
                      <v-row>Charles Thaxton: Duše vědy</v-row>
                      <v-row>Bill Bright: Modlitba, půst a probuzení</v-row>
                      <v-row>George Verwer: Revoluce lásky</v-row>
                      <v-row>John Stott: komentáře k biblickým knihám</v-row>
                      <v-row>Phillip Yancey: Ježíš jak jsem ho neznal</v-row>
                    </v-col>
                  </v-card>
                </v-col>
                <v-col cols="12" md="3">
                  <v-card elevation="0" class="pa-2  mx-3">
                    <v-col>
                      <v-row>Bill Hybels: Too Busy not to Pray</v-row>
                      <v-row>Friedhold Vogel: Odvaha k Modlitbě</v-row>
                      <v-row>D. H. Dolman: Modlitební život věřících</v-row>
                      <v-row>C. S.Lewis: Bůh na lavici obžalovaných</v-row>
                      <v-row>J. I. Packer: Choďme Duchem</v-row>
                      <v-row>Bill Hybels: Zjednoduš svůj život</v-row>
                      <v-row>Dallas Willard: Obnova srdce</v-row>
                      <v-row>John White: Leadership podle knihy Nehemjáš</v-row>
                      <v-row>J. Oswald Sanders: Duchovní vedení</v-row>
                      <v-row>Francis Schaffer: Ten, který je skutečností</v-row>
                    </v-col>
                  </v-card>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <h3 class="ml-5 my-7 text-sm-h5 font-weight-regular">Další bonusové materiály</h3>

        <v-row cols="12">
          <v-col cols="12" md="4">
            <v-card elevation="0" class="pa-2  mx-3" >
              <v-col>
                <v-row><b>Pro skupinky</b></v-row>
                <v-row>
                  <v-btn small href="/resource/1495" color="amber lighten-2" class="rounded-lg text-none px-1 mt-1" elevation="0" >
                    Jak si připravit osobní svědectví
                  </v-btn>
                </v-row>
                <v-row>
                  <v-btn small href="/resource/1505" color="amber lighten-2" class="rounded-lg text-none px-1 mt-1" elevation="0" >
                    Jak hreším
                  </v-btn>
                </v-row>
                <v-row>
                  <v-btn small href="/resource/1506" color="amber lighten-2" class="rounded-lg text-none px-1 mt-1" elevation="0" >
                    Jak poznat Boží vůli pro můj život
                  </v-btn>
                </v-row>
                <v-row>
                  <v-btn small href="/resource/1510" color="amber lighten-2" class="rounded-lg text-none px-1 mt-1" elevation="0" >
                    Kdo jsem - verše z Bible
                  </v-btn>
                </v-row>
                <v-row>
                  <v-btn small href="/resource/1513" color="amber lighten-2" class="rounded-lg text-none px-1 mt-1" elevation="0" >
                    Osobní cíle a plánovaní
                  </v-btn>
                </v-row>
                <v-row>
                  <v-btn small href="/resource/1514" color="amber lighten-2" class="rounded-lg text-none px-1 mt-1" elevation="0" >
                    Přehled rozvoje osobního života a služby
                  </v-btn>
                </v-row>
                <v-row>
                  <v-btn small href="/resource/1515" color="amber lighten-2" class="rounded-lg text-none px-1 mt-1" elevation="0" >
                    S Bohem v životních bojích - 2. Sam
                  </v-btn>
                </v-row>
              </v-col>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card elevation="0" class="pa-2  mx-3" >
              <v-col>
                <v-row><b>Materiály doplňkové</b></v-row>
                <v-row>
                  <v-btn small href="/resource/1537" color="amber lighten-2" class="rounded-lg text-none px-1 mt-1" elevation="0" >
                    Discovery Bible návod
                  </v-btn>
                </v-row>
                <v-row>
                  <v-btn small href="/resource/1540" color="amber lighten-2" class="rounded-lg text-none px-1 mt-1" elevation="0" >
                    Jak zkrotit jazyk - samostudium
                  </v-btn>
                </v-row>
                <v-row>
                  <v-btn small href="/resource/1541" color="amber lighten-2" class="rounded-lg text-none px-1 mt-1" elevation="0" >
                    Jaký mám potenciál k přátelství
                  </v-btn>
                </v-row>
                <v-row>
                  <v-btn small href="/resource/1542" color="amber lighten-2" class="rounded-lg text-none px-1 mt-1" elevation="0" >
                    Kroky ke slobode - Neal Anderson
                  </v-btn>
                </v-row>
                <v-row>
                  <v-btn small href="/resource/1543" color="amber lighten-2" class="rounded-lg text-none px-1 mt-1" elevation="0" >
                    Mistrova dílna - studium pro muže
                  </v-btn>
                </v-row>
                <v-row>
                  <v-btn small href="/resource/1544" color="amber lighten-2" class="rounded-lg text-none px-1 mt-1" elevation="0" >
                    Modlitba za studenty
                  </v-btn>
                </v-row>
                <v-row>
                  <v-btn small href="/resource/1545" color="amber lighten-2" class="rounded-lg text-none px-1 mt-1" elevation="0" >
                    Poznej svůj prirozený evangelizačný styl
                  </v-btn>
                </v-row>
                <v-row>
                  <v-btn small href="/resource/1546" color="amber lighten-2" class="rounded-lg text-none px-1 mt-1" elevation="0" >
                    Příjímaní Božího slova - Bud Hinkson
                  </v-btn>
                </v-row>
                <v-row>
                  <v-btn small href="/resource/1547" color="amber lighten-2" class="rounded-lg text-none px-1 mt-1" elevation="0" >
                    Příprava ke křtu
                  </v-btn>
                </v-row>
                <v-row>
                  <v-btn small href="/resource/1548" color="amber lighten-2" class="rounded-lg text-none px-1 mt-1" elevation="0" >
                    Roengenové otázky
                  </v-btn>
                </v-row>
                <v-row>
                  <v-btn small href="/resource/1549" color="amber lighten-2" class="rounded-lg text-none px-1 mt-1" elevation="0" >
                    Sebeobraz
                  </v-btn>
                </v-row>
                <v-row>
                  <v-btn small href="/resource/1550" color="amber lighten-2" class="rounded-lg text-none px-1 mt-1" elevation="0" >
                    Sirotci vs synové
                  </v-btn>
                </v-row>
              </v-col>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card elevation="0" class="pa-2  mx-3" >
              <v-col>
                <v-row><b>Různé</b></v-row>
                <v-row>
                  <v-btn small href="/resource/1551" color="amber lighten-2" class="rounded-lg text-none px-1 mt-1" elevation="0" >
                    4x ja jsem - samostudium
                  </v-btn>
                </v-row>
                <v-row>
                  <v-btn small href="/resource/1552" color="amber lighten-2" class="rounded-lg text-none px-1 mt-1" elevation="0" >
                    7x ja jsem - samostudium
                  </v-btn>
                </v-row>
                <v-row>
                  <v-btn small href="/resource/1555" color="amber lighten-2" class="rounded-lg text-none px-1 mt-1" elevation="0" >
                    Rozdíl mezi rozlišovaním a souzením
                  </v-btn>
                </v-row>
                <v-row>
                  <v-btn small href="/resource/1553" color="amber lighten-2" class="rounded-lg text-none px-1 mt-1" elevation="0" >
                    Rozvoj manželství
                  </v-btn>
                </v-row>
                <v-row>
                  <v-btn small href="/resource/1554" color="amber lighten-2" class="rounded-lg text-none px-1 mt-1" elevation="0" >
                    Host, který se stal majitelem
                  </v-btn>
                </v-row>
                <v-row>
                  <v-btn small href="/resource/1556" color="amber lighten-2" class="rounded-lg text-none px-1 mt-1" elevation="0" >
                    Slovo na cestu Jan 13-15 kap
                  </v-btn>
                </v-row>
                <v-row>
                  <v-btn small href="/resource/1557" color="amber lighten-2" class="rounded-lg text-none px-1 mt-1" elevation="0" >
                    Strategická modlitba
                  </v-btn>
                </v-row>
              </v-col>
            </v-card>
          </v-col>
      </v-row>


      </v-container>
    </div>
  </template>

<script>
import { mapState, mapActions } from 'vuex';
import NavigationMenu from './NavigationMenu.vue';

export default {
  components: { NavigationMenu },
  data() {
    return {
      panel: [0]
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      sharingCounts: (state) => state.resources.sharingCounts
    })
  },
  methods: {
    ...mapActions('alert', {
      successAction: 'successAction',
      errorAction: 'errorAction'
    })
  },
  created() {}
};
</script>

<style>
</style>
