import qs from 'qs';
import { resources } from '../store/modules/resources';
import { authHeader } from '../utils';
import apiRequest from './apiRequest';

export const resourcesService = {
  getResource,
  getResourceByQuery,
  getResourcesByQuery,
  getResources,
  updateResource,
  getRelatedResources,
  getAuthors,
  getAuthorsByQuery,
  getThemes,
  getTags,
  getFolders,
  storeReport,
  countResources
};

/**
 * Service which will get all available resources specified by query.
 * @param {query} query for search engine
 * @return {object} all resources.
 */
function getResourceByQuery(id, populate = []) {
  const query = qs.stringify({
    populate
  });

  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return apiRequest('/api/resources/' + id + '?' + query, requestOptions);
}

/**
 * Service which will get all available resources specified by query.
 * @param {query} query for search engine
 * @return {object} all resources.
 */
function getResourcesByQuery(query) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return apiRequest('/api/resources?' + query,
      requestOptions).then((response) => {
    return response;
  });
}

/**
 * Service which will get full resource identified by ID.
 * @param {id} id
 * @return {object} all resources.
 */
function getResource(id, accesses = false) {
  // old variant
  // const query = qs.stringify({
  //   populate: ['themes', 'authors', 'folders', 'tags', 'mediaZone.file', (accesses)?'accesses':''],
  // });

  const query = qs.stringify({
    populate: {
      'authors': {
        fields: ['id', 'fullName']
      },
      'tags': {
        fields: ['id', 'name']
      },
      'themes': {
        fields: ['id', 'name']
      },
      'mediaTypes': {
        fields: ['id', 'type']
      },
      'folders': {
        fields: ['id', 'name']
      },
      'mediaZone': {
        populate: ['file']
      },
      'accesses': {
        fields: (accesses) ? ['id', 'name'] : ''
      }
    }
  });

  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return apiRequest('/api/resources/' + id + '?' + query, requestOptions);
}

// this method will request all resources from backend filterd by filter stores in store
// under 'selected' property
// usable media types of resources: ['Audio', 'Video', 'Document']
function getResources(filterType = 'none', page = 0, pageSize = 3) {
  let filters = {};

  switch (filterType) {
    case 'none':
      filters = getFilterFromStore();
      break;

    case 'Audio':
    case 'Video':
    case 'Document':
      // get filter from selected object in search bar
      filters = getFilterFromStore();

      // add media to it
      filters.$and.push({
        mediaTypes: {
          type: {
            $eq: filterType
          }
        }
      });
      break;

    case 'MissingTheme':
      filters = {
        themes: {
          id: {
            $null: true
          }
        }
      };
      break;

    case 'MissingDescription':
      filters = {
        description: {
          $null: true
        }
      };
      break;

    case 'NonProcessed':
      filters = {
        $or: [
          {
            processed: {
              $null: true
            }
          },
          {
            processed: {
              $eq: false
            }
          }
        ]
      };
      break;

    default:
      console.error('Wrong filter type', filterType);
      break;
  }

  const query = qs.stringify({
    populate: {
      authors: {
        fields: ['fullName'],
        populate: ['photo']
      },
      tags: {
        fields: ['name']
      },
      themes: {
        fields: ['name']
      },
      mediaTypes: {
        fields: ['type']
      },
      folders: {
        fields: ['name']
      }
    },
    filters,
    sort: [resources.state.selected.sortDirection],
    pagination: {
      page: page,
      pageSize: pageSize
    }
  }, {
    encodeValuesOnly: true
  });

  const returnObj = {
    resources: [],
    status: 'loading',
    meta: {}
  };

  getResourcesByQuery(query).then((response) => {
    const loadedResources = [];

    for (const item of response.data) {
      const resource = {
        id: item.id,
        title: item.attributes.title,
        description: item.attributes.description,
        date: item.attributes.date,
        visitsCount: item.attributes.visitsCount,
        authors: item.attributes.authors.data.map((author) => {
          return {
            name: author.attributes.fullName,
            pic: (author.attributes.photo.data != null &&
              author.attributes.photo.data.attributes.formats != null) ?
              author.attributes.photo.data.attributes.formats.thumbnail.url :
              'none'
          };
        }),
        themes: item.attributes.themes.data.map((theme) => {
          return theme.attributes.name;
        }),
        tags: item.attributes.tags.data.map((tag) => {
          return tag.attributes.name;
        }),
        types: item.attributes.mediaTypes.data ?
          item.attributes.mediaTypes.data.map((medium) => {
            return medium.attributes.type;
          }) :
          '',
        folders: item.attributes.folders.data.map((folder) => {
          return folder.attributes.name;
        }),
        proccesed: item.attributes.proccesed
      };

      loadedResources.push(resource);
    }

    returnObj.resources = loadedResources;
    returnObj.meta = response.meta;
    returnObj.status = 'done';

    if (process.env.NODE_ENV === 'development') {
      console.log('Directly loaded resources:', loadedResources);
    }
  });

  return returnObj;
}

/*
 * This function update a resource with a new title
 */
async function updateResource(resID, data, newTagName = null) {
  if (newTagName) {
    // add new tag into DB and get its id
    const requestOptionsNewTag = {
      method: 'POST',
      headers: authHeader(),
      body: {
        data: {
          name: newTagName
          // TODO: other fields should be also added
        }
      }
    };

    const response = await apiRequest('/api/tags', requestOptionsNewTag);
    if (process.env.NODE_ENV === 'development') {
      console.log('Create new tag result (1):', response);
    }
    data.tags.push(response.data.id);
  }

  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: {
      data
    }
  };
  return apiRequest('/api/resources/' + resID + '?populate[0]=tags', requestOptions);
}

// help function will return generated filter from "selected" property stored in VUEX
function getFilterFromStore() {
  // get selected object from VUEX
  const selected = resources.state.selected;

  // create query from it
  const filters = {
    $and: [
      { $or: [] },
      { $or: [] }
    ]
  };

  // add selected authors to the query
  for (const selectedAuthor of selected.authors) {
    const obj = {
      authors: {
        fullName: {
          $eq: selectedAuthor.name
        }
      }
    };
    filters.$and[0].$or.push(obj);
  }

  // add selected themes to the query
  for (const selectedTheme of selected.themes) {
    const obj = {
      themes: {
        name: {
          $eq: selectedTheme.name
        }
      }
    };
    filters.$and[1].$or.push(obj);
  }

  // add dateFrom to the query
  if (selected.dateFrom) {
    const obj = {
      date: {
        $gt: selected.dateFrom
      }
    };
    filters.$and.push(obj);
  }

  // add dateTo to the query
  if (selected.dateTo) {
    const obj = {
      date: {
        $lt: selected.dateTo
      }
    };
    filters.$and.push(obj);
  }


  // add filltext search to the query
  if (selected.query) {
    if (selected.exactSearch === 'true') {
      const obj = {
        $or: [
          {
            title: {
              $contains: selected.query
            }
          },
          {
            description: {
              $contains: selected.query
            }
          },
          {
            authors: {
              fullName: {
                $contains: selected.query
              }
            }
          },
          {
            tags: {
              name: {
                $contains: selected.query
              }
            }
          },
          {
            themes: {
              name: {
                $contains: selected.query
              }
            }
          },
          {
            folders: {
              name: {
                $contains: selected.query
              }
            }
          }
        ]
      };
      filters.$and.push(obj);
    } else {
      const obj = {
        $or: [
          {
            title: {
              $containsi: selected.query
            }
          },
          {
            description: {
              $containsi: selected.query
            }
          },
          {
            authors: {
              fullName: {
                $containsi: selected.query
              }
            }
          },
          {
            tags: {
              name: {
                $containsi: selected.query
              }
            }
          },
          {
            themes: {
              name: {
                $containsi: selected.query
              }
            }
          },
          {
            folders: {
              name: {
                $containsi: selected.query
              }
            }
          }
        ]
      };
      filters.$and.push(obj);
    }
  }

  if (process.env.NODE_ENV === 'development') {
    console.log('Filter query: ', filters);
  }

  return filters;
}

/**
 * Service will return related resources contained in specified folder.
 * @param {folderId} folder id.
 * @return {object}   related resources.
 */
function getRelatedResources(folderId, resID) {
  const query = qs.stringify({
    populate: [
      'resources.authors',
      'resources.mediaTypes',
      'resources.themes',
      'resources.tags'
    ],
    filters: {
      resources: {
        id: {
          $eq: resID
        }
      }
    }
  });

  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return apiRequest('/api/folders/' + folderId + '?' + query, requestOptions).then((response) => {
    return response;
  });
}

/**
 * * Service which will get all available folders.
 * @return {object} all resources.
 */
function getFolders(sort = [], populate = [], pageSize = 25) {
  return recursiveGetQuery(1, pageSize, undefined, '/api/folders', populate, sort);
}

/**
 * Service which will get all available authors.
 * @return {object} all resources.
 */
function getAuthors(sort = [], populate = [], pageSize = 25) {
  return recursiveGetQuery(1, pageSize, undefined, '/api/authors', populate, sort);
}

/**
 *  Recursive GET query, wich will iterate over all pages and return a list of records
 */
function recursiveGetQuery(page = 1, pageSize = 25, list = [], url, populate = [], sort = []) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  const query = qs.stringify({
    populate,
    pagination: {
      page,
      pageSize
    },
    sort
  });

  return apiRequest(url + '?' + query, requestOptions)
      .then((response) => {
        list = list.concat(response.data);

        if (page < response.meta.pagination.pageCount) {
          return recursiveGetQuery(page + 1, pageSize, list, url, populate, sort);
        } else {
          return list;
        }
      });
}

/**
 * Service which will get all available authors.
 * @param {query} query for search engine
 * @return {object} all authors.
 */
function getAuthorsByQuery(query) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return apiRequest('/api/authors?' + query, requestOptions).then((response) => {
    return response;
  });
}


/**
 * Service which will get all available authors with number of its resources.
 * @param {query} query for search engine
 * @return {object} all authors.
 */
function countResources(api, query, all = false) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  if (!all) {
    return apiRequest('/api/count/' + api + '?' + query, requestOptions).then((response) => {
      return response;
    });
  } else {
    return recursiveGetQuery(1, 25, undefined, '/api/count/' + api, [], []);
  }
}


/**
 * Service which will get all available themes.
 * @return {object} all resources.
 */
function getThemes(sort = [], populate = [], pageSize = 25) {
  return recursiveGetQuery(1, pageSize, undefined, '/api/themes', populate, sort);
}

/**
 * Service which will get all available tags.
 * @return {object} all resources.
 */
function getTags(sort = [], populate = [], pageSize = 25) {
  return recursiveGetQuery(1, pageSize, undefined, '/api/tags', populate, sort);
}

/*
 * This function store user report
 */
function storeReport(report) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: {
      data: {
        user: report.user,
        date: report.date,
        title: report.title,
        description: report.description,
        authors: report.authors,
        themes: report.themes,
        tags: report.tags,
        comment: report.comment,
        state: report.state,
        resid: report.resid
      }
    }
  };

  return apiRequest('/api/reports/', requestOptions);
}

