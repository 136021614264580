
export const utils = {
  getInitials
};

function getInitials(name) {
  const parts = name.split(' ');
  let _firstName = '';
  let _lastName = '';
  let _initials = '';
  if (parts.length > 0) {
    if (parts.length > 1) {
      _firstName = parts[0];
      _lastName = parts[1];
      _initials = _firstName[0]+_lastName[0];
    } else {
      _firstName = parts[0];
      _initials = _firstName[0];
    }
  }
  return _initials;
}
