<template>
  <v-container class="my-16" style="max-width:1024px;">
      <v-row justify="center">
        <v-card  class="pa-10" rounded flat color="amber lighten-4" max-width="620">
          <v-card-title>
            <v-row justify="center">
              <v-img max-height="32" max-width="32" src="@/assets/logo-in-life.png" alt="In-Life logo"></v-img>
              <span class="mx-1 text--primary text-button">Zdroje</span>
            </v-row>
          </v-card-title>
          <v-form
            @submit.prevent="handleSubmit"
            class="mt-6"
            v-model="valid"
            lazy-validation>
            <p class="text--secondary font-weight-light">
              Pro přístup ke zdrojům <b>In-Life</b> je nutné se registrovat
            </p>

            <v-text-field
              ref="fullname"
              v-model="fullname"
              label="Jméno a přímení"
              required color="amber"
              :rules="fullnameRules"
            />

            <v-text-field
              ref="username"
              v-model="username"
              label="Uživatelské jméno"
              :rules="usernameRules"
              required color="amber"
              @keydown="userNameExists = false"
            />

            <v-text-field
              ref="email"
              v-model="email"
              label="Email"
              :rules="emailRules"
              required color="amber"
              @keydown="userEmailExists = false"
            />

            <v-text-field
              v-model="password"
              label="Heslo"
              :rules="passwordRules"
              required color="amber"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              @click:append="show = !show"
            />

            <v-text-field
              v-model="confirm"
              label="Heslo znovu"
              :rules="confirmRules"
              required color="amber"
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show2 ? 'text' : 'password'"
              @click:append="show2 = !show2"
            />

            <v-row>
              <v-btn
                class="mt-8"
                elevation="0"
                :disabled="username && password && equality && valid ? false : true"
                @click="handleSubmit"
                color="amber lighten-2">
                Registrovat
              </v-btn>
            </v-row>
          </v-form>
        </v-card>
      </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { userService } from '../services/user.service';

export default {
  data() {
    return {
      email: '',
      username: '',
      password: '',
      confirm: '',
      fullname: '',
      // variable equality should be boolean true, and variable valid is not necessary
      equality: 'true',
      valid: true,
      show: false,
      show2: false,
      userNameExists: false,
      userEmailExists: false,
      fullnameRules: [
        (v) => !!v || 'Pole je povinné'
      ],
      usernameRules: [
        (v) => !!v || 'Pole je povinné',
        (v) => (v && v.length >= 5) || 'Uživatelské jméno musí mít alespoň 5 znaků',
        (v) => !this.userNameExists || 'Uživatelské jméno již existuje'
      ],
      emailRules: [
        (v) => !!v || 'Pole je povinné',
        (v) => /.+@.+\..+/.test(v) || 'E-mail musí být platný',
        (v) => !this.userEmailExists || 'Email již existuje'
      ],
      passwordRules: [
        (v) => !!v || 'Pole je povinné',
        (v) => (v && v.length >= 8) || 'Heslo musí mít alespoň 8 znaků'
      ],
      confirmRules: [
        (v) => !!v || 'Pole je povinné',
        (v) => this.equality || 'Hesla se nezhodují'
      ]
    };
  },
  computed: {
    ...mapState('account', ['status'])
  },
  methods: {
    ...mapActions('account', ['register']),
    ...mapActions('alert', {
      successAction: 'successAction',
      errorAction: 'errorAction'
    }),
    handleSubmit(e) {
      const user = {
        username: this.username,
        password: this.password,
        email: this.email,
        fullName: this.fullname
      };

      userService.checkUserName(this.username).then((isUserNameAvailable) => {
        if (!isUserNameAvailable) {
          this.userNameExists = true;
          this.$refs.username.validate();
          this.errorAction('Registrace se nezdařila: Uživatelské jméno již existuje');
        } else {
          userService.checkUserEmail(this.email).then((isUserEmailAvailable) => {
            if (!isUserEmailAvailable) {
              this.userEmailExists = true;
              this.$refs.email.validate();
              this.errorAction('Registrace se nezdařila: Email již existuje');
            } else {
              this.register(user).then(this.$router.push('/registered'));
            }
          }).catch((res) => {
            this.errorAction('Registrace se nezdařila: ' + res.status + ' ' + res.message);
          });
        }
      }).catch((res) => {
        this.errorAction('Registrace se nezdařila: ' + res.status + ' ' + res.message);
      });
    }
  },
  updated() {
    this.equality = this.password == this.confirm ? true : false;
  }
};
</script>
