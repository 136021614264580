<template>
    <div>
      <v-card v-if="noAccesses" class="text-center text--secondary mt-16" elevation="0">
        Zatím tady nejsou žádné zdroje, neboť registrace se zpracovává. <br>
        V případe dotazů napište na admin@in-life.org <br>
        Děkujeme za pochopení
      </v-card>
      <v-card v-else-if="data.resources.length==0 && data.status=='done'" class="text-center text--secondary mt-16" elevation="0">
        Nebyly nalezeny žádné zdroje daného typu ...
      </v-card>
      <div class="text-center" v-if="data.status=='loading'">
        <v-progress-circular
          class="mt-16"
          indeterminate
          color="grey"
        ></v-progress-circular>
        <v-card elevation="0" class="text--secondary mt-3">
          Načitám zdroje...
        </v-card>
      </div>
      <template v-for="(res, index) in data.resources">
      <div :key='index'>
        <v-hover v-slot="{ hover }">
          <v-card
            :class="(view=='default')? 'my-6 mx-2 pb-3 rounded-lg' : 'my-2 mx-2 pb-3 rounded-lg'"
            flat
            ref="mycard"
            @click="clicked(res.id)"
            :color="hover ? 'amber lighten-3' : 'amber lighten-4'"
            >
            <v-card-title :class="(view=='default') ? 'd-flex justify-space-between pt-2' : 'd-flex justify-space-between py-2 px-4'">
              <span class="float-start">
                {{ res.title }}
                <span v-if="view=='compact'">
                  <v-btn
                    v-for="author in res.authors" :key="author.id"
                    href="#" class="ma-1 text-decoration-none text-none pa-1 text--secondary"
                    @click.stop="setSearch('author',author)" large
                    elevation="0" text
                  >
                    {{ author.name }}
                  </v-btn>
                </span>
              </span>
              <span class="text--secondary float-end" style="font-size:1rem;">
                <v-icon color="gray" class="px-1" v-if="haveMedia(res, 'Video')"  title="Obsahuje video záznam">mdi-video</v-icon>
                <v-icon color="gray" class="px-1" v-if="haveMedia(res, 'Audio')"  title="Obsahuje audio záznam">mdi-music</v-icon>
                <v-icon color="gray" class="px-1" v-if="haveMedia(res, 'Document')"  title="Obsahuje dokument (pdf, pptx, docx ...)">mdi-file-document-outline</v-icon>
                <v-chip disabled
                  v-if="view=='default' && (res.date)" :color="hover ? 'amber lighten-3' : 'amber lighten-4'"
                  class="text--primary text-right" label
                >
                  <v-icon small color="black">mdi-calendar</v-icon> <span class="ps-1">{{new Date(res.date).toLocaleDateString()}}</span>
                </v-chip>
                <v-chip disabled :color="hover ? 'amber lighten-3' : 'amber lighten-4'" :title="'Zobrazeno: '+res.visitsCount+'×'"  class="px-0">
                  <v-icon small color="black">mdi-eye</v-icon><span class="ps-1 text--secondary ">{{res.visitsCount}}</span>
                </v-chip>
              </span>
            </v-card-title>
            <v-row v-if="view=='default'" class="px-2 mt-n4">
              <v-col cols="12" sm="12" md="12" lg="4" xl="3">
                <v-row v-if="res.authors.length>0">
                  <v-btn
                    v-for="author in res.authors" :key="author.id"
                    href="#" class="ma-1 text-decoration-none text-none pa-1"
                    @click.stop="setSearch('author',author)" large
                    elevation="0" text
                  >
                    <v-avatar color="amber lighten-2" class="mx-4" size="32">
                      <v-icon v-if="author.pic == 'none'">mdi-account</v-icon>
                      <v-img v-else :src="author.pic"></v-img>
                    </v-avatar>
                    {{author.name}}
                  </v-btn>
                </v-row>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="8" xl="9">
                <v-row v-if="res.description && width > 400" class="text--secondary font-weight-light py-2 px-4 order-lg-2">
                    {{ (res.description.substr(0,200))+' ...' }}
                  </v-row>
                  <v-row v-if="res.description && width <= 400" class="text--secondary font-weight-light py-2 px-4 order-lg-2">
                    {{ (res.description.substr(0,135))+' ...' }}
                  </v-row>
                  <v-row class='mt-2 mb-1 mr-1'>
                    <div v-if="res.themes.length>0 || res.tags.length > 0 || res.folders.length > 0" class="d-flex flex-row flex-wrap ml-1" >
                      <div v-for="(theme,index) in res.themes" :key="theme.id">
                        <v-btn plain x-small class="font-weight-light"
                          @click.stop="setSearch('theme',theme)">
                            {{ theme }}
                        </v-btn>
                        <span v-if="index<res.themes.length-1" class="text--disabled">|</span>
                        <span v-if="index == res.themes.length-1 && (res.tags.length > 0 || res.folders.length > 0)" class="text--disabled">|</span>
                      </div>
                      <div v-for="(tag,index) in res.tags" :key="tag.id">
                        <v-btn plain x-small class="font-weight-light text-none"
                          @click.stop="setSearch('tag',tag)">
                            {{ tag }}
                        </v-btn>
                        <span v-if="index<res.tags.length-1" class="text--disabled">|</span>
                        <span v-if="index == res.tags.length-1 &&  res.folders.length > 0" class="text--disabled">|</span>
                      </div>
                      <div v-for="(folder,index) in res.folders" :key="folder.id">
                        <v-btn plain x-small class="font-weight-light text-none"
                          @click.stop="setSearch('tag',folder)">
                            {{ folder }}
                        </v-btn>
                        <span v-if="index<res.folders.length-1" class="text--disabled">|</span>
                      </div>
                    </div>
                  </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-hover>
      </div>
      </template>
      <v-row v-if="data.resources.length>0" class="mt-1">
        <v-col cols='8'>
          <v-pagination
            v-if="data.meta.pagination && data.meta.pagination.pageCount>1"
            v-model="paginationPage"
            :length="data.meta.pagination.pageCount"
            @input="updateResources"
            color="amber lighten-2"
            class="float-left ms-n3"
          ></v-pagination>
        </v-col>
        <v-col cols='4'>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="amber lighten-2 mt-1 mx-1"
                v-bind="attrs"
                v-on="on"
                class="float-right"
                title="Počet záznamů na stránku/kompaktnost zobrazení"
                elevation="0"
              >
                {{ paginationPerPage }} |
                <v-icon class="ps-1">{{(view=='default')? 'mdi-view-agenda':'mdi-view-headline'}}</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item-group>
                <v-list-item disabled dense class="text-overline"><small>Na stránku</small></v-list-item>
                <v-list-item @click="setPaginationPerPage(10)" value="10">
                  <v-list-item-title class="text--primary px-2">10</v-list-item-title>
                </v-list-item>
                <v-list-item @click="setPaginationPerPage(25)" value="25">
                  <v-list-item-title class="text--primary px-2">25</v-list-item-title>
                </v-list-item>
                <v-list-item @click="setPaginationPerPage(50)" value="50">
                  <v-list-item-title class="text--primary px-2">50</v-list-item-title>
                </v-list-item>
                <v-list-item @click="setPaginationPerPage(100)" value="100">
                  <v-list-item-title class="text--primary px-2">100</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
              <v-divider></v-divider>
              <v-list-item-group>
                <v-list-item disabled dense class="text-overline"><small>Zobrazení</small></v-list-item>
                <v-list-item @click="setView('default')">
                  <v-list-item-title class="text--primary px-2">Výchozí</v-list-item-title>
                </v-list-item>
                <v-list-item @click="setView('compact')">
                  <v-list-item-title class="text--primary px-2">Kompaktní</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { resourcesService } from '../services/resources.service';

export default {
  props: ['filter', 'noAccesses'],
  data() {
    return {
      activeId: -1,
      data: {},
      view: 'default', // nastavení zobrazení ('default', 'compact')
      paginationPage: 1, // aktualni stranka
      paginationPages: 2, // počet zdroju na stránce
      paginationPerPage: 25, // default page size
      width: 723 // default value
    };
  },
  computed: {
    ...mapState({
      selected: (state) => state.resources.selected

    })
  },
  watch: {
    // this is triggered, when "selected" object is changes
    '$store.state.resources.selected'() {
      this.data = resourcesService.getResources(this.$props.filter, this.paginationPage, this.paginationPerPage);
    },
    '$data.data.resources'() {
      if (this.data.status === 'done') {
        const resIDs = this.data.resources.map( (res) => res.id);
        this.storeIDs(resIDs);
      }
    }
  },
  methods: {
    ...mapActions('resources', {
      storeSelected: 'storeSelected',
      storeIDs: 'storeIDs'
    }),
    ...mapActions('account', {
      updateUser: 'updateUser'
    }),
    clicked(resourceId) {
      this.$router.push('/resource/'+resourceId); // přesměruje na detail zdroje
    },
    setSearch(type, value) {
      const _selected = {
        authors: [],
        themes: [],
        query: '',
        dateFrom: '',
        dateTo: '',
        sortDirection: 'createdAt:desc'
      };
      switch (type) {
        case 'theme':
          _selected.themes.push({ name: value });
          break;
        case 'author':
          _selected.authors.push({ name: value.name });
          break;
        case 'tag':
          _selected.query = value;
          break;
      }
      this.storeSelected(_selected);
    },
    setView(view) {
      this.view = view;
    },
    haveMedia(res, type) {
      return res.types.filter((obj) => {
        return obj == type;
      }).length > 0;
    },
    updateResources(newPage) {
      this.data = resourcesService.getResources(this.$props.filter, newPage, this.paginationPerPage);
      this.paginationPage = newPage;
    },
    setPaginationPerPage(count) {
      this.paginationPerPage = count;
      this.paginationPage = 1;
      this.data = resourcesService.getResources(this.$props.filter, this.paginationPage, this.paginationPerPage);
    },
    windowResized(e) {
      if ( this.$refs.mycard != undefined && this.$refs.mycard.length > 0) {
        this.width = this.$refs.mycard[0].$el.clientWidth;
      }
    }
  },
  created() {
    // here I calculate size of the Card from the size of the window
    // 256 is the size of the navigation panel
    // brake points form them are 600px and 1000px
    // 42 is borders of between the card and navigation panels
    // this is needed just to properly allign date and view count
    if ( window.innerWidth <= 600 ) {
      this.width = window.innerWidth - 42;
    }
    if ( window.innerWidth > 600 && window.innerWidth <= 1000 ) {
      this.width = window.innerWidth - 256 - 42;
    }
    if ( window.innerWidth > 1000 ) {
      this.width = window.innerWidth - 256 - 256 - 42;
    }

    window.addEventListener('resize', this.windowResized);
    this.data = resourcesService.getResources(this.$props.filter, this.paginationPage, this.paginationPerPage);
  },
  destroyed() {
    window.removeEventListener('resize', this.windowResized);
  }
};
</script>

<style scoped>
div /deep/ .theme--light.v-pagination .v-pagination__item--active{
  color: black;
}
div /deep/ .v-pagination__item {
  /* this is to remove elevation on the pagination */
  box-shadow: none !important
}
div /deep/ .v-pagination__navigation {
  /* this is to remove elevation on the pagination */
  box-shadow: none !important
}
</style>
