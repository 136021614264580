import Vue from 'vue';
import Vuex from 'vuex';

import { alert } from './modules/alert';
import { account } from './modules/account';
import { resources } from './modules/resources';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    alert,
    account,
    resources
  }
});

if (process.env.NODE_ENV === 'development') {
  store.subscribeAction({
    before: (action, state) => {
      console.log('Old State: ', state);
      console.log(`Action: ${action.type}`);
    },
    after: (action, state) => {
      console.log('New State: ', state);
    }
  });
}

export default store;
