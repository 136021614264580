<template>
    <v-container class="my-16" style="max-width:1024px;">
        <v-row justify="center">
      <v-card  class="pa-10" outlined flat rounded color="amber lighten-4" max-width="380">
        <v-card-title>
          <v-row justify="center">
            <v-img max-height="32" max-width="32" src="@/assets/logo-in-life.png" alt="In-Life logo"></v-img>
            <span class="mx-1 text--primary text-button">Zdroje</span>
          </v-row>
        </v-card-title>
        <v-form @submit.prevent="handleSubmit" class="mt-6">
          <h1 class="text-h6">Registrace ...</h1>
          <p class="text--secondary text-body-2 mt-2">
            Váš požadavek na registraci byl <b> úspěšně evidován</b> a  bude co nejdříve zpracován.
            Po úspěšném zpracování obržíte e-mail.
          </p>
          <v-row>
            <v-btn
              class="mt-8 mx-auto"
              href="/login"
              elevation="0"
              color="amber lighten-2">
              Přihlásit
            </v-btn>
          </v-row>
          <p class="text--secondary text-caption mt-12 mb-n4 text-body-2">
            V případě potíží s přihlášením nebo s používáním portálu napište
            <a href="mailto:admin@in-life.org" class="text--secondary font-weight-regular">správci</a>.
        </p>
        </v-form>
      </v-card>
    </v-row>
    </v-container>
</template>

<script>
export default {
};
</script>
