import Vue from 'vue';
import VeeValidate from 'vee-validate';

import App from './App.vue';
import router from './router';
import store from './store';

import vuetify from '@/plugins/vuetify';


Vue.use(VeeValidate);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');

