<template>
    <div>
        <navigation-menu></navigation-menu>
        <v-container class="px-1 py-0" fluid>
            <h1 class="text-h6 ma-sm-3 text-sm-h4 font-weight-regular d-flex justify-center">Řečníci</h1>
            <v-divider class="amber lighten-4 mx-auto"></v-divider>
            <p class="text--disabled mt-4 text-body-2">
                Většina zdrojů (zejména těch audio-vizuálních) má uvedeného jednoho nebo více řečníků.
                Seznam všech dostupných řečníků je abecedně řazený. Vedle fotky řečníka je zobrazen počet zdrojů
                (existuje-li alespoň jeden).
            </p>
            <v-row>
                <v-col>
                    <v-pagination
                        v-if="pageCount>1"
                        v-model="currentPage"
                        :length="pageCount"
                        @input="updateSpeakers"
                        color="amber lighten-2"
                        class="float-left mx-n3"
                    ></v-pagination>
                </v-col>
                <v-col>
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="amber lighten-2 mt-1"
                            light v-bind="attrs"
                            v-on="on"
                            class="float-right"
                            title="Počet řečníků na stránku"
                            elevation="0"
                        >
                            {{ perPage }}
                        </v-btn>
                        </template>
                        <v-list>
                            <v-list-item-group>
                                <v-list-item disabled dense class="text-overline"><small>Na stránku</small></v-list-item>
                                <v-list-item @click="setPaginationPerPage(10)">
                                    <v-list-item-title class="px-2 text--secondary">10</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="setPaginationPerPage(20)">
                                    <v-list-item-title class="px-2 text--secondary">20</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="setPaginationPerPage(50)">
                                    <v-list-item-title class="px-2 text--secondary">50</v-list-item-title>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-menu>
                </v-col>
            </v-row>
            <div class="text-center" v-if="loading">
              <v-progress-circular
                class="mt-16"
                indeterminate
                color="grey"
              ></v-progress-circular>
              <v-card elevation="0" class="text--secondary mt-3">
                Načitám autory...
              </v-card>
            </div>
            <v-row class="d-flex flex-wrap" >
              <template v-for="speaker in speakers" >
                <div :key="speaker.id">
                  <v-hover v-slot="{ hover }">
                    <v-card @click="clicked(speaker.name)"
                      class="ma-2"
                      elevation="0"
                      outlined
                      min-width="200"
                      min-height="90"
                      :color="hover ? 'amber lighten-3' : 'amber lighten-4'"
                    >
                      <div class="text-center mt-3">
                          <v-avatar v-if="speaker.photo == null" color="grey lighten-4">
                              <span class="dark--text body">{{ speaker.initials }}</span>
                          </v-avatar>
                          <v-avatar v-if="speaker.photo != null">
                              <img :src="speaker.photo" :alt="speaker.name">
                          </v-avatar>
                          <v-badge
                              v-if="speaker.res_count>0" color='amber' bottom
                              :content="speaker.res_count.toString()" offset-x="12"
                              class="speaker-badge"
                          >
                          </v-badge>
                      </div>
                      <div class="text-center">
                          <b>{{speaker.name}}</b>
                      </div>
                  </v-card>
                </v-hover>
              </div>
            </template>
          </v-row>
        </v-container>
    </div>
</template>

<script>
import { resourcesService } from '../services/resources.service';
import { mapActions } from 'vuex';
import qs from 'qs';
import NavigationMenu from './NavigationMenu.vue';
import { utils } from '../utils/utils';

export default {
  components: { NavigationMenu },
  data() {
    return {
      speakers: [], // pole obsahující $perPage aktuálně načtených řečníků
      currentPage: 1, // aktuální stránka s řečníky
      perPage: 20, // počet řečníků na stránce
      pageCount: 0,
      loading: false
    };
  },
  methods: {
    ...mapActions('resources', {
      storeSelected: 'storeSelected'
    }),
    ...mapActions('alert', {
      successAction: 'successAction',
      errorAction: 'errorAction'
    }),

    /**
         * Metoda clicked() zpracuje kliknutí uživatele na kontkrétního řečníka/autora.
         * Tohoto řečníka/autora vloží do objektu selected a zpřístupní ostatním komponentám
         * pomocí storeSelected().
         */
    clicked(speakerName) {
      const selectedObj = {
        authors: [{
          name: speakerName // název řečníka
        }],
        themes: [],
        query: '',
        dateFrom: '',
        dateTo: '',
        sortDirection: 'createdAt:desc'
      };
      this.storeSelected(selectedObj);
      this.$router.push('/'); // přesměruje na home page
    },

    /**
         * Metoda updateSpeakers() zareaguje na změnu aktuální stránky zobrazených řečníků.
         * Načte z backendu požadovanou část/stránku řečníků a naplní props:speakers objekty
         *  {
         *      id: 0,
         *      name: '',
         *      initials: '',
         *      photo: <url>,
         *      res_count: 0
         *  }
         *  page    aktuální stránka výpisu
         */
    updateSpeakers(page) {
      this.currentPage = page;
      this.loading = true;

      const query = qs.stringify({
        pagination: {
          page: page,
          pageSize: this.perPage
        }
      });
      this.speakers = [];
      resourcesService.countResources('authors', query).then(
          (speakers) => {
            if (speakers) {
              speakers.data.authors.forEach((_speaker) => {
                const initials = utils.getInitials(_speaker.fullName);
                const speaker = {
                  id: _speaker.id,
                  name: _speaker.fullName,
                  initials: initials,
                  photo: (_speaker.photo == null || _speaker.photo.formats == null ) ?
                    null:_speaker.photo.formats.thumbnail.url,
                  res_count: _speaker.resourcesCount
                };
                this.speakers.push(speaker);
              });
              this.pageCount = speakers.meta.pagination.pageCount;
            }
            this.loading = false;
          }
      ).catch((res) => {
        this.errorAction('Načtení se nepodařilo: ' + res.status + ' ' + res.message);
      });
    },
    setPaginationPerPage(count) {
      this.perPage = count;
      this.page = 1;
      this.updateSpeakers(1);
    }
  },

  /**
     * Metoda created() se volá při vytváření komponenty SpeakersPage.
     * Načte z backendu pouze řečníky z první stránky.
     */
  created() {
    this.updateSpeakers(1);
  }
};
</script>
<style scoped>
div /deep/ .theme--light.v-pagination .v-pagination__item--active{
  color: black;
}
.v-application .amber.lighten-2{
    color: black;
}
div /deep/ .v-pagination__item {
  /* this is to remove elevation on the pagination */
  box-shadow: none !important
}
div /deep/ .v-pagination__navigation {
  /* this is to remove elevation on the pagination */
  box-shadow: none !important
}
</style>
