<template>
  <div>
    <v-card
      elevation="0" color="amber lighten-4" outlined class="ma-1 rounded-lg"
    >
      <v-card-title
        v-if="type == 'sharedWithMe'" class="pa-2"
        @click="$emit('show-shared-resource')"
        style="cursor: pointer"
      >
        <v-row no-gutters>
          <v-col
            cols="12" sm="8" class="text-sm-h6"
          >
            {{ entry.resTitle }}
          </v-col>
          <v-col  cols="12" sm="4"
            class="text--secondary text-button text-right"
          >
            <small>
              Do: <b>{{ entry.validTo.toLocaleDateString() }}</b>
            </small>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-title
        v-if="type == 'iShare'" class="pa-2"
      >
        <v-row no-gutters>
          <v-col cols="12" sm="6" class="text-sm-h6">
            <v-row no-gutters>
              <v-col cols="10">
                <span
                  @click="$emit('show-resource')"
                  style="cursor: pointer;word-break:break-word"
                >
                  {{ entry.resTitle }}
                </span>
              </v-col>
              <v-col cols="2">
                <v-icon
                  class="d-flex d-sm-none text--secondary mx-2 float-end pt-2"
                  title="Zrušit sdílení"
                  @click="$emit('unshare')"
                >
                  mdi-share-off
                </v-icon>
              </v-col>
            </v-row>
          </v-col >
          <v-col cols="12" sm="6">
            <v-row no-gutters>
              <v-col cols="6" class="text--secondary text-body-2 pt-2">
                {{ entry.shareTo }}
              </v-col>
              <v-col  cols="6">
                <v-icon
                  class="d-none d-sm-flex text--secondary mx-2 float-right mt-2"
                  @click="$emit('unshare')"
                >
                  mdi-share-off
                </v-icon>
                <span class="text--secondary text-button float-right">
                  <small>
                    Do: <b>{{ entry.validTo.toLocaleDateString() }}</b>
                  </small>
                </span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ['entry', 'type']
};
</script>

<style scoped>
</style>
