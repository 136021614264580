import 'regenerator-runtime/runtime';
import apiRequest from './apiRequest';
import { authHeader, getCurrentUser } from '../utils';

export const userService = {
  login,
  logout,
  register,
  getAll,
  update,
  changePassword,
  delete: _delete,
  getAccesses,
  checkUserName,
  checkUserEmail,
  getUserProfile,
  updateFavourite
};

/**
 * Service which will try to login some user.
 * @param {username} username string.
 * @param {password} password string.
 * @return {user} logged in user.
 */
function login(username, password) {
  const identifier = username;

  const requestOptions = {
    method: 'POST',
    body: { identifier, password }
  };

  return apiRequest('/api/auth/local', requestOptions).then((response) => {
    const requestOptions2 = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + response.jwt
      }
    };

    // add user accesses to the user
    return apiRequest('/api/userprofile', requestOptions2).then((profileResponse) => {
      const user = {
        id: response.user.id,
        username: response.user.username,
        name: response.user.fullName,
        email: response.user.email,
        token: response.jwt,

        isStaff: profileResponse.role.name == 'InLifeStaff' ||
          profileResponse.role.name == 'Editor' ||
          profileResponse.role.name == 'Admin' ? true : false,

        isEditor: profileResponse.role.name == 'Editor' ||
          profileResponse.role.name == 'Admin' ? true : false,

        isAdmin: profileResponse.role.name == 'Admin' ? true : false,

        noAccess: profileResponse.accesses.length == 0 ? true : false
      };
      localStorage.setItem('user', JSON.stringify(user));
      return user;
    });
  });
}

/**
 * Service which will log the current user out.
 */
function logout() {
  localStorage.removeItem('user');
}

/**
 * Service which register a new user.
 * @param {user} user for registration.
 * @return {object} response from server.
 */
function register(user) {
  const requestOptions = {
    method: 'POST',
    body: user
  };

  return apiRequest('/api/auth/local/register', requestOptions).then((response) => {
    return response;
  });
}

function checkUserName(userName) {
  const requestOptions = {
    method: 'GET'
  };

  return apiRequest('/api/check/username/' + userName, requestOptions).then((response) => {
    return response;
  });
}


function checkUserEmail(email) {
  const requestOptions = {
    method: 'GET'
  };

  return apiRequest('/api/check/email/' + email, requestOptions).then((response) => {
    return response;
  });
}

/**
 * Service which will get all registered users.
 * @param query
 * @return {object} all registered users.
 */
function getAll(query) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return apiRequest('/api/users?'+query, requestOptions).then((response) => {
    return response;
  });
}

/**
 * Service which will get all available accesses.
 * @return {object} all available accesses.
 */
function getAccesses() {
  const qs = require('qs');
  const query = qs.stringify({
    sort: ['name']
  });
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return apiRequest('/api/accesses?'+query, requestOptions).then((response) => {
    return response;
  });
}


/**
 * Service which will update user with specific id.
 * @param {user} user new features.
 * @return {object} response from server.
 */
function update(user) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: user
  };

  return apiRequest('/users', requestOptions);
}

// TODO change password function
/**
 * Service which will update user with specific id.
 * @param {currentPassword} current password.
 * @param {newPassword} new password.
 * @param {confirmNewPassword} confirmed new password.
 * @return {object} response from server.
 */
function changePassword(currentPassword, newPassword, confirmNewPassword) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: {
      currentPassword: currentPassword,
      newPassword: newPassword,
      confirmNewPassword: confirmNewPassword
    }
  };

  return apiRequest('/api/userprofile/change-password', requestOptions);
}

/**
 * Service which will delete user with specific id.
 * @param {id} id of the user.
 * @return {object} response from server.
 */
function _delete(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  };

  return apiRequest(`/users/${id}`, requestOptions);
}

/**
 * Service for getting user profil
 * @param {id} userID number.
 * @return {user} logged in user.
 */
function getUserProfile(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  // when we ask for the user profile, we also updated it
  const currUser = getCurrentUser();

  return apiRequest('/api/userprofile', requestOptions).then((profileResponse) => {
    const user = {
      id: profileResponse.id,
      username: profileResponse.username,
      name: profileResponse.fullName,
      email: profileResponse.email,
      token: currUser.token,
      favourites: profileResponse.favourites,

      isStaff: profileResponse.role.name == 'InLifeStaff' ||
        profileResponse.role.name == 'Editor' ||
        profileResponse.role.name == 'Admin' ? true : false,

      isEditor: profileResponse.role.name == 'Editor' ||
        profileResponse.role.name == 'Admin' ? true : false,

      isAdmin: profileResponse.role.name == 'Admin' ? true : false,

      noAccess: profileResponse.accesses.length == 0 ? true : false
    };

    localStorage.setItem('user', JSON.stringify(user));
    return user;
  });
}

/**
 * Service for setting favourite resources
 * @param {id} userID number.
 * @return {user} logged in user.
 */
function updateFavourite(resourceID, flag) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  // add user accesses to the user
  return apiRequest('/api/userprofile', requestOptions).then((res) => {
    const favourites = res.favourites.map((resource) => {
      return resource.id;
    });
    let skip = false;

    if (flag) {
      const index = favourites.indexOf(resourceID);
      if (index < 0) {
        favourites.push(resourceID);
      } else {
        skip = true;
      }
    } else {
      const index = favourites.indexOf(resourceID);
      if (index > -1) {
        favourites.splice(index, 1);
      } else {
        skip = true;
      }
    }

    // we can skip this step if the resource is already between the favourites
    if (!skip) {
      const requestOptions2 = {
        method: 'PUT',
        // headers: {
        //   Authorization: 'Bearer ' + process.env.VUE_APP_STRAPI_REG_KEY
        // },
        headers: authHeader(),
        body: {
          favourites: favourites
        }
      };

      return apiRequest('/api/userprofile/favourites', requestOptions2);
    }
  });
}

