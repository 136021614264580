<template>
  <v-container class="my-16" style="max-width:1024px;">
    <v-row justify="center">
      <v-card  class="pa-10" outlined flat rounded color="amber lighten-4" max-width="380">
        <v-card-title>
          <v-row justify="center">
            <v-img max-height="32" max-width="32" src="@/assets/logo-in-life.png" alt="In-Life logo"></v-img>
            <span class="mx-1 text--primary text-button">Zdroje</span>
          </v-row>
        </v-card-title>
        <v-form @submit.prevent="handleSubmit" class="mt-6">
          <h1 class="text-h6">Vytvoření hesla</h1>
          <p class="text--secondary text-body-2 mt-2">
            Vytvořte nové a bezpečné heslo. Nové heslo by mělo obsahovat alespoň <b>8 znaků</b>. Použijte kombinaci písmen (malých i velkých) a číslic.
          </p>
          <v-text-field
              v-model="password"
              label="Heslo"
              :rules="passwordRules"
              required color="amber"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              @click:append="show = !show"
          />
          <v-text-field
            v-model="confirm"
            label="Heslo znovu"
            :rules="confirmRules"
            required color="amber"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show2 ? 'text' : 'password'"
            @click:append="show2 = !show2"
          />
          <v-row>
            <v-btn
              class="mt-10 mx-auto"
              href="/login"
              elevation="0" x-small text plain
            >
              Jít na přihlášení
            </v-btn>
            <v-btn
              class="mt-8 mx-auto"
              elevation="0"
              :disabled="isValid ? false : true"
              @click="handleSubmit"
              color="amber lighten-2">
              Vytvořit
            </v-btn>
          </v-row>
        </v-form>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import { authCustomService } from '../services/auth-custom.service';

export default {
  data() {
    return {
      password: '',
      confirm: '',
      equality: 'true',
      show: false,
      show2: false,
      passwordRules: [
        (v) => !!v || 'Pole je povinné',
        (v) => (v && v.length >= 8) || 'Heslo musí mít alespoň 8 znaků'
      ],
      confirmRules: [
        (v) => !!v || 'Pole je povinné',
        (v) => this.equality || 'Hesla se nezhodují'
      ]
    };
  },
  computed: {
    isValid() {
      return this.password.length >= 8 && this.equality;
    }
  },
  methods: {
    ...mapActions('account', ['register']),
    ...mapActions('alert', {
      successAction: 'successAction',
      errorAction: 'errorAction'
    }),

    handleSubmit(e) {
      const token = this.$route.query.resetToken;
      authCustomService.resetPassword(token, this.confirm)
          .then((res) => {
            this.successAction('Heslo bylo úspěšně uloženo. Přihlaste se!');
            this.$router.push('/login');
          })
          .catch((res) => {
            this.errorAction('Uložení hesla selhalo: '+res.message+' ['+res.status+']');
          });
    }
  },
  updated() {
    this.equality = this.password == this.confirm ? true : false;
  },
  created() {
    const token = this.$route.query.resetToken;
    if (token == null) {
      this.$router.push('/login');
    }
  }
};
</script>
