<template>
    <div>
        <navigation-menu></navigation-menu>
        <v-container class="px-1 py-0" fluid>
            <h1 class="text-h6 ma-sm-3 text-sm-h4 font-weight-regular d-flex justify-center">Témata</h1>
            <v-divider class="amber lighten-4 mx-auto"></v-divider>
            <p class="text--disabled mt-4 text-body-2">
                Zdroje jsou řazeny do jednotlivých témat podle jejich obsahu. Jeden zdroj může být zařazen
                do několika témat. Kliknutím na konkrétní téma se zobrazí všechny dostupné zdroje daného tématu.
            </p>
            <div class="text-center" v-if="loading">
              <v-progress-circular
                class="mt-16"
                indeterminate
                color="grey"
              ></v-progress-circular>
              <v-card elevation="0" class="text--secondary mt-3">
                Načitám témata...
              </v-card>
            </div>
            <v-row>
                <v-col v-for="_topics in topics"  :key="_topics.id" cols="12" sm="6" md="6" lg="6" xl="6">
                    <v-list v-for="mainTopic in _topics" :key="mainTopic.id">
                        <v-list-item @click="clicked(mainTopic.name)">
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-medium my-n2">
                                  <v-badge color='grey lighten-3' inline dark
                                      :content="getResCount(mainTopic.name)"
                                  >
                                    {{mainTopic.name}}
                                  </v-badge>
                                </v-list-item-title>
                                <v-list v-for="topic in mainTopic.topics" :key="topic.id" >
                                    <v-list-item class="mx-4 my-n4 text--primary" @click="clicked(topic.name)">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                              <v-badge color='grey lighten-3' inline
                                                  :content="getResCount(topic.name)"
                                              >
                                              {{topic.name}}
                                              </v-badge>
                                            </v-list-item-title>
                                            <v-list v-for="leafTopic in topic.topics" :key="leafTopic.id" >
                                                <v-list-item class="mx-4 my-n4 text--secondary" @click="clicked(leafTopic.name)">
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                          <v-badge color='grey lighten-3' inline
                                                              :content="getResCount(leafTopic.name)"
                                                          >
                                                            {{leafTopic.name}}
                                                          </v-badge>
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { resourcesService } from '../services/resources.service';
import NavigationMenu from './NavigationMenu.vue';

export default {
  components: { NavigationMenu },
  data() {
    return {
      /**
         * pole (hlavních) témat. Každé téma může obsahovat další témata
         * (předpokladám, že budou stačit 3 úrovně)
         */
      topics: [],
      countMap: [],
      loading: false
    };
  },
  methods: {
    ...mapActions('resources', {
      storeSelected: 'storeSelected'
    }),
    ...mapActions('alert', {
      successAction: 'successAction',
      errorAction: 'errorAction'
    }),

    /**
         * Metoda clicked() zpracuje kliknutí uživatele na kontkrétní téma.
         * Toto téma vloží do objektu selected a ten uloží, pro ostatní komponenty
         * pomocí storeSelected().
         */
    clicked(topicName) {
      const selectedObj = {
        authors: [],
        themes: [{
          name: topicName // název vybraného tématu
        }],
        query: '',
        dateFrom: '',
        dateTo: '',
        sortDirection: 'createdAt:desc'
      };
      this.storeSelected(selectedObj);
      this.$router.push('/'); // přesměruje na home page
    },
    getResCount(topicName) {
      for (const topic of this.countMap) {
        if (topic.name === topicName) {
          return topic.resourcesCount;
        }
      }
      return '0';
    }
  },
  created() {
    this.loading = true;
    resourcesService.countResources('themes', undefined, true).then((themesCount) => {
      this.folders = [];
      for (const item of themesCount) {
        this.countMap = this.countMap.concat(item.themes);
      }

      resourcesService.getThemes(['name'], '*').then(
          (_topics) => {
            if (_topics) {
              this.topics = [];
              const topicsMap = new Map(); // témata uložená podle ID
              const tmpTopics = []; // všchna témata
              const mainTopics = []; // hlavní témata
              for (const _topic of _topics) {
                /**
                           *  Projde vrácenou odpověď z backendu, do this.topics uloží témata, která nemají
                           *  žádného rodiče.
                           */
                const topic = { id: 0, name: '', resources: 0, parent: null };
                topic.id = _topic.id;
                topic.name = _topic.attributes.name;
                topic.resources = (_topic.attributes.resources.data!=null) ? _topic.attributes.resources.data.length: 0;
                if (_topic.attributes.parent.data == null) {
                  // pokud téma nemá rodiče, vloží ho do pole topics
                  topic.parent = null;
                  mainTopics.push(topic);
                } else {
                  // pokud téma má rodiče uloží to proměnné parent ID rodičovského tématu
                  // v dalším průchodu ID nahradí objektem rodiče
                  topic.parent = _topic.attributes.parent.data.id;
                }
                topicsMap.set(topic.id, topic);
                tmpTopics.push(topic);
              }
              const halfLength = Math.round(mainTopics.length/2);
              this.topics[0] = mainTopics.slice(0, halfLength);
              this.topics[1] = mainTopics.slice(halfLength, mainTopics.length);
              for (const _topic of tmpTopics) {
                /**
                           * Projde pomocné pole tmpTopics a tématům nastaví místo ID rodiče objekt rodiče z topicsMap.
                           */
                if (_topic.parent != null) {
                  const _parent = topicsMap.get(_topic.parent);
                  if (_parent.topics==null) {
                    _parent.topics = [];
                  }
                  _parent.topics.push(_topic);
                }
              }
            }
            this.loading = false;
          }
      ).catch((res) => {
        this.errorAction('Načtení se nepodařilo: ' + res.status + ' ' + res.message);
      });
    }).catch((res) => {
      this.errorAction('Načtení se nepodařilo: ' + res.status + ' ' + res.message);
    });
  }
};
</script>

<style scoped>
div /deep/ .v-badge__badge{
  color: black !important
}
</style>
