<template>
  <div>
      <v-btn v-if="!drawer" icon tile @click="drawer = true" color="amber"
        style="position: absolute; left: 10px; top: 7px; z-index: 5;">
        <!-- < this will make this button on the top of app panel .. hack? :) > -->
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <v-navigation-drawer
        app
        clipped
        v-model="drawer"
        mobile-breakpoint="600"
        color="grey lighten-3"
        :outlined="false"
      >
        <v-subheader class="mt-2">
          <v-img max-height="24" max-width="24" src="@/assets/logo-in-life.png"></v-img>
          <span class="ms-2 text--primary text-button">zdroje</span>
        </v-subheader>
        <v-list dense>
          <v-list-item
            v-for="item in items"
            :key="item.title"
            :to="item.href"
            link
            active-class="bgAmber"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="user.isStaff || user.isAdmin"
            to="/users" link
            active-class="bgAmber"
          >
            <v-list-item-icon>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Uživatelé</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="user.isStaff || user.isAdmin"
            to="/materials" link
            active-class="bgAmber"
          >
            <v-list-item-icon>
              <v-icon>mdi-file-document-multiple</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Materiály</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list dense>
          <v-list-item class="px-3">
            <v-list-item-avatar color="amber lighten-2">
              <span class="dark--text body">{{ initials() }}</span>
            </v-list-item-avatar>
          <v-list-item-title v-if="user.name != null"> {{ user.name }}</v-list-item-title>
          <v-list-item-title v-if="user.name == null "> {{ user.username }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-for="item in profileItems"
            :key="item.title"
            :to="item.href"
            link
            active-class="bgAmber"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                {{ item.title }}
                <v-badge
                  v-if="item.href=='/sharings' && sharingCounts.sharedWithMe > 0"
                  class="ms-1 text--primary font-weight-bold" color="grey darken-1"
                  :content="sharingCounts.sharedWithMe">
                </v-badge>
                </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            @click="logout"
            to="/login"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-logout-variant</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Odhlásit</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

      </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { userService } from '../services/user.service';
import { utils } from '../utils/utils';

export default {
  data() {
    return {
      drawer: 'null',
      items: [
        { title: 'Home', icon: 'mdi-magnify', href: '/' },
        { title: 'Řečníci', icon: 'mdi-account', href: '/speakers' },
        { title: 'Témata', icon: 'mdi-format-list-bulleted', href: '/topics' },
        { title: 'Skupiny', icon: 'mdi-select-group', href: '/folders' }
      ],
      profileItems: [
        { title: 'Profil', icon: 'mdi-account-cog', href: '/profile' },
        { title: 'Oblíbené', icon: 'mdi-heart', href: '/favourites' },
        { title: 'Sdílení', icon: 'mdi-account-multiple-outline', href: '/sharings' }
      ],
      mini: false
    };
  },
  created() {
    window.innerWidth > 600 ? this.drawer = true : this.drawer = false;
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      sharingCounts: (state) => state.resources.sharingCounts
    })
  },
  methods: {
    logout() {
      userService.logout();
    },
    initials() {
      if (this.user.name != null) {
        return utils.getInitials(this.user.name);
      } else {
        return this.user.username.substr(0, 2).toUpperCase();
      }
    }
  }

};

</script>
<style scoped>
.bgAmber{
  background-color: #EEEEEE !important;
  color : #ffc107 !important;
}
.v-navigation-drawer >>> .v-navigation-drawer__border {
  display: none
}
</style>
