import 'regenerator-runtime/runtime';
import apiRequest from './apiRequest';
import { authHeader } from '../utils';

export const sharingService = {
  counts,
  share,
  unshare,
  shared,
  sharings
};

/**
 * Gets a count of sharing connected with the autenticated user.
 */
function counts() {
  const request = {
    method: 'GET',
    headers: authHeader()
  };
  return apiRequest('/api/sharings/counts', request);
}

/**
 * Create a new sharing for resource specified by {resID}.
 * @param {*} sharerID identifier of user who shares resource
 * @param {*} validTo end of sharing (format yyyy-mm-dd hh:mm:ss)
 * @param {*} shareTo email of the person to whom is resource shared
 * @param {*} resID identifier of the resource
 */
function share(sharerID, validTo, shareTo, resID) {
  const request = {
    method: 'POST',
    headers: authHeader(),
    body: {
      'data': {
        'sharer': sharerID,
        'validTo': validTo,
        'shareTo': shareTo,
        'resource': resID
      }
    }
  };
  return apiRequest('/api/share', request);
};

/**
 * Delete sharings specified by {sharingID}.
 * @param {*} sharingID identifier of sharing to delete
 */
function unshare(sharingID) {
  const request = {
    method: 'DELETE',
    headers: authHeader()
  };
  return apiRequest('/api/unshare/'+sharingID, request);
}

/**
 * Gets a shared resource defined by the {token}.
 * @param {*} token hash computed when sharing was created
 */
function shared(token) {
  const request = {
    method: 'GET',
    headers: authHeader()
  };
  return apiRequest('/api/shared/'+token, request);
}

/**
 * Gets all user's sharings.
 */
function sharings() {
  const request = {
    method: 'GET',
    headers: authHeader()
  };
  return apiRequest('/api/sharings', request);
}
