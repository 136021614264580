<template>
    <div>
        <navigation-menu></navigation-menu>
        <v-container class="mt-4 pa-2" fluid>
            <!-- <h1 class="text-h4 text-sm-h3 font-weight-light">Nezpracované zdroje</h1>
            <v-divider class="amber"></v-divider>
            <p class="text--secondary mt-4 font-weight-light">
                Na této stránce najdete zdroje, kterým je potřeba přidelit téma a popisek
            </p> -->
            <resource-container-to-process></resource-container-to-process>
        </v-container>
    </div>
</template>

<script>

import ResourceContainerToProcess from './ResourceContainerToProcess.vue';
import NavigationMenu from './NavigationMenu.vue';

export default {
  components: { NavigationMenu, ResourceContainerToProcess }
};

</script>
