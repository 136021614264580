<template>
  <v-app>
    <v-main>
      <v-container fluid>
        <v-snackbar
            v-model="snackbar"
            :timeout="timeout"
            elevation="16"
            :color="colorVariant"
            top center
            rounded
            app
            class="mt-4 pa-2"
            max-width="320px;"
        >
          <span class="black--text">{{ text.trim() }}</span>
          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              v-bind="attrs"
              @click="snackbar = false"
            >
              <v-icon class="black--text">mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
        <router-view></router-view>
      </v-container>
    </v-main>
    <v-footer app>
      <div class="mx-auto caption">
        Portál <a href="https://zdroje.org" target="_blank" rel="noopener noreferrer" class="orange--text font-weight-bold text-decoration-none">Zdroje</a> <a href="https://in-life.org" target="_blank" rel="noopener noreferrer" class="black--text text-decoration-none">In-Life</a> &copy; 2022-{{ (new Date()).getFullYear() }}
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  data: () => ({
    snackbar: false,
    text: '',
    timeout: 3000,
    colorVariant: ''
  }),
  components: {},
  computed: {
    ...mapState({
      alert: (state) => state.alert
    })
  },
  methods: {
    ...mapActions('alert', {
      clearAction: 'clearAction'
    })
  },
  watch: {
    '$store.state.alert.fire'() {
      if (this.alert.fire == 'true') {
        if (this.alert.type == 'alert-danger') {
          this.text = this.alert.message;
          this.colorVariant = 'red';
          this.snackbar = true;
          this.clearAction();
        } else {
          this.text =this.alert.message;
          this.colorVariant = 'amber';
          this.snackbar = true;
          this.clear;
          this.clearAction();
        }
      }
    }
  }
};
</script>

<style>
  .v-application ol, .v-application ul {
      padding-left: 0px !important;
  }
</style>
