<template>
  <div>
    <v-btn v-if="!drawer" icon tile @click="drawer = true"  color="amber"
        style="position: absolute; right: 10px; top: 7px; z-index: 5;" title="Kontextová nabídka ..." >
        <!-- < this will make this button on the top of app panel .. hack? :) > -->
        <v-icon>mdi-chevron-double-left</v-icon>
    </v-btn>
    <v-navigation-drawer
      app
      right
      clipped
      v-model="drawer"
      mobile-breakpoint="1000"
      color="grey lighten-3"
    >
      <v-list dense>
        <v-subheader  class="ma-2">
          <span class="text-button text--secondary">Nástroje</span>
        </v-subheader>
        <v-list-item>
          <v-list-item-content>
            <ReportForm
              :resid="resourceID" btnText="Navrhnout úpravu">
            </ReportForm>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-btn @click="$emit('toggle-favourite')" small elevation="0" color="amber lighten-2 rounded-lg">
                <v-icon>{{ !favourite ? "mdi-cards-heart-outline" : "mdi-cards-heart" }}</v-icon>
                <span class="ms-1">
                  {{ !favourite ? "Přidat do oblíbených" : "Odstranit z oblíbených" }}
                </span>
            </v-btn>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <share-form :resid="resourceID"></share-form>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list v-show="folders.length>0" class="mt-4">
        <v-subheader>
          <span class="text--secondary text-button text-caption">Související zdroje</span>
        </v-subheader>
        <v-card
          v-for="folder in folders" :key="folder"
          class="mx-3 mb-6 rounded-lg"
          flat
          color="grey lighten-3"
        >
          <p class="text--secondary text-body-2 mx-2 mb-n2 font-italic">
            {{ folder }}
          </p>
          <v-list-item v-for="res in resources[folders.indexOf(folder)]" :key="res.id" class="mb-n4">
            <v-list-item-content>
              <v-card flat color='amber lighten-4' class="rounded-lg" @click="$emit('show-resource',res.id)">
                <v-card-text class="text-h6 text-center">
                  {{ res.attributes.title }}<br>
                  <div class="text-center text--disabled">
                    <span v-for="(author,index) in res.attributes.authors.data" :key="author.id" class="text-subtitle-2">
                      {{ author.attributes.fullName }}
                      <span v-if="index < res.attributes.authors.data.length-1">|</span>
                    </span>
                  </div>
                </v-card-text>
              </v-card>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import ReportForm from './ReportForm.vue';
import ShareForm from './ShareForm.vue';
import { resourcesService } from '../services/resources.service';

export default {
  components: { ReportForm, ShareForm },
  props: ['resourceID', 'favourite'],
  data() {
    return {
      drawer: 'null',
      mini: false,
      folders: [],
      resources: []
    };
  },
  watch: {
    'resourceID'() {
      this.loadRelated();
    }
  },
  created() {
    window.innerWidth > 1000 ? this.drawer = true : this.drawer = false;
  },
  methods: {
    loadRelated() {
      this.folders = [];
      this.resources = [];

      resourcesService.getResourceByQuery(this.resourceID, ['folders']).then((resource) => {
        const folders = resource.data.attributes.folders.data;
        for (const folder of folders) {
          const folderName = folder.attributes.name;
          this.folders.push(folderName);
          this.resources.push([]);

          resourcesService.getRelatedResources(folder.id, this.resourceID).then((folder) => {
            folder.data.attributes.resources.data.forEach((resource) => {
              if (resource.id != this.resourceID) {
                this.resources[this.folders.indexOf(folderName)].push(resource);
              }
            });
          });
        }
      });
    }
  },
  created() {
    // this line makes drawer to be false when page is loaded on small screens
    window.innerWidth > 1000 ? this.drawer = true : this.drawer = false;

    this.loadRelated();
  }
};
</script>

<style scoped>
.v-navigation-drawer >>> .v-navigation-drawer__border {
  display: none
}
</style>
