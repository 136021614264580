<template>
  <div class="mt-2">
    <v-tabs color='amber' grow mobile-breakpoint>
      <v-tab class="px-2" title="Nezpracované zdroje..."><v-icon class="mx-1">mdi-alert-octagon-outline</v-icon><span class="d-none d-sm-flex">Nezpracované</span></v-tab>
      <v-tab-item>
        <resource-media-tab filter='NonProcessed'></resource-media-tab>
      </v-tab-item>
      <v-tab class="px-2" title="Zdroje bez témat..."><v-icon class="mx-1">mdi-alert-octagon-outline</v-icon><span class="d-none d-sm-flex">Chybí téma</span></v-tab>
      <v-tab-item>
        <resource-media-tab filter='MissingTheme'></resource-media-tab>
      </v-tab-item>
      <v-tab title="Zdroje bez popisku ..."><v-icon class="mx-1">mdi-alert-octagon-outline</v-icon><span class="d-none d-sm-flex">Chybí popisek</span></v-tab>
      <v-tab-item>
        <resource-media-tab filter='MissingDescription'></resource-media-tab>
      </v-tab-item>
   </v-tabs>
  </div>
</template>

<script>
import ResourceMediaTab from './ResourceMediaTab.vue';

export default {
  components: { ResourceMediaTab }
};
</script>
