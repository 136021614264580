<template>
    <div>
        <navigation-menu></navigation-menu>
        <v-container class="px-1 py-0" fluid>
          <h1 class="text-h6 ma-sm-3 text-sm-h4 font-weight-regular d-flex justify-center">Oblíbené zdroje</h1>
          <v-divider class="amber lighten-4 mx-auto"></v-divider>
          <p
            v-if="authorsMap != null && authorsMap.size > 0"
            class="text--disabled mt-6 text-body-2"
          >
            Seznam oblíbených zdrojů seskupených podle řečníka.
          </p>
          <div class="text-center" v-if="authorsMap == null">
            <v-progress-circular
              class="mt-16"
              indeterminate
              color="grey"
            ></v-progress-circular>
            <v-card elevation="0" class="text--secondary mt-3">
              Načitám zdroje...
            </v-card>
          </div>
          <div class="text--disabled mt-8 text-body-2" elevation="0" v-if="authorsMap != null && authorsMap.size == 0">
            <v-card elevation="0" class="text--disabled">
              <p>Nebyly nalezeny žádné zdroje.</p>
              Pro přidání zdroje mezi oblíbené najdi zdroj a&nbsp;klikni na symbol <v-icon>mdi-heart-outline</v-icon> pod jeho názvem.
            </v-card>
          </div>
          <div v-if="authorsMap != null">
              <div v-for="author in authorsMap.keys()" :key="author">
                  <v-col>
                      <v-row cols="12">
                          <v-btn
                              class="mx-1 pa-2 text-none"
                              large elevation="0" text  color="black"
                              >
                                  {{ author }}
                          </v-btn>
                      </v-row>
                      <v-row cols="12" class="d-flex flex-wrap mx-auto">
                        <v-card
                          v-for="resource in authorsMap.get(author)" :key="resource.id"
                          width="200" elevation="0" color="amber lighten-4" outlined class="ma-1 rounded-lg"
                          @click="showResource(resource.id)"
                        >
                          <v-card-text class="text-h6 text-center">
                            {{ resource.attributes.title }}<br>
                            <div class="text-center text--disabled mt-2">
                              <span v-for="(author,index) in resource.attributes.authors.data" :key="author.id" class="text-subtitle-2">
                                {{ author.attributes.fullName }}
                                <span v-if="index < resource.attributes.authors.data.length-1">|</span>
                              </span>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-row>
                  </v-col>
              </div>
          </div>
        </v-container>
    </div>

</template>

<script>
import { mapState } from 'vuex';
import NavigationMenu from './NavigationMenu.vue';
import { userService } from '../services/user.service';
import { resourcesService } from '../services/resources.service';
import { mapActions } from 'vuex';

export default {
  components: { NavigationMenu },
  data() {
    return {
      authorsMap: null
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user
    }),
    ...mapActions('alert', {
      errorAction: 'errorAction'
    })
  },
  created() {
    userService.getUserProfile(this.user.id).then((profile) => {
      // normally I would do idsNumber = resIds.lenght .. but it doesn't work!
      // I need to count it in this map
      const map = new Map();
      let idsNumber = 0;
      const resIds = profile.favourites.map((resource) => {
        idsNumber++;
        return resource.id;
      });

      let counter = 0;


      for (const id of resIds) {
        resourcesService.getResource(id).then((response) => {
          let authorName = 'Neznámy';

          if (response.data.attributes.authors.data.length > 0) {
            authorName = response.data.attributes.authors.data[0].attributes.fullName;
          }

          if (map.has(authorName)) {
            let value = map.get(authorName);
            value = value.concat( [response.data]);
            map.set(authorName, value);
            counter++;
          } else {
            map.set(authorName, [response.data]);
            counter++;
          }

          // need to wait till all resources are loaded
          if (counter == idsNumber) {
            this.authorsMap = new Map([...map.entries()].sort());
          }
        }).catch((error) => {
          if (error.status == 404 || error.status == 403) {
            // it can happen that user lost access to the resource he/she has between favorites
            // in this case, we don't need to do nothing, just continue
            counter++;
          }
        });
      }
      // if there is no favourites give it 0
      if (resIds == 0) {
        this.authorsMap = new Map();
      }
    }).catch((error) => {
      this.errorAction(error+response.status);
    });
  },
  methods: {
    showResource(resID) {
      this.$router.push('/resource/'+resID);
    }
  }
};
</script>

