<template>
    <div>
        <navigation-menu></navigation-menu>
        <v-container class="px-1 py-0" fluid>
            <h1 class="text-h6 ma-sm-3 text-sm-h4 font-weight-regular d-flex justify-center">Skupiny</h1>
            <v-divider class="amber lighten-4 mx-auto"></v-divider>
            <p class="text--disabled mt-4 text-body-2">
                Přehled skupin zdrojů ražené podle roku vzniku. Zdroje, které patří logicky k sobě jsou přidané do skupin (např. jsou z jednoho pobytu, patří do
                jedné série, příp. podle jiného kritéria).
            </p>
            <div class="text-center" v-if="loading">
              <v-progress-circular
                class="mt-16"
                indeterminate
                color="grey"
              ></v-progress-circular>
              <v-card elevation="0" class="text--secondary mt-3">
                Načitám skupiny...
              </v-card>
            </div>
            <v-row>
                <v-col v-for="year in years"  :key="year" cols="12" sm="6" md="6" lg="6" xl="6">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title v-if="year!='undefined'" class="font-weight-medium">{{year}}</v-list-item-title>
                            <v-list-item-title v-if="year=='undefined'" class="font-weight-medium">Nezařazené</v-list-item-title>
                              <v-list v-for="folder in findFolders(year)" :key="folder.id" >
                                <v-list-item class="mx-4 my-n4 text--primary" @click="clicked(folder.name)">
                                  <v-list-item-content>
                                      <v-list-item-title>{{folder.name}}</v-list-item-title>
                                  </v-list-item-content>
                              </v-list-item>
                            </v-list>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { resourcesService } from '../services/resources.service';
import NavigationMenu from './NavigationMenu.vue';

export default {
  components: { NavigationMenu },
  data() {
    return {
      folders: [],
      years: [],
      loading: false
    };
  },
  methods: {
    ...mapActions('resources', {
      storeSelected: 'storeSelected'
    }),

    /**
         * Metoda clicked() zpracuje kliknutí uživatele na kontkrétní skupinu.
         * Meno teto skupiny vloží do objektu selected a ten uloží, pro ostatní komponenty
         * pomocí storeSelected().
         */
    clicked(folderName) {
      const selectedObj = {
        authors: [],
        themes: [],
        query: folderName,
        dateFrom: '',
        dateTo: '',
        sortDirection: 'createdAt:desc'
      };
      this.storeSelected(selectedObj);
      this.$router.push('/'); // přesměruje na home page
    },
    findFolders(year) {
      return this.folders.filter((folder) => {
        return folder.year == year;
      });
    }
  },
  created() {
    this.loading = true;
    resourcesService.countResources('folders', undefined, true).then((folders) => {
      if (folders) {
        this.folders = [];
        for (const item of folders) {
          this.folders = this.folders.concat(item.folders);
        }

        // get years from every folder
        this.years = this.folders.map((folder) => {
          return folder.year;
        });

        // get unique years from every folder
        this.years = this.years.filter((item, pos) => {
          return this.years.indexOf(item)== pos;
        });

        // sort years and put 'undefined on the end'
        this.years.sort();
        this.years.reverse();
        if (this.years && this.years[0] == 'undefined') {
          this.years.shift();
          this.years.push('undefined');
        }
      }
      this.loading = false;
    });
  }
};
</script>

<style>
</style>
