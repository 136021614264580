<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="300"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="amber lighten-2"
          elevation="0"
          v-bind="attrs"
          v-on="on"
        >
          Změna hesla
        </v-btn>
      </template>

      <v-card class="pa-2">
        <v-card-title>
          <span class="text-h5 text--center">Změna hesla</span>
        </v-card-title>

        <v-container>
          <v-text-field
            v-model="currentPassword"
            label="Současné heslo"
            :rules="oldPasswordRules"
            required color="amber"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show ? 'text' : 'password'"
            @click:append="show = !show"
            ref="oldPassword"
            @keydown="oldValid = true"
            />
          <v-text-field
            v-model="newPassword"
            label="Nové heslo"
            :rules="passwordRules"
            required color="amber"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show2 ? 'text' : 'password'"
            @click:append="show2 = !show2"
          />
          <v-text-field
            v-model="confirmNewPassword"
            label="Nové heslo znovu"
            :rules="confirmRules"
            required color="amber"
            :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show3 ? 'text' : 'password'"
            @click:append="show3 = !show3"
          />
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="amber lighten-2"
            elevation="0"
            @click="changePassword"
            :disabled="currentPassword && newPassword && confirmNewPassword && (newPassword === confirmNewPassword) ? false : true"
          >
            Uložit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { userService } from '../services/user.service';

export default {
  data() {
    return {
      dialog: false,
      favourites: [],
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      oldValid: true,
      show: false,
      show2: false,
      show3: false,
      confirmRules: [
        (v) => !!v || 'Pole je povinné',
        (v) => v === this.newPassword || 'Hesla se neshodují'
      ],
      passwordRules: [
        (v) => !!v || 'Pole je povinné',
        (v) => (v && v.length >= 8) || 'Heslo musí mít alespoň 8 znaků'
      ],
      oldPasswordRules: [
        (v) => !!v || 'Pole je povinné',
        () => this.oldValid || 'Heslo neni stejné'
      ]
    };
  },
  methods: {
    ...mapActions('alert', {
      successAction: 'successAction',
      errorAction: 'errorAction'
    }),
    changePassword() {
      userService.changePassword(this.currentPassword, this.newPassword, this.confirmNewPassword)
          .then((res) => {
            this.successAction('Heslo bylo uloženo.');
            this.dialog = false;
          })
          .catch((res) => {
            if (res.message === 'Old password does not match.') {
              this.errorAction('Současné heslo je nesprávné');
              this.oldValid = false;
              this.$refs.oldPassword.validate();
            } else {
              this.errorAction('Uložení hesla se nezdařilo: '+res.status);
            }
          });
    }
  }
};

</script>
