<template>
  <div>
    <navigation-menu></navigation-menu>
    <v-container class="px-1 py-0" fluid>
      <h1 class="text-h6 ma-sm-3 text-sm-h4 d-flex justify-center mt-n1 font-weight-regular">Uživatelé</h1>
      <v-divider class="amber lighten-4 mx-auto mt-1"></v-divider>
      <p class="text--disabled text-body-2 mt-4">
        Seznam uživatelů portálu včetně přístupu, který mají ke zdrojům. <span v-if="user.isAdmin">Pro změnu přístupu klikněte na tlačítko
        <v-icon color="grey">mdi-shield-account-variant</v-icon>. Pro odeslání zprávy uživatelům použijte
        <v-icon color="grey">mdi-email</v-icon>.</span>
      </p>
      <v-data-table
        :headers="headers" :items="users" sort-by="['fullName']"
        :page.sync="page" :items-per-page="itemsPerPage"
        hide-default-footer @page-count="pageCount = $event"
        :search="search" :custom-filter="filter" color="amber"
      >
        <template v-slot:top>
          <v-toolbar flat class="mt-n1 mb-1">
            <v-row>
              <v-col>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Zadej jméno uživatele ..."
                  single-line filled rounded hide-details color="amber"
                >
                </v-text-field>
              </v-col>
              <v-col v-if="user.isAdmin" cols="2">
                <v-dialog v-model="dialogSend" max-width="320px" persistent>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon x-large class="float-right"
                      v-bind="attrs" v-on="on" title="Odeslat zprávu uživatelům ..."
                    >
                      <v-icon>mdi-email</v-icon>
                    </v-btn>
                  </template>
                  <v-card class="py-2">
                    <v-card-title>Odeslat zprávu ...</v-card-title>
                    <v-card-text>
                      Vyberte skupinu (dle přístupu), které chcete odeslat emailovou zprávu, zadejte předmět a text zprávy.
                    </v-card-text>
                    <v-card-text>
                      <v-row class="mt-n4">
                        <v-col class="py-0">
                          <v-select
                            v-model="sendTo" :items="groups" label="Příjemci:"
                            multiple item-color="amber" color="amber"
                          >
                          </v-select>
                        </v-col>
                      </v-row>
                      <v-row class="mt-n4">
                        <v-col class="py-0">
                          <v-text-field
                            color="amber" label="Přemět ..."
                            v-model="emailSubject"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="mt-n4">
                        <v-col class="py-0">
                          <v-textarea
                            color="amber" label="Text zprávy ..."
                            v-model="emailText"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions class="mt-n4">
                      <v-spacer></v-spacer>
                      <v-btn elevation="0" @click="dialogSend = false">
                        Zpět
                      </v-btn>
                      <v-btn color="amber" elevation="0" @click="send()">
                        Odeslat
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
            <v-dialog v-model="dialog" max-width="320px" persistent>
              <v-card class="py-2">
                <v-card-title>Úprava přístupu ...</v-card-title>
                <v-card-text>
                  Zvolte skupiny, do kterých uživatel patří, tím bude určeno
                  k jakým zdrojům bude mít přístup.
                </v-card-text>
                <v-card-text>
                  <v-list>
                    <v-list-item v-for="access in allAccesses" :key="access.id">
                      <template v-slot:default="{ }">
                        <v-list-item-action>
                          <v-checkbox v-model="selectedAccess" color="amber" :label="access.name" :value="access">
                          </v-checkbox>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                  </v-list>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn elevation="0" @click="close">
                    Zpět
                  </v-btn>
                  <v-btn color="amber" elevation="0" @click="save()">
                    Uložit
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.fullName`]="props">
          <span class="font-weight-bold text--primary">
            {{ props.item.fullName }}
          </span>
        </template>
        <template v-slot:[`item.ministry`]="props">
          <span class="'font-italic text--secondary">
            {{ props.item.ministry }}
          </span>
        </template>
        <template v-slot:[`item.email`]="props">
          <span class="'font-italic text--secondary">
            {{ props.item.email }}
          </span>
        </template>
        <template v-slot:[`item.accesses`]="{ item }">
          <v-btn v-if="item.accesses.length>0" @click="editItem(item.id)" text x-small :disabled="user.isStaff && !user.isAdmin">
            <v-icon>mdi-shield-account-variant</v-icon>
            {{ item.accesses.map((v) => v.name).join(' | ') }}
          </v-btn>
          <v-btn v-else @click="editItem(item.id)" text class="text--disabled" x-small :disabled="user.isStaff && !user.isAdmin">
            <v-icon>mdi-shield-account-variant</v-icon>
            Bez přístupu
          </v-btn>
        </template>
        <template v-slot:[`item.role`]= "{ item }">
          <span v-if="item.role != undefined" class="text--disabled">
            <i>{{ item.role.name }}</i>
          </span>
        </template>
        <template v-slot:footer>
          <v-row class="mt-1">
            <v-col cols="12" md="3">
              <v-select
                v-model="selectedMinistry" :items="allMinistries" outlined dense
                color="amber" item-color="amber" @change="filterByMinistry"
              >
              </v-select>
            </v-col>
            <v-col cols="10" md="7">
              <v-pagination v-model="page" :length="pageCount" color="amber">
              </v-pagination>
            </v-col>
            <v-col cols="2" md="2">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="amber lighten-2 mt-1" light v-bind="attrs" v-on="on"
                  class="float-right" title="Počet uživatelů na stránku" elevation="0"
                >
                  {{ itemsPerPage }}
                </v-btn>
                </template>
                <v-list>
                  <v-list-item-group>
                    <v-list-item disabled dense class="text-overline"><small>Na stránku</small></v-list-item>
                    <v-list-item @click="setItemsPerPage(10)">
                      <v-list-item-title class="px-2 text--secondary">10</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="setItemsPerPage(20)">
                      <v-list-item-title class="px-2 text--secondary">20</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="setItemsPerPage(50)">
                      <v-list-item-title class="px-2 text--secondary">50</v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import NavigationMenu from './NavigationMenu.vue';
import { mapActions, mapState } from 'vuex';
import { userService } from '../services/user.service';
import { adminService } from '../services/admin.service';

export default {
  components: { NavigationMenu },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      search: '',
      dialog: false,
      dialogSend: false,
      editedUser: {
        id: 0,
        name: '',
        accesses: []
      },
      sendTo: [],
      groups: [],
      selectedAccess: [],
      allAccesses: [],
      headers: [],
      users: [],
      allUsers: [],
      allMinistries: [],
      selectedMinistry: '',
      emailSubject: '',
      emailText: ''
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user
    })
  },
  methods: {
    ...mapActions('alert', {
      successAction: 'successAction',
      errorAction: 'errorAction'
    }),

    filter(value, search, item) {
      const name = item.fullName;
      return name != null &&
        search != null &&
        typeof name === 'string' &&
        name.toString().indexOf(search) !== -1;
    },

    filterByMinistry() {
      if ( this.selectedMinistry == 'Všechny služby') {
        this.users = this.allUsers;
      } else {
        const _users = this.allUsers.filter((user) => {
          if (user.ministry == this.selectedMinistry) {
            return user;
          }
        });
        this.users = _users;
      }
    },

    send() {
      const sendToUsers = this.users.filter((user)=>{
        const hasSome = user.accesses.some((access)=>{
          return this.sendTo.indexOf(access.name) !== -1;
        });
        if (hasSome) {
          return user.id;
        }
      });
      const userIDs = sendToUsers.map((user)=>{
        return user.id;
      });
      adminService.sendmail(userIDs, this.emailSubject, this.emailText)
          .then((res)=>{
            this.successAction('Zpráva byla odeslána.');
          })
          .catch((res)=>{
            this.errorAction('Odesální zprávy selhalo: '+res.status+' '+res.message);
          });
      this.dialogSend = false;
    },

    save() {
      this.dialog = false;
      const accessesIDs = this.selectedAccess.map((access) => {
        return access.id;
      });
      adminService.updateAccesses(this.editedUser.id, accessesIDs)
          .then((res) =>{
            const user = this.users.find((u) => u.id === this.editedUser.id);
            user.accesses = this.selectedAccess;
            user.accesses.sort((a, b)=>{
              return a.name.toUpperCase().localeCompare(b.name.toUpperCase());
            });
            this.successAction('Nastavení přístupu bylo uloženo.');
          })
          .catch((res) => {
            this.errorAction('Při ukládání změn došlo k chybě: '+res.status+' '+res.message);
          });
    },

    editItem(userId) {
      this.editedUser = this.users.find((u) => u.id === userId);

      // I need to find proper object from allAcceses to pass it to selected
      // so checkbox can match it
      this.selectedAccess = [];
      for (const userAccess of this.editedUser.accesses) {
        for (const access of this.allAccesses) {
          if (userAccess.id == access.id) {
            this.selectedAccess.push(access);
          }
        }
      }
      this.dialog = true;
    },

    setItemsPerPage(perPage) {
      this.itemsPerPage = perPage;
    },
    sortAccess(a, b) {
      // aaaaaaaaaaa means that 'Bez prístupu' will be always first of last
      let accessNameA = 'aaaaaaaaaaa';
      let accessNameB = 'aaaaaaaaaaa';

      if (a.length != 0) {
        accessNameA = a[0].name;
      }
      if (b.length != 0) {
        accessNameB = b[0].name;
      }
      return accessNameA.localeCompare(accessNameB);
    },

    /**
     * Initialize page data. Get users and accesses from the backend.
     */
    initialize() {
      if (this.user.isAdmin) {
        this.headers = [{ text: 'Jméno', value: 'fullName', filterable: true },
          { text: 'Služba', value: 'ministry', filterable: false },
          { text: 'E-mail', value: 'email', filterable: false },
          { text: 'Přístup', value: 'accesses', filterable: false, sort: (a, b) => this.sortAccess(a, b) },
          { text: 'Role', value: 'role', filterable: false, sort: (a, b) => a.name.localeCompare(b.name) }
        ];
      } else {
        this.headers = [{ text: 'Jméno', value: 'fullName', filterable: true },
          { text: 'Služba', value: 'ministry', filterable: false },
          { text: 'E-mail', value: 'email', filterable: false },
          { text: 'Přístup', value: 'accesses', filterable: false, sort: (a, b) => this.sortAccess(a, b) }
        ];
      }
      const qs = require('qs');
      const query = qs.stringify({
        sort: ['fullName'],
        populate: {
          accesses: {
            sort: ['name']
          },
          role: {
            sort: ['name']
          }
        }
      });

      // get users and acceses
      userService.getAll(query)
          .then((response) => {
            this.users = response;
            this.allUsers = this.users;
            const _ministries = new Set();
            this.users.forEach((user) => {
              _ministries.add(user.ministry);
            });
            this.allMinistries = [];
            this.allMinistries.push('Všechny služby');
            for (const ministry of _ministries.values()) {
              this.allMinistries.push(ministry);
            }
            this.allMinistries.sort();
            this.selectedMinistry = 'Všechny služby';
          });

      // get all available accesses
      userService.getAccesses().then((response) => {
        this.allAccesses = response.data.map((access) => {
          return {
            id: access.id,
            name: access.attributes.name
          };
        });
        this.groups = this.allAccesses.map((access)=>{
          return access.name;
        });
      });
    },

    /**
     * Close dialog and undo changes.
     */
    close() {
      this.dialog = false;
      this.editedIndex = -1;
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  created() {
    this.initialize();
  }
};
</script>

<style scoped>
div /deep/ .theme--light.v-pagination .v-pagination__item--active{
  color: black;
}
.v-application .amber.lighten-2{
    color: black;
}
div /deep/ .v-pagination__item {
  /* this is to remove elevation on the pagination */
  box-shadow: none !important
}
div /deep/ .v-pagination__navigation {
  /* this is to remove elevation on the pagination */
  box-shadow: none !important
}
</style>
