import { STORE_SELECTED, CLEAN_SELECTED, STORE_IDS, CLEAN_IDS, STORE_SHARING_COUNTS, CLEAN_SHARING_COUNTS } from '../actions';
import { SET_LOADING, SET_SELECTED, SET_IDS } from '../mutations';

/*
  * resources: all resources loaded from DB
  *   - it is used to render table on HomePage.vue component
  * authors: list of all authors
  *   - it is used to render autocomplete element on SearchBar.vue component
  * themes: list of all themes
  *   - it is used to render autocomplete element on SearchBar.vue component
  * selected: used to set filter option on different Vue component
  *   - used on DetailPage component, list of authors and themes
  */
const state = {
  loading: 'progress',
  selected: {
    authors: [],
    themes: [],
    query: '',
    exactSearch: false,
    dateFrom: '',
    dateTo: '',
    sortDirection: 'createdAt:desc'
  },
  sharingCounts: {
    sharedWithMe: 0,
    iShare: 0
  },
  resIDs: []
};

const actions = {

  // used byt VUE commponents to store object with selected
  // authors, themes and query for SearchBar componet during creation phase
  [STORE_SELECTED]({ commit }, selected) {
    commit(SET_SELECTED);
    commit(SET_SELECTED, selected);
  },

  // use this method to clean selected properties
  [CLEAN_SELECTED]({ commit }) {
    commit(SET_SELECTED);
    const obj = {
      authors: [],
      themes: [],
      query: ''
    };
    commit(SET_SELECTED, obj);
  },

  // used byt VUE commponents to store object with selected
  // authors, themes and query for SearchBar componet during creation phase
  [STORE_IDS]({ commit }, IDs) {
    commit(SET_IDS);
    commit(SET_IDS, IDs);
  },

  // use this method to clean list of selected resource IDs
  [CLEAN_IDS]({ commit }) {
    commit(SET_IDS);
    commit(SET_IDS, []);
  },

  // used byt VUE commponents to store count of shared resources
  // (sharedWithMe and iShare)
  [STORE_SHARING_COUNTS]({ commit }, sharingCounts) {
    commit(STORE_SHARING_COUNTS);
    commit(STORE_SHARING_COUNTS, sharingCounts);
  },

  // use this method to clean count of shared resources
  [CLEAN_SHARING_COUNTS]({ commit }) {
    commit(STORE_SHARING_COUNTS);
    commit(STORE_SHARING_COUNTS, []);
  }
};

const mutations = {
  [SET_LOADING](state, res) {
    state.loading = res;
  },
  [SET_SELECTED](state, res) {
    state.selected = res;
  },
  [SET_IDS](state, res) {
    state.resIDs = res;
  },
  [STORE_SHARING_COUNTS](state, counts) {
    state.sharingCounts = counts;
  }
};

export const resources = {
  namespaced: true,
  state,
  actions,
  mutations
};
