<template>
  <div class="ma-2">
    <v-col>
      <v-row cols="12">
        <v-text-field
          v-model="selectedObject.query"
          filled
          hide-details="auto"
          label="Zadejte slovo nebo frázi ..."
          clearable
          rounded
          single-line
          color="amber"
          prepend-inner-icon="mdi-magnify"
          class="ma-sm-4 ma-md-8 ma-lg-13"
        >
          <v-tooltip slot="append" top>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                color="black"
                dark
                @click="
                  selectedObject.exactSearch === 'true'
                    ? ((selectedObject.exactSearch = 'false'),
                      (iconVariant = 'mdi-format-letter-case'))
                    : ((selectedObject.exactSearch = 'true'),
                      (iconVariant = 'mdi-format-letter-case-upper'))
                "
              >
                {{ iconVariant }}
              </v-icon>
            </template>
            <span>Citlivost na velikost písmen při vyhledávaní</span>
          </v-tooltip>
        </v-text-field>
      </v-row>
      <v-row cols="12" class="mt-6">
        <v-btn
          v-for="author in selectedObject.authors" :key='author.id'
          color="grey lighten-3"
          small
          elevation="0"
          rounded
          class="ma-1"
        >
          {{ author.name }}
          <v-icon right @click="removeAuthor(author)">
            mdi-close
          </v-icon>
        </v-btn>
        <v-btn
          v-for="theme in selectedObject.themes" :key='theme.id'
          color="grey lighten-3"
          small
          elevation="0"
          rounded
          class="ma-1"
        >
          {{ theme.name }}
          <v-icon right  @click="removeTheme(theme)">
            mdi-close
          </v-icon>
        </v-btn>
        <v-btn
          v-if="selectedObject.dateFrom != ''"
          color="grey lighten-3"
          small
          elevation="0"
          rounded
          class="ma-1"
        >
          Od {{ selectedObject.dateFrom }}
          <v-icon right @click="selectedObject.dateFrom=''" >
            mdi-close
          </v-icon>
        </v-btn>
        <v-btn
          v-if="selectedObject.dateTo != ''"
          color="grey lighten-3"
          small
          elevation="0"
          rounded
          class="ma-1"
        >
          Do {{ selectedObject.dateTo }}
          <v-icon right @click="selectedObject.dateTo=''" >
            mdi-close
          </v-icon>
        </v-btn>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      selectedObject: {
        authors: [],
        themes: [],
        query: '',
        exactSearch: '',
        dateFrom: '',
        dateTo: '',
        sortDirection: 'createdAt:desc'
      },
      iconVariant: 'mdi-format-letter-case'
    };
  },
  watch: {
    // when selected is changed, reload the search query
    '$store.state.resources.selected'() {
      this.selectedObject = this.selected;
    },
    selectedObject: {
      handler(val) {
        // this will update selected objec in VUEX which will
        // trigger resources update
        this.storeSelected(val);
      },
      deep: true
    }
  },
  computed: {
    ...mapState({
      selected: (state) => state.resources.selected
    })
  },
  methods: {
    ...mapActions('resources', {
      storeSelected: 'storeSelected'
    }),
    removeAuthor(item) {
      const index = this.selectedObject.authors.findIndex(
          (author) => author.name == item.name
      );
      if (index >= 0) this.selectedObject.authors.splice(index, 1);
    },
    removeTheme(item) {
      const index = this.selectedObject.themes.findIndex(
          (theme) => theme.name == item.name
      );
      if (index >= 0) this.selectedObject.themes.splice(index, 1);
    }
  },
  created() {
    // load selected properties from permanet storage
    // it was stored there by other VUE components like Detail page
    this.selectedObject = this.selected;
  }
};
</script>

<style>
</style>
