export const LOGIN_REQUEST = 'loginRequest';
export const LOGIN_SUCCESS = 'loginSuccess';
export const LOGIN_FAILURE = 'loginFailure';
export const LOGOUT = 'logOut';
export const REGISTER_REQUEST = 'registerRequest';
export const REGISTER_SUCCESS = 'registerSuccess';
export const REGISTER_FAILURE = 'registerFailure';
export const SUCCESS = 'success';
export const ERROR = 'error';
export const CLEAR = 'clear';
export const SET_LOADING = 'setLoading';
export const SET_SELECTED = 'setSelected';
export const SET_IDS = 'setIDs';
export const SET_USER = 'setUser';
export const STORE_SHARING_COUNTS = 'storeSharingCounts';

