<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small
        elevation="0" color="amber lighten-2 rounded-lg"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-share</v-icon>Sdílet
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-h5">
        Sdílení zdroje ...
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        Zadejte email osoby, které bude zdroj nasdílen (výchozí doba sdílení je <b>1&nbsp;měsíc</b>).
        Kliknutím na tlačítko <b>'Sdílet'</b> bude na vložený email odeslána zpráva obsahující odkaz
        pro přístup ke zdroji.
      </v-card-text>
      <v-card-text class="mt-n2">
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                label="Email"
                required color="amber"
                v-model="email"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-n2">
            <v-col cols="6">
              <label>Nastavit platnost sdílení na:</label>
            </v-col>
            <v-col cols="6" class="mt-n5">
              <v-combobox
                v-model="select"
                :items="items" color="amber"
                item-color="amber"
              >
              </v-combobox>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions >
        <v-spacer></v-spacer>
        <v-btn
          class="mx-2 mt-n10 mb-2"
          color="amber"
          elevation="0"
          @click="share()"
          :disabled="!isValidEmail"
        >
          Sdílet
        </v-btn>
      </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>

import { sharingService } from '../services/sharings.service';
import { mapState, mapActions } from 'vuex';

export default {
  props: ['resid'],
  data() {
    return {
      dialog: false,
      select: ['1 měsíc'],
      items: [
        '1 měsíc',
        '3 měsíce',
        '6 mesíců'],
      email: ''
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user
    }),
    isValidEmail() {
      const isValid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email);
      return isValid;
    }
  },
  methods: {
    ...mapActions('alert', {
      successAction: 'successAction',
      errorAction: 'errorAction'
    }),
    share() {
      const numOfMonths = parseInt(this.select[0].charAt(0));
      console.log('NUMBER OF MOUNTHS: ', numOfMonths);
      const now = new Date();
      const validTo = new Date();
      validTo.setMonth(now.getMonth()+numOfMonths);
      sharingService.share(this.user.id, validTo, this.email, this.resid)
          .then(() => {
            this.successAction('Zdroj byl úspěšně nasdílen.');
          })
          .catch((response) => {
            this.errorAction('Při sdílení zdroje došlo k chybě: ', response);
          });
      this.dialog = false;
    }
  }
};
</script>
