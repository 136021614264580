/**
 * Function for parsing the currently logged in user from client local storage
 * in his browser.
 * @return {object} Either object with current logged in user or empty object.
 */
export function getCurrentUser() {
  let user = {};
  try {
    user = JSON.parse(localStorage.getItem('user'));
  } catch (error) {
    console.log('getCurrentUser() parsing failed: ', error);
  }
  return user;
}
