<template>
  <div>
    <navigation-menu></navigation-menu>
    <v-container class="px-1 py-0" fluid>
      <h1 class="text-h6 ma-sm-3 text-sm-h4 d-flex justify-center">Profil uživatele</h1>
      <v-divider class="amber lighten-4 mx-auto"></v-divider>
      <p class="text--disabled mt-6 text-body-2">
          Změna uživatelského jména, hesla a&nbsp;dalších uživatelských nastavení. V&nbsp;případe potíži s&nbsp;portálem nebo jakýchkoli otázek napište
          <span><a href="mailto:admin@in-life.org" class="text--secondary">správci portálu</a></span>.
      </p>
      <v-col>
        <v-row>
          <v-col cols="3" md="2" class="text--secondary font-weight-light">
            Jméno:
          </v-col>
          <v-col class="text--primary">
            {{ user.name }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" md="2" class="text--secondary font-weight-light">
            Login:
          </v-col>
          <v-col class="text--primary">
            {{ user.username }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" md="2" class="text--secondary font-weight-light">
            Email:
          </v-col>
          <v-col class="text--primary">
            {{ user.email }}
          </v-col>
        </v-row>
        <v-row class="mt-8">
          <change-password-form class="mt-2"></change-password-form>
        </v-row>
      </v-col>
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import NavigationMenu from './NavigationMenu.vue';
import ChangePasswordForm from './ChangePasswordForm.vue';

export default {
  components: { NavigationMenu, ChangePasswordForm },
  data() {
    return {
      favourites: []
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user
    })
  },
  methods: {
    showResource(resID) {
      this.$router.push('/resource/'+resID);
    }
  }
};
</script>

<style>
</style>
