<template>
  <div>
    <v-btn v-if="!drawer" icon tile @click="drawer = true"  color="amber"
      style="position: absolute; right: 10px; top: 7px; z-index: 5;" title="Kontextová nabídka ...">
      <!-- < this will make this button on the top of app panel .. hack? :) > -->
      <v-icon>mdi-chevron-double-left</v-icon>
    </v-btn>
    <v-navigation-drawer
      app
      right
      clipped
      v-model="drawer"
      mobile-breakpoint="1000"
      color="grey lighten-3"
    >
      <v-list dense>
        <v-subheader class="ma-2">
          <span class="text-button text--secondary">Možnosti hledání</span>
        </v-subheader>
        <v-list-item class="my-2">
          <v-list-item-content>
            <v-autocomplete
                  v-model="selectedObject.authors"
                  :items="authors"
                  small-chips dense
                  hide-details="auto"
                  color="amber"
                  label="Vyber autora"
                  item-text="name"
                  item-value="name"
                  return-object
                  hide-selected
                  multiple
                >
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="removeAuthor(data.item)"
                    >
                      <v-avatar left color="amber lighten-4" size="32">
                        <v-icon v-if="data.item.pic == 'none'">mdi-account</v-icon>
                        <v-img v-else :src="data.item.pic"></v-img>
                      </v-avatar>
                      {{ data.item.name.length > 15 ? data.item.name.substr(0,15) : data.item.name }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <template v-if="(typeof data.item) !== 'object'">
                      <v-list-item-content
                        v-text="data.item"
                      ></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-avatar color="amber lighten-4" size="32">
                        <v-icon v-if="data.item.pic == 'none'">mdi-account</v-icon>
                        <v-img v-else :src="data.item.pic"></v-img>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.name"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="my-2">
          <v-list-item-content>
            <v-autocomplete
                  v-model="selectedObject.themes"
                  :items="themes"
                  small-chips dense
                  hide-details="auto"
                  color="amber"
                  label="Vyber téma"
                  item-text="name"
                  item-value="name"
                  return-object
                  hide-selected
                  multiple
                >
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="removeTheme(data.item)"
                    >
                    {{ data.item.name.length > 15 ? data.item.name.substr(0,15) : data.item.name }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <template v-if="(typeof data.item) !== 'object'">
                      <v-list-item-content
                        v-text="data.item"
                      ></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.name"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="mt-6">
          <v-list-item-content>
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              left
              min-width="auto"
              v-model="menu1"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="selectedObject.dateFrom"
                  label="Datum od"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  color="amber"
                  class="mt-n3 mb-n6 text-body-2"
                  clearable
                  @click:clear="selectedObject.dateFrom = ''"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="selectedObject.dateFrom"
                no-title
                scrollable
                locale="cs"
                color="amber"
                @input="menu1 = false"
                :first-day-of-week="1"
              >
              </v-date-picker>
            </v-menu>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              left
              min-width="auto"
              v-model="menu2"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="selectedObject.dateTo"
                  label="Datum do"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  color="amber"
                  class="mt-n1 mb-n5"
                  clearable
                  @click:clear="selectedObject.dateTo = ''"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="selectedObject.dateTo"
                no-title
                scrollable
                locale="cs"
                color="amber"
                @input="menu2 = false"
                :first-day-of-week="1"
              >
              </v-date-picker>
            </v-menu>
          </v-list-item-content>
        </v-list-item>
        <v-subheader class="mt-8 mx-2">
          <span class="text-button text--secondary text-caption ">Řazení zdrojů:</span>
        </v-subheader>
        <v-list-item  v-for="item in sortItems"  :key="item.title">
          <v-list-item-content>
            <v-btn
              :color="item.title == sortSelected ? 'amber lighten-2' : 'amber lighten-4'"
              class="rounded-lg"
              @click="setDirection(item.title)" small elevation="0">
              {{ item.title }}
            </v-btn>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="!isSearchEmpty">
          <v-list-item-content>
              <v-btn color="red lighten-2" @click="clearSearch()" small elevation="0" class="rounded-lg">
                <v-icon dense>mdi-close</v-icon>
                Vyčistit vyhledávaní
              </v-btn>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { resourcesService } from '../services/resources.service';

export default {
  data() {
    return {
      drawer: 'null',
      mini: false,
      authors: [],
      themes: [],
      selectedObject: {
        authors: [],
        themes: [],
        query: '',
        exactSearch: '',
        dateFrom: '',
        dateTo: '',
        sortDirection: 'createdAt:desc'
      },
      sortSelected: 'Dle data přidání',
      menu1: false,
      menu2: false,
      sortItems: [
        { title: 'Dle data přidání', icon: 'mdi-sort-clock-descending-outline' },
        { title: 'Od nejstaršího', icon: 'mdi-sort-calendar-ascending' },
        { title: 'Od nejnovějšího', icon: 'mdi-sort-calendar-descending' }
      ]
    };
  },
  watch: {
    // when selected is changed, reload the search query
    '$store.state.resources.selected'() {
      this.selectedObject = this.selected;
    },
    selectedObject: {
      handler(val) {
        // this will update selected objec in VUEX which will
        // trigger resources update
        this.storeSelected(val);
      },
      deep: true
    }
  },
  created() {
    window.innerWidth > 1000 ? this.drawer = true : this.drawer = false;

    // tu si zistime pocet stranok nutnych si nacitat
    resourcesService.getAuthors(['fullName'], ['photo']).then((res) => {
      this.authors = res.map((element) => {
        return {
          name: element.attributes.fullName,
          pic: (element.attributes.photo.data != null &&
              element.attributes.photo.data.attributes.formats != null ) ?
            element.attributes.photo.data.attributes.formats.thumbnail.url :
            'none' // : './person_img.png'
        };
      });
    });

    resourcesService.getThemes(['name']).then((res) => {
      this.themes = res.map((element) => {
        return { name: element.attributes.name };
      });
    });
  },
  computed: {
    ...mapState({
      userName: (state) => state.account.user.username,
      selected: (state) => state.resources.selected
    }),
    isSearchEmpty() {
      if ( this.selectedObject.authors.length > 0 ||
          this.selectedObject.themes.length > 0 ||
          this.selectedObject.query ||
          this.selectedObject.dateFrom ||
          this.selectedObject.dateTo ||
          this.selectedObject.sortDirection != 'createdAt:desc' ) {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    ...mapActions('resources', {
      storeSelected: 'storeSelected'
    }),
    ...mapActions('alert', {
      successAction: 'successAction',
      errorAction: 'errorAction'
    }),
    removeAuthor(item) {
      const index = this.selectedObject.authors.findIndex(
          (author) => author.name == item.name
      );
      if (index >= 0) this.selectedObject.authors.splice(index, 1);
    },
    removeTheme(item) {
      const index = this.selectedObject.themes.findIndex(
          (theme) => theme.name == item.name
      );
      if (index >= 0) this.selectedObject.themes.splice(index, 1);
    },
    setDirection(type) {
      this.sortSelected = type;

      switch (type) {
        case 'Dle data přidání':
          this.selectedObject.sortDirection = 'createdAt:desc';
          break;
        case 'Od nejstaršího':
          this.selectedObject.sortDirection = 'date:asc';
          break;
        case 'Od nejnovějšího':
          this.selectedObject.sortDirection = 'date:desc';
          break;
        default:
          break;
      }
    },
    clearSearch() {
      this.selectedObject = {
        authors: [],
        themes: [],
        query: '',
        exactSearch: '',
        dateFrom: '',
        dateTo: '',
        sortDirection: 'createdAt:desc'
      };
      this.sortSelected = 'Dle data přidání';
    }
  }
};
</script>

<style scoped>
.bgAmber{
  background-color: #EEEEEE !important;
  color : #ffe082 !important;
}
.v-navigation-drawer >>> .v-navigation-drawer__border {
  display: none
}
</style>
