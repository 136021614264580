<template>
  <div class="youtube">
    <div id="player"></div>
    <!-- <v-btn id="mute">Mute</v-btn> -->
  </div>
</template>

<script>
export default {
  props: {
    id: Number,
    video: String
  },
  data() {
    return {
      videoTitle: '',
      duration: '',
      player: null
    };
  },
  mounted() {
    console.info('YouTube load with id:', this.video);
    window.YT = null;

    const videoId = this.video;
    const tag = document.createElement('script');

    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    /* eslint-disable no-unused-vars */
    let player;
    window.onYouTubeIframeAPIReady = function() {
      player = new window.YT.Player('player', {
        playerVars: {
          color: 'white', // white version of the player
          rel: 0 // on the end of the video, only sources from the same cannel will show

        },
        height: '300',
        width: '100%',
        videoId: videoId, // I need ID here.. this.id won't work
        events: {
          'onReady': window.onPlayerReady,
          'onStateChange': window.onPlayerStateChange
        }
      });
    };

    window.onPlayerReady = function(event) {
      // if we want to autoplay, we can uncomment this
      // event.target.playVideo();
    };

    window.onPlayerStateChange = function(event) {
      // when video ends I need to emit notification to parent
    };

    // if we want to have our own controls of the video
    // document.getElementById('mute').addEventListener('click', function(event) {
    //  if(player.isMuted()){
    //    player.unMute()
    //  } else {
    //    player.mute()
    //  }
    // })
  }
};
</script>
