import Vue from 'vue';
import Router from 'vue-router';

import HomePage from '../components/HomePage';
import LoginPage from '../components/LoginPage';
import ResourcePage from '../components/ResourcePage';
import SpeakersPage from '../components/SpeakersPage';
import TopicsPage from '../components/TopicsPage';
import ProfilePage from '../components/ProfilePage';
import FoldersPage from '../components/FoldersPage';
import RegisterPage from '../components/RegisterPage';
import RegisteredPage from '../components/RegisteredPage';
import ToProcessPage from '../components/ToProcessPage';
import FavouritesPage from '../components/FavouritesPage';
import UsersPage from '../components/UsersPage';
import ForgotPasswordPage from '../components/ForgotPasswordPage';
import ResetPasswordPage from '../components/ResetPasswordPage';
import SharingsPage from '../components/SharingsPage';
import SharedResourcePage from '../components/SharedResourcePage';
import MaterialsPage from '../components/MaterialsPage';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    { path: '/', component: HomePage },
    { path: '/login', component: LoginPage },
    { path: '/resource/:id', component: ResourcePage },
    { path: '/speakers', component: SpeakersPage },
    { path: '/topics', component: TopicsPage },
    { path: '/folders', component: FoldersPage },
    { path: '/profile', component: ProfilePage },
    { path: '/register', component: RegisterPage },
    { path: '/registered', component: RegisteredPage },
    { path: '/process', component: ToProcessPage },
    { path: '/favourites', component: FavouritesPage },
    { path: '/users', component: UsersPage },
    { path: '/forgot-password', component: ForgotPasswordPage },
    { path: '/reset-password', component: ResetPasswordPage },
    { path: '/sharings', component: SharingsPage },
    { path: '/sharing', component: SharedResourcePage },
    { path: '/materials', component: MaterialsPage }

    // otherwise redirect to home
    // {path: '*', redirect: '/'},
  ]
});

router.beforeEach((to, from, next) => {
  /*
    Redirect to login page if not logged in and trying to access
    a restricted page
  */
  // const publicPages = ['/login'];
  const publicPages = ['/login', '/register', '/registered', '/forgot-password', '/reset-password', '/sharing'];
  const privilegedPages = ['/users', '/process', '/materials'];
  const authRequired = !publicPages.includes(to.path);
  const privilegeRequired = privilegedPages.includes(to.path);
  const userJSON = localStorage.getItem('user');
  const user = JSON.parse(userJSON);

  if (authRequired && user == null) {
    return next('/login');
  }

  if (privilegeRequired && !(user.isAdmin || user.isEditor || user.isStaff)) {
    return next('/');
  }

  next();
});

export default router;
