<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-if="!btnText" text elevation="0"
          @click="loadForm()"
          v-bind="attrs"
          v-on="on"
            >
          <v-icon>mdi-wrench</v-icon>
      </v-btn>
      <v-btn v-if="btnText"
        @click="loadForm()"
        small
        elevation="0" color="amber lighten-2 rounded-lg"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-wrench</v-icon>
        <span class="ms-1">{{ btnText ? btnText : '' }}</span>
      </v-btn>
    </template>
    <v-card v-if="resLoaded">
      <v-card-title>
        <span class="text-h5">Návrh na úpravu zdroje</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <p class="text--secondary mt-4 mb-0 text-body-2">
          Jestli si našel/našla chybu v názvu, popisku nebo v jiné části zdroje, zde můžeš zadat navrhovanou úpravu.
          Připadně můžeš nechat libovolný další komentář.
        </p>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="report.title" color="amber"
                hint="Nadpis" persistent-hint prepend-icon="mdi-format-title"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" class="mt-n6">
              <v-textarea
                :value="report.description" v-model="report.description" color="amber"
                class="text--secondary font-weight-light" prepend-icon="mdi-text-long"
                hint="Popisek" persistent-hint rows="3"
              >
              </v-textarea>
            </v-col>
            <v-col cols="12">
              <v-autocomplete v-if="authorsLoaded"
                  v-model="report.authors"
                  :items="allAuthors"
                  solo hide-details
                  color="amber"
                  label="Autoři"
                  item-text="name"
                  item-value="name"
                  return-object
                  hide-selected
                  multiple dense
                  prepend-icon="mdi-account"
                  no-data-text="Autor nenalezen! Vyberte ze seznamu."
                  >
                  <template v-slot:selection="data">
                      <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close label
                      @click="data.select"
                      @click:close="removeAuthor(data.item)"
                      color="grey lighten-4"
                      >
                      {{ data.item.name }}
                      </v-chip>
                  </template>
                  <template v-slot:item="data">
                      <template v-if="(typeof data.item) !== 'object'" >
                      <v-list-item-content v-text="data.item"></v-list-item-content>
                      </template>
                      <template v-else>
                      <v-list-item-content>
                          <v-list-item-title v-html="data.item.name"></v-list-item-title>
                      </v-list-item-content>
                      </template>
                  </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" >
              <v-autocomplete v-if="themesLoaded"
                  v-model="report.themes"
                  :items="allThemes"
                  solo hide-details
                  color="amber"
                  label="Témata"
                  item-text="name"
                  item-value="name"
                  return-object
                  hide-selected
                  multiple dense
                  prepend-icon="mdi-format-list-bulleted"
                  no-data-text="Téma nenalezeno! Vyberte ze seznamu."
                  >
                  <template v-slot:selection="data">
                      <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close label
                      @click="data.select"
                      @click:close="removeTheme(data.item)"
                      color="grey lighten-4"
                      >
                      {{ data.item.name }}
                      </v-chip>
                  </template>
                  <template v-slot:item="data">
                      <template v-if="(typeof data.item) !== 'object'" >
                      <v-list-item-content v-text="data.item"></v-list-item-content>
                      </template>
                      <template v-else>
                      <v-list-item-content>
                          <v-list-item-title v-html="data.item.name"></v-list-item-title>
                      </v-list-item-content>
                      </template>
                  </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" >
              <v-autocomplete v-if="tagsLoaded"
                  v-model="report.tags"
                  :items="allTags"
                  ref="tagautocomplete"
                  solo chips
                  hide-details
                  color="amber"
                  label="Štítky"
                  item-text="name"
                  item-value="name"
                  return-object
                  hide-selected
                  multiple dense
                  prepend-icon="mdi-tag"
                  deletable-chips
                  >
                  <template v-slot:selection="data">
                      <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close label
                      @click="data.select"
                      @click:close="removeTag(data.item)"
                      color="grey lighten-4"
                      >
                      {{ data.item.name }}
                      </v-chip>
                  </template>
                  <template v-slot:item="data">
                      <template v-if="(typeof data.item) !== 'object'" >
                      <v-list-item-content v-text="data.item"></v-list-item-content>
                      </template>
                      <template v-else>
                      <v-list-item-content>
                          <v-list-item-title v-html="data.item.name"></v-list-item-title>
                      </v-list-item-content>
                      </template>
                  </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" class="mt-n4">
              <v-textarea v-model="report.comment" color="amber"
                          class="text--secondary font-weight-light" auto-grow
                          prepend-icon="mdi-comment"
                          hint="Volný komentář" persistent-hint
                          rows="2"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="pb-4 mt-n8">
        <v-spacer></v-spacer>
        <v-btn
          color="amber" elevation="0"
          @click="processSubmit()"
        >
          Uložit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { resourcesService } from '../services/resources.service';
import { mapActions } from 'vuex';

export default {

  props: ['resid', 'btnText'],
  data() {
    return {
      dialog: false,
      newTagName: '',
      selectedTagIndex: -1,
      allAuthors: [],
      report: { comment: '' },
      resLoaded: false,
      authorsLoaded: false,
      themesLoaded: false,
      tagsLoaded: false
    };
  },
  methods: {
    ...mapActions('alert', {
      successAction: 'successAction',
      errorAction: 'errorAction'
    }),
    removeAuthor(author) {
      const idx = this.report.authors.findIndex((item) => {
        return item.id === author.id;
      });
      this.report.authors.splice(idx, 1);
    },
    removeTheme(theme) {
      const idx = this.report.themes.findIndex((item) => {
        return item.id === theme.id;
      });
      this.report.themes.splice(idx, 1);
    },
    removeTag(tag) {
      const idx = this.report.tags.findIndex((item) => {
        return item.id === tag.id;
      });
      this.report.tags.splice(idx, 1);
    },
    loadForm() {
      this.resLoaded = false;
      this.authorsLoaded = false;
      this.themesLoaded = false;
      this.tagsLoaded = false;

      // here we load resorce for the form
      resourcesService.getResource(this.resid).then((resource) => {
        if (resource && resource.data.attributes) {
          this.report.title = resource.data.attributes.title;
          this.report.description = resource.data.attributes.description;
          this.report.themes = resource.data.attributes.themes.data.map((theme) => {
            return {
              id: theme.id,
              name: theme.attributes.name
            };
          });
          this.report.tags = resource.data.attributes.tags.data.map((tag) => {
            return {
              id: tag.id,
              name: tag.attributes.name
            };
          });
          this.report.tags = resource.data.attributes.tags.data.map((tag) => {
            return {
              id: tag.id,
              name: tag.attributes.name
            };
          });
          this.report.authors = resource.data.attributes.authors.data.map((author) => {
            return {
              id: author.id,
              name: author.attributes.fullName
            };
          });
        }

        this.resLoaded = true;
      });

      // here we load data to full autocomplete forms
      resourcesService.getAuthors(['fullName']).then((report) => {
        this.allAuthors = report.map((element) => {
          return {
            id: element.id,
            name: element.attributes.fullName
          };
        });

        this.authorsLoaded = true;
      });

      resourcesService.getTags(['name']).then((report) => {
        this.allTags = report.map((element) => {
          return {
            id: element.id,
            name: element.attributes.name
          };
        });

        this.tagsLoaded = true;
      });

      resourcesService.getThemes(['name']).then((report) => {
        this.allThemes = report.map((element) => {
          return {
            id: element.id,
            name: element.attributes.name
          };
        });

        this.themesLoaded = true;
      });
    },
    processSubmit() {
      const user = JSON.parse(localStorage.getItem('user'));

      this.report.user = user.username;
      this.report.date = new Date();
      this.report.state = 'new';
      this.report.resid = this.resid;

      resourcesService.storeReport(this.report)
          .then(() => {
            this.successAction('Návrh zmeny byl uložen.');
          })
          .catch((response) => {
            this.errorAction('Uložení návrhu změn se nezdařilo: '+response.status);
          });

      this.dialog = false;
    }

  }
};

</script>
