<template>
  <v-container class="my-16" style="max-width:1024px;">
    <v-row justify="center">
      <v-card  class="pa-10" outlined flat rounded color="amber lighten-4" max-width="380">
        <v-card-title>
          <v-row justify="center">
            <v-img max-height="32" max-width="32" src="@/assets/logo-in-life.png" alt="In-Life logo"></v-img>
            <span class="mx-1 text--primary text-button">Zdroje</span>
          </v-row>
        </v-card-title>
        <v-form  @submit.prevent="handleSubmit" class="mt-6">
          <h1 class="text-h6">Obnovení hesla</h1>
          <p class="text--secondary text-body-2 mt-2">
            Pro obnovu zapomenutého hesla zadejte Váš e-mail, kterým jste registrováni na portálu <b>Zdroje <span style="white-space: nowrap">In-Life</span></b>.
          </p>
          <p class="text--secondary text-body-2 mt-2">
            Na zadaný e-mail, Vám bude zaslán odkaz pro nové vytvoření hesla.
          </p>
          <v-text-field
            v-model="email"
            label="Email"
            :rules="emailRules"
            required color="amber"
            class="mt-6 mb-n2"
          />
          <v-row>
            <v-btn
              class="mt-10 mx-auto ml-2"
              href="/login"
              elevation="0" x-small text plain
            >
              Jít na přihlášení
            </v-btn>
            <v-btn
              class="mt-8 float-right"
              elevation="0"
              :disabled="emailIsValid ? true : false"
              @click="handleSubmit()"
              color="amber lighten-2"
            >
              Obnovit
            </v-btn>
          </v-row>
        </v-form>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import { authCustomService } from '../services/auth-custom.service';

export default {
  data() {
    return {
      email: '',
      show: false,
      emailRules: [
        (v) => !!v || 'Pole je povinné',
        (v) => /.+@.+\..+/.test(v) || 'E-mail musí být platný'
      ]
    };
  },
  computed: {
    emailIsValid() {
      return !/.+@.+\..+/.test(this.email);
    }
  },
  methods: {
    ...mapActions('alert', {
      successAction: 'successAction',
      errorAction: 'errorAction'
    }),

    handleSubmit() {
      authCustomService.forgotPassword(this.email)
          .then((res) => {
            this.successAction('Na zadaný email, byl odeslán odkaz pro vytvoření hesla.');
            this.$router.push('/login');
          })
          .catch((res) => {
            this.errorAction('Obnovení hesla selhalo: '+res.message+' ['+res.status+']');
          });
    }
  }
};
</script>
