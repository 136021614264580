import { getCurrentUser } from './local-storage-getter';

/**
 * Function for checking authorization token.
 * @return {object} Either object with token or empty object.
 */
export function authHeader() {
  const user = getCurrentUser();

  if (user && user.token) {
    return { Authorization: 'Bearer ' + user.token };
  } else {
    return {};
  }
}
