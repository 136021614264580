import { CLEAR_ACTION, ERROR_ACTION, SUCCESS_ACTION } from '../actions';
import { CLEAR, ERROR, SUCCESS } from '../mutations';

const state = {
  type: null,
  message: null,
  fire: null
};

const actions = {
  [SUCCESS_ACTION]({ commit }, message) {
    commit(SUCCESS, message);
  },
  [ERROR_ACTION]({ commit }, message) {
    commit(ERROR, message);
  },
  [CLEAR_ACTION]({ commit }) {
    commit(CLEAR);
  }
};

const mutations = {
  [SUCCESS](state, message) {
    state.type = 'alert-success';
    state.message = message;
    state.fire = 'true';
  },
  [ERROR](state, message) {
    state.type = 'alert-danger';
    state.message = message;
    state.fire = 'true';
  },
  [CLEAR](state) {
    state.fire = null;
    state.type = null;
    state.message = null;
  }
};

export const alert = {
  namespaced: true,
  state,
  actions,
  mutations
};
