<template>
  <div>
      <navigation-menu></navigation-menu>
      <context-menu-resource
          :resourceID="$route.params.id"
          :favourite="res.isFavourite"
          @toggle-favourite="toggleFavourite()"
          @show-resource="(id) => showResource(id)"
        ></context-menu-resource>
      <v-container class="pa-0" fluid>
        <h1 class="text-sm-h3 text-h4 font-weight-light text-center mt-10">
          {{res.title}}
        </h1>
        <!-- Authors -->
        <div v-if="res.authors.length>=1">
          <div class="text-center mt-2">
            <span v-for="author in res.authors" :key="author.id">
              <v-btn href="#" class="my-1 mx-0 text-capitalize"
                  @click="clicked('author',author.name)" elevation="0" plain
                  color="grey darken-2" :title="'Zobrazit zdroje autora: '+author.name"
              >
                  {{author.name}}
              </v-btn>
            </span>
            <v-btn @click="toggleFavourite()" plain elevation="0" class="justify-end p-0"
              :title="(res.isFavourite)?'Odebrat z oblíbených':'Přidat k oblíbeným'">
              <v-icon v-if='res.isFavourite'>mdi-heart</v-icon>
              <v-icon v-if='!res.isFavourite'>mdi-cards-heart-outline</v-icon>
            </v-btn>
            <span v-if="res.date!=null && res.date.length" class="text--disabled text-caption ms-4">
              {{new Date(res.date).toLocaleDateString()}}
            </span>
          </div>
        </div>
        <!-- Themes and tags -->
        <div class="text-center">
          <span v-for="(theme,index) in res.themes" :key="theme.id + theme.name">
            <v-btn @click="clicked('theme',theme.name)" small elevation="0" plain color="grey-darken-2"
              class="font-weight-light" :title="'Zobrazit zdroje z témetem: '+theme.name">
              {{theme.name}}
            </v-btn>
            <span v-if="index<res.themes.length-1" class="text--disabled">|</span>
            <span v-if="index == res.themes.length-1 && res.tags.length > 0" class="text--disabled">|</span>
          </span>
          <span v-for="(tag,index) in res.tags" :key="tag.id + tag.name">
            <v-btn @click="clicked('tag',tag.name)" small elevation="0" plain color="grey-darken-2"
              class="text-none font-weight-light" :title="'Zobrazit zdroje se štítkem: '+tag.name">
              {{tag.name}}
            </v-btn>
            <span v-if="index<res.tags.length-1" class="text--disabled">|</span>
          </span>
        </div>
        <!-- Audios -->
        <div v-if="res.audios.length>0" class="pt-16 pb-10">
            <div v-for="audio in res.audios" :key="audio.id">
              <AudioPlayer :sources="[ audio.url ]"/>
              <!-- <div>old version of audio</div> -->
              <!-- <audio controls :src="audio.url" style="width:100%" :title="audio.name"></audio><br> -->
            </div>
        </div>
        <!-- Videos -->
        <div v-if="res.videos.length>0" class="my-8">
            <div v-for="video in res.videos" :key="video.id">
                <!-- old version of youtube video -->
                <!--iframe width="100%" height="300vh" :src="'https://www.youtube.com/embed/'+video.name+'?rel=0'" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe-->
                <Youtube :video="video.url"/>
            </div>
        </div>
        <!-- Images -->
        <div v-if="res.images.length>0" class="my-8">
          <v-row no-gutters class="mt-2">
            <v-card v-for="image in res.images" :key="image.id" class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12"
              elevation="0">
              <v-img :src="image.url"></v-img>
            </v-card>
          </v-row>
        </div>
        <!-- Documents (pdf, docx, pptx, ...) -->
        <div v-if="res.docs.length>0" class="my-6">
          Ke stažení:<br>
          <span v-for="doc in res.docs" :key="doc.id">
            <a :href="doc.url" target="_blank" class="text--secondary btn pa-2 rounded text-decoration-none"
              :title="'Stáhnout soubor: '+doc.name">
              {{doc.name}} <v-icon>mdi-download</v-icon></a>
          </span>
        </div>
          <!-- Description -->
          <p class="mt-4 text--secondary">
            <i v-if="(res.description != null && res.description.length>0)" class="font-weight-light">
              {{ res.description }}
            </i>
          </p>
          <!-- Folders -->
          <v-expansion-panels v-if="res.folders.length > 0 && res.folderResources.length > 0" flat accordion class="pa-0">
            <v-expansion-panel>
              <v-expansion-panel-header class="text-overline text--secondary pa-0">
                Související zdroje
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pa-0">
                <div v-for="folder in res.folders" :key="folder.id" class="text--secondary text-subtitle-2">
                  <p class="mt-3 pa-0">{{ folder }}</p>
                  <v-slide-group multiple show-arrows center-active>
                    <v-slide-item v-for="res in res.folderResources[res.folders.indexOf(folder)]" :key="res.id" class="w-100">
                      <v-card width="200" elevation="0" color="amber lighten-4" outlined class="mx-1 rounded-lg" @click="showResource(res.id)">
                        <v-card-text class="text-h6 text-center">
                          {{ res.attributes.title }}<br>
                          <div class="text-center text--disabled mt-2">
                            <span v-for="author in res.attributes.authors.data" :key="author.id" class="text-subtitle-2">
                              {{ author.attributes.fullName }}
                            </span>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-slide-item>
                  </v-slide-group>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!-- Accesses -->
          <div v-if="canManageAccess || isStaff">
            <div class="mt-4">
              <v-card flat color="grey lighten-4" class="pa-2">
                <div class="w-100">
                  <span class="px-2 text--secondary text-caption">Zdroj je dostupný pro:</span>
                  <span
                    v-for="(access,index) in res.accesses" :key="access.id"
                    plain small
                    class="text-caption text-none font-weight-bold text--secondary"
                  >
                    {{access.name}}
                    <span v-if="index<res.accesses.length-1" class="text--disabled">|</span>
                  </span>
                </div>
                <div v-if="canManageAccess" class="w-100 mt-2">
                  <v-btn v-if="!res.processed"
                        color="red" small elevation="0"
                        class="mx-1 text-none font-weight-normal"
                        @click="updateResource('processed')"
                          >
                        Nezpracovaný
                    </v-btn>
                    <v-btn v-if="res.processed"
                        color="green" small elevation="0"
                        class="mx-1 text-none font-weight-normal"
                        @click="updateResource('processed')"
                          >
                        Zpracovaný
                    </v-btn>
                    <v-btn
                        color="secondary"
                        @click="showResource(nextResourceID)"
                        :disabled="(nextResourceID===null)"
                        class="mx-1 float-end text--white"
                        title="Následující zdroj ..." small
                    >
                        <span class="mx-1 d-none d-sm-inline">Další</span><v-icon>mdi-arrow-right</v-icon>
                    </v-btn>
                    <v-btn
                        color="secondary"
                        @click="showResource(prevResourceID)"
                        :disabled="(prevResourceID===null)"
                        class="mx-1 float-end"
                        title="Předchozí zdroj..." small
                    >
                    <v-icon>mdi-arrow-left</v-icon><span class="mx-1 d-none d-sm-inline">Předchozí</span>
                    </v-btn>
                  </div>
                </v-card>
              </div>
          </div>
          <div>
            <v-switch v-if="isEditor" :label="editMode ? 'Vypnout editaci' : 'Zapnout editaci'" v-model="editMode" inset color="amber">
            </v-switch>
            <div v-if="!editingTitle && !editingTheme && !editingTag && !editingDescr && !editingAccess">
              <v-btn v-if="editMode" color="amber lighten-2" small elevation="0" @click="edit.title = res.title; editingTitle = true" class="mr-2">
                Nadpis
              </v-btn>
              <v-btn v-if="editMode" color="amber lighten-2" small elevation="0" @click="edit.themes = [...res.themes]; editingTheme = true" class="mr-2">
                Téma
              </v-btn>
              <v-btn v-if="editMode" color="amber lighten-2" small elevation="0" @click="edit.tags =  [...res.tags]; editingTag = true" class="mr-2">
                Tagy/Štítky
              </v-btn>
              <v-btn v-if="editMode" color="amber lighten-2" small elevation="0" @click="edit.description = res.description; editingDescr = true" class="mr-2">
                Popis
              </v-btn>
              <v-btn v-if="canManageAccess && editMode"  color="amber lighten-2" small elevation="0" @click="edit.accesses = [...res.accesses]; editingAccess = true" class="mr-2">
                Prístupy
              </v-btn>
            </div>
            <div v-if="editMode">
              <v-row v-if="editingTitle" align="center" class="mb-sm-1">
                <v-col cols="10">
                    <v-text-field v-model="edit.title" color="secondary" solo
                      class="text--secondary font-weight-light" hide-details="true"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="2" class="mt-n4 mb-2 mt-sm-2">
                    <v-btn class="mx-1 float-right mb-n1" color="grey" icon @click="editingTitle = false" title="Zrušit změny v nadpisu zdroje.">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-btn class="mx-1 float-right mb-n1" color="amber" icon @click="updateResource('title')" title="Uložit změny v madpisu zdroje.">
                        <v-icon>mdi-check</v-icon>
                    </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="editingDescr">
                <v-col cols="10">
                  <v-textarea :value="edit.description" v-model="edit.description" color="secondary" solo
                      class="text--secondary font-weight-light ma-3" auto-grow
                  >
                  </v-textarea>
                </v-col>
                  <v-col cols="2">
                    <v-btn class="mx-1 float-right mb-n1" color="grey" icon @click="editingDescr=false" title="Zrušit změny v popisu zdroje.">
                          <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-btn class="mx-1 float-right mb-n1" color="amber" icon @click="updateResource('description')" title="Uložit změny v popisu zdroje.">
                        <v-icon>mdi-check</v-icon>
                    </v-btn>
                  </v-col>
              </v-row>
              <v-row v-if="editingTheme">
                <v-col cols="10">
                  <v-autocomplete v-if="allThemes" v-model="edit.themes" :items="allThemes" solo hide-details color="grey lighten-3"
                    label="Témata" item-text="name" item-value="name" return-object hide-selected multiple dense
                    no-data-text="Téma nenalezeno! Vyberte některé ze seznamu.">
                    <template v-slot:selection="data">
                      <v-chip v-bind="data.attrs" :input-value="data.selected" close label @click="data.select"
                        @click:close="removeFromList(edit.themes, data.item)" color="grey lighten-4">
                        {{ data.item.name }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <template v-if="(typeof data.item) !== 'object'">
                        <v-list-item-content>{{data.item}}</v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="2" class="mt-n1 mt-sm-1">
                  <v-btn class="mx-1 float-right" color="grey" icon light @click="editingTheme=false" title="Zrušit změny témat">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-btn class="mx-1 float-right" color="amber" icon light @click="updateResource('themes')"
                    title="Uložit změny témat">
                    <v-icon>mdi-check</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="editingTag">
                <v-col cols="10">
                  <v-autocomplete v-if="allTags" v-model="edit.tags" :items="allTags" ref="tagautocomplete" solo chips hide-details
                    color="secondary lighten-4" label="Štítky" item-text="name" item-value="name" return-object hide-selected multiple
                    dense deletable-chips @keydown="tagsInputKeyDown" :search-input.sync="edit.newTagName"
                    @update:list-index="tagSelected" @change="edit.newTagName = ''">
                    <template v-slot:no-data>
                      <span class="text--secondary px-2">
                        <small>Hledaný štítek neexistuje. Vytvoříte jej stisknutím <kbd>Enter</kbd>.</small>
                      </span>
                    </template>
                    <template v-slot:selection="data">
                      <v-chip v-bind="data.attrs" :input-value="data.selected" close label @click="data.select"
                        @click:close="removeFromList(edit.tags, data.item)" color="grey lighten-4">
                        {{ data.item.name }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <template v-if="(typeof data.item) !== 'object'">
                        <v-list-item-content>{{ data.item }}</v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="2" class="mt-n1 mt-sm-1">
                  <v-btn class="mx-1 float-right" color="grey" icon @click="editingTag=false" title="Zrušit změny štítků ...">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-btn class="mx-1 float-right" color="amber lighten-2" icon @click="updateResource('tags')"
                    title="Uložit změny štítků ...">
                    <v-icon>mdi-check</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="editingAccess">
                <v-col cols="10">
                  <v-autocomplete v-if="allAccesses"
                    v-model="edit.accesses"
                    :items="allAccesses"
                    solo chips
                    hide-details
                    color="darkgrey"
                    label="Vyberte roli ..."
                    item-text="name"
                    item-value="name"
                    return-object
                    hide-selected
                    multiple dense
                    prepend-icon="mdi-shield-account-variant"
                    prefix="Přístupné pro: "
                    deletable-chips
                    >
                    <template v-slot:no-data>
                        <span class="text--secondary px-2">
                            <small>Hledaná role neexistuje, vyberte z dostupných.</small>
                        </span>
                    </template>
                    <template v-slot:selection="data">
                        <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close label
                        @click="data.select"
                        @click:close="removeFromList(edit.accesses, data.item)"
                        color="amber lighten-2"
                        >
                        {{ data.item.name }}
                        </v-chip>
                    </template>
                    <template v-slot:item="data">
                        <template v-if="(typeof data.item) !== 'object'" >
                            <v-list-item-content>{{ data.item }}</v-list-item-content>
                        </template>
                        <template v-else>
                        <v-list-item-content>
                            <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                        </v-list-item-content>
                        </template>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="2">
                  <v-btn class="mx-1 mt-1 float-right" color="grey" icon @click="editingAccess=false" title="Zrušit změny štítků ...">
                      <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-btn class="mx-1 mt-1 float-right" color="amber lighten-2" icon @click="updateResource('accesses')" title="Uložit změny štítků ...">
                      <v-icon>mdi-check</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <div v-if="canManageAccess && editMode" slot="append" class="mt-3">
              <span v-for="(audio, index) in res.audios" :key="audio.id">
                URL {{ index+1 }}. zaznamu: {{ audio.url }}
              </span>
            </div>
          </div>
      </v-container>
  </div>
</template>


<script>
import { resourcesService } from '../services/resources.service';
import { mapActions, mapState } from 'vuex';
import { userService } from '../services/user.service';
import Youtube from '../components/YouTube.vue';
import AudioPlayer from './AudioPlayer.vue';
import NavigationMenu from './NavigationMenu.vue';
import ContextMenuResource from './ContextMenuResource.vue';

export default {
  components: { NavigationMenu, Youtube, AudioPlayer, ContextMenuResource },
  data() {
    return {

      // all data from resource
      res: {
        title: '',
        description: '',
        date: '',
        themes: [],
        tags: [],
        authors: [],
        videos: [],
        audios: [],
        docs: [],
        images: [],
        visitsCount: 0,
        processed: '',
        folders: [],
        folderResources: [],
        related: [],
        accesses: [],
        isFavourite: false
      },

      // temporary data for editing feature
      edit: {
        title: '',
        description: '',
        themes: [],
        tags: '',
        newTagName: '',
        selectedTagIndex: -1,
        accesses: ''
      },

      // flags for editing
      editingTheme: false,
      editingTag: false,
      editingDescr: false,
      editingTitle: false,
      editingAccess: false,

      // data to fill the autocomplete
      allThemes: [],
      allTags: [],
      allAccesses: [],

      // flag for user roles
      isEditor: false,
      canManageAccess: false,
      isStaff: false,

      prevResourceID: null,
      nextResourceID: null,
      pageSize: 25,
      editMode: false,

      // for audio player testing
      audioSources: [
        'https://in-life.org/app/A3.mp3'
      ]
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      resIDs: (state) => state.resources.resIDs
    })
  },
  methods: {
    ...mapActions('resources', {
      storeSelected: 'storeSelected'
    }),
    ...mapActions('alert', {
      successAction: 'successAction',
      errorAction: 'errorAction'
    }),

    /**
         * Metoda clicked() zpracuje kliknutí uživatele na kontkrétní téma.
         * Toto téma vloží do objektu selected a ten uloží, pro ostatní komponenty
         * pomocí storeSelected().
         */
    clicked(type, value) {
      const _selected = {
        authors: [],
        themes: [],
        query: '',
        dateFrom: '',
        dateTo: '',
        sortDirection: 'createdAt:desc'
      };
      switch (type) {
        case 'theme':
          _selected.themes.push({ name: value });
          break;
        case 'author':
          _selected.authors.push({ name: value });
          break;
        case 'tag':
        case 'folder':
          _selected.query = value;
          break;
        case 'date':
          _selected.dateFrom = value;
      }
      this.storeSelected(_selected);
      this.$router.push('/'); // přesměruje na home page
    },
    removeFromList(list, itemToRemove) {
      const idx = list.findIndex((item) => {
        return item.id === itemToRemove.id;
      });
      list.splice(idx, 1);
    },
    tagSelected(idx) {
      this.edit.selectedTagIndex = idx;
    },
    tagsInputKeyDown(e) {
      if (e.key=='Enter') {
        if (this.edit.selectedTagIndex === -1 && this.edit.newTagName != null && this.edit.newTagName.trim().length>0) {
          this.updateResource('tags');
        } else {
          this.edit.newTagName = '';
        }
      }
    },
    updateResource(type) {
      let data = null;
      const error = 'Uložení změn se nezdařilo:';
      let success = '';

      switch (type) {
        case 'title':
          data = { title: this.edit.title };
          success = 'Nadpis zdroje byl úspěšně uložen.';
          this.editingTitle = false;
          break;
        case 'description':
          data = { description: this.edit.description };
          success = 'Nadpis zdroje byl úspěšně uložen.';
          this.editingDescr = false;
          break;
        case 'themes':
          data = { themes: this.edit.themes.map((theme) => {
            return theme.id;
          }) };
          success = 'Změny v tématech zdroje byly úspěšně uloženy.';
          this.editingTheme = false;
          break;
        case 'accesses':
          data = { accesses: this.edit.accesses.map((access) => {
            return access.id;
          }) };
          success = 'Změny v nastavení přístupu ke zdroji byly úspěšně uloženy.';
          this.editingAccess = false;
          break;
        case 'tags':
          data = { tags: this.edit.tags.map((tag) => {
            return tag.id;
          }) };
          success = 'Změny v seznamu štítků byly úspěšně uloženy.';
          this.editingTag = false;
          break;
        case 'processed':
          data = { processed: !this.res.processed };
          success = 'Změna v zpracovaní byly úspěšně uloženy.';
          break;
        default:
          break;
      }

      resourcesService.updateResource(this.$route.params.id, data, this.edit.newTagName)
          .then((response) => {
            this.successAction(success);

            // if success update also res file with new data
            switch (type) {
              case 'title':
                this.res.title = this.edit.title;
                break;
              case 'description':
                this.res.description = this.edit.description;
                break;
              case 'themes':
                this.res.themes = [...this.edit.themes];
                break;
              case 'accesses':
                this.res.accesses = [...this.edit.accesses];
                break;
              case 'processed':
                this.res.processed = !this.res.processed;
                break;
              case 'tags':
                if (!this.edit.newTagName) {
                  this.res.tags = [...this.edit.tags];
                } else {
                  this.res.tags = response.data.attributes.tags.data.map((tag) => {
                    return {
                      id: tag.id,
                      name: tag.attributes.name
                    };
                  });
                  // try to find newest id. It will have the higest id number
                  let newTagID = 0;
                  let newTagName = '';
                  for (const tag of this.res.tags) {
                    if (tag.id > newTagID) {
                      newTagID = tag.id; newTagName = tag.name;
                    }
                  }
                  this.allTags.push({ id: newTagID, name: newTagName });
                  this.edit.newTagName = '';
                }
                break;
              default:
                break;
            }
          })
          .catch((response) => {
            this.errorAction(error+response.status);
          });
    },
    showResource(resID) {
      this.$router.push('/resource/'+resID);
      this.loadResource();
    },
    loadResource() {
      this.canManageAccess = this.user.isAdmin;
      this.isStaff = this.user.isStaff;

      resourcesService.getResource(this.$route.params.id, (this.canManageAccess || this.isStaff)).then((resource) => {
        if (resource && resource.data.attributes) {
          this.res.title = resource.data.attributes.title;
          this.res.description = resource.data.attributes.description;
          this.res.date = resource.data.attributes.date;
          this.res.processed = resource.data.attributes.processed;
          this.res.visitsCount = parseInt(resource.data.attributes.visitsCount)+1;
          this.res.themes = resource.data.attributes.themes.data.map((theme) => {
            return {
              id: theme.id,
              name: theme.attributes.name
            };
          });
          this.res.authors = resource.data.attributes.authors.data.map((author) => {
            return {
              id: author.id,
              name: author.attributes.fullName
            };
          });
          this.res.videos = [];
          this.res.audios = [];
          this.res.docs = [];
          this.res.images = [];
          if (resource.data.attributes.mediaZone.length > 0) {
            for (const mediaItem of resource.data.attributes.mediaZone) {
              if (mediaItem['__component'] == 'media-components.media-external') {
                // je-li komponenty typu 'media-external', pak její typ bude Video
                this.res.videos.push({
                  id: 'vid'+mediaItem.id,
                  name: mediaItem.name,
                  url: mediaItem.url
                });
              } else {
                // je-li komponenta typu 'media-file', pak její typ bude nastaven
                // podle vlastnosti MIME (Video, Audio, Document, Image)
                const mediaObject = {
                  id: mediaItem.id,
                  name: mediaItem.name,
                  url: mediaItem.file.data.attributes.url,
                  caption: mediaItem.file.data.attributes.caption
                };
                if (mediaItem.file.data.attributes.mime.includes('audio')) {
                  mediaObject.id = 'aud'+mediaObject.id;
                  this.res.audios.push(mediaObject);
                } else if (mediaItem.file.data.attributes.mime.includes('image')) {
                  mediaObject.id = 'img'+mediaObject.id;
                  this.res.images.push(mediaObject);
                } else if (mediaItem.file.data.attributes.mime.includes('video')) {
                  mediaObject.id = 'vid'+mediaObject.id;
                  this.res.videos.push(mediaObject);
                } else {
                  mediaObject.id = 'doc'+mediaObject.id;
                  this.res.docs.push(mediaObject);
                }
                if (mediaObject.name == null) {
                  mediaObject.name = mediaItem.file.data.attributes.name;
                }
              }
            }
          }
          this.res.folders=[];
          this.res.folderResources=[];
          const folders = resource.data.attributes.folders.data.map((_folder) => {
            return {
              id: _folder.id,
              name: _folder.attributes.name
            };
          });
          if (folders.length > 0) {
            for (const folder of folders) {
              this.res.folders.push(folder.name);
              this.res.folderResources.push([]);
              resourcesService.getRelatedResources(folder.id, this.$route.params.id).then((resFolder) => {
                resFolder.data.attributes.resources.data.forEach((resource) => {
                  if (resource.id != this.$route.params.id) {
                    this.res.folderResources[this.res.folders.indexOf(folder.name)].push(resource);
                  }
                });
              });
            }
          }
          this.res.tags = resource.data.attributes.tags.data.map((tag) => {
            return {
              id: tag.id,
              name: tag.attributes.name
            };
          });
          if (this.canManageAccess || this.isStaff) {
            this.res.accesses = resource.data.attributes.accesses.data.map((access) => {
              return {
                id: access.id,
                name: access.attributes.name
              };
            });
          }
        }
      }).catch((error) => {
        if (error.status == 404 || error.status == 403) {
          this.errorAction('Požadovaný zdroj není dostupný. Buď neexistuje, nebo nemáte přístup.');
          this.$router.push('/');
        }
      });

      this.isEditor = this.user.isEditor;

      if (this.isEditor) {
        resourcesService.getThemes(['name']).then((response) => {
          const themesNames =
                    response.map((element) => {
                      return { id: element.id, name: element.attributes.name };
                    });
          this.allThemes = themesNames;
        });

        resourcesService.getTags(['name']).then((response) => {
          const tagsNames =
                response.map((element) => {
                  return { id: element.id, name: element.attributes.name };
                });
          this.allTags = tagsNames;
        });
      }
      if (this.canManageAccess) {
        userService.getAccesses().then((response) => {
          const accesses = response.data.map((access) => {
            return {
              id: access.id,
              name: access.attributes.name
            };
          });
          this.allAccesses = accesses;
        });
      }
      const idx = this.resIDs.findIndex((val) => {
        return val == this.$route.params.id;
      });
      let hasPrev = false;
      let hasNext = false;
      if (this.resIDs.length>1) {
        if (idx === 0) {
          hasPrev = false;
          hasNext = true;
        } else if (idx === this.resIDs.length-1) {
          hasPrev = true;
          hasNext = false;
        } else {
          hasPrev = true;
          hasNext = true;
        }
      }
      this.prevResourceID = (hasPrev) ? this.resIDs[idx-1] : null;
      this.nextResourceID = (hasNext) ? this.resIDs[idx+1] : null;

      // trying to find if this resource is between favourites
      userService.getUserProfile(this.$route.params.id).then((profile) => {
        const test = profile.favourites.filter((fav) => fav.id == parseInt(this.$route.params.id));
        if (test.length > 0) {
          this.res.isFavourite = true;
        } else {
          this.res.isFavourite = false;
        }
      });
    },
    toggleFavourite() {
      userService.updateFavourite(parseInt(this.$route.params.id), !this.res.isFavourite)
          .then((succ) => {
            if (this.res.isFavourite) {
              this.successAction('Vymazáno z oblíbených');
            } else {
              this.successAction('Uloženo mezi oblíbené');
            }
            this.res.isFavourite = !this.res.isFavourite;
          })
          .catch((err) => this.errorAction('Uložení se nezdařilo: ', err.status, err.message));
    }
  },
  created() {
    this.loadResource();
  }
};
</script>

<style deep>
  .v-expansion-panel-content__wrap {padding: 0 4px 16px !important; flex-grow: 1;}
</style>
