<template>
  <v-card  class="d-flex flex-column flex-sm-row white ma-6" elevation="0">
    <v-row>
      <v-col cols="12" md="6" class="order-md-1">
        <v-row>
          <v-slider
            :value="progress * 100"
            track-color="grey  lighten-1"
            color="amber darken-2"
            @change="changeSeek"
            :disabled="audioLoading"
        >
            <template v-slot:prepend>
              <span class="pt-1 px-1 text--secondary">{{ getCurrentTime }}</span>
            </template>
            <template v-slot:append>
                <span class="pt-1 px-1 text--secondary"> {{ getDuration }}</span>
            </template>
        </v-slider>
        </v-row>
      </v-col>
      <v-col cols="5" md="2" class="order-md-0 ">
        <v-row class="justify-center justify-md-start">
          <v-btn icon @click="setSeek(seek - 30)" v-if="!audioLoading" title="Posunout o 30s zpět">
              <v-icon>mdi-rewind-30</v-icon>
          </v-btn>
          <v-progress-circular
            v-if="audioLoading"
            indeterminate
            color="amber darken-2"
          ></v-progress-circular>
          <v-btn icon @click="togglePlayback" v-if="!audioLoading">
              <v-icon large v-if="!playing">mdi-play</v-icon>
              <v-icon large v-if="playing">mdi-pause</v-icon>
          </v-btn>
          <v-btn icon @click="setSeek(seek + 30)" v-if="!audioLoading" title="Posunout o 30s dopředu">
              <v-icon>mdi-fast-forward-30</v-icon>
          </v-btn>
        </v-row>
      </v-col>
      <v-col cols="7" md="4" class='order-md-2 pt-xs-0'>
        <v-row class="justify-center justify-md-end" >
          <v-menu
              top
              :offset-y="true"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-badge
                  :content="Math.floor(volume *100)"
                  overlap
                  inline
                  color="amber darken-2"
                >
                  <v-btn icon
                    :disabled="audioLoading"
                    v-bind="attrs"
                    v-on="on"
                    color="darken-2"
                    rounded title="Hlasitost"
                  >
                    <v-icon v-if="volume > 0.7  && volume <= 1">mdi-volume-high</v-icon>
                    <v-icon v-if="volume > 0.4  && volume <= 0.7">mdi-volume-medium</v-icon>
                    <v-icon v-if="volume > 0    && volume <= 0.4">mdi-volume-low</v-icon>
                    <v-icon v-if="volume == 0">mdi-volume-off</v-icon>

                  </v-btn>
                </v-badge>
              </template>
                <v-slider
                  :value="volume * 100"
                  @input="changeVolume"
                  vertical
                  track-color="grey"
                  color="amber darken-2"
                  background-color="white"
                  >
                </v-slider>
            </v-menu>
            <v-menu
              top
              :offset-y="true"
              class="m-10 p-10"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-badge
                  :content="rate+'x'"
                  overlap
                  inline
                  color="amber darken-2"
                >
                  <v-btn icon
                    :disabled="audioLoading"
                    v-bind="attrs"
                    v-on="on"
                    color="darken-2"
                    rounded title="Rychlost přehrávání"
                  >
                    <v-icon>mdi-play-speed</v-icon>
                  </v-btn>
                </v-badge>
              </template>
              <v-list>
              <v-list-item>
                <v-btn icon @click="setRate(Math.round((rate - 0.1)*10)/10)"
                  :disabled="rate <= 0.5 ? true : false">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                {{ rate }}x
                <v-btn icon @click="setRate(Math.round((rate + 0.1)*10)/10)"
                  :disabled="rate >= 3 ? true : false">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-list-item>
              </v-list>
            </v-menu>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import VueHowler from 'vue-howler';

// more info about audio controls here:
// https://github.com/mickdekkers/vue-howler

export default {
  mixins: [VueHowler],
  data() {
    return {
      mutedPlayer: false,
      items: [
        { rate: 1 },
        { rate: 1.25 },
        { rate: 1.5 },
        { rate: 1.75 },
        { rate: 2 }
      ],
      icon: 'mdi-play',
      audioLoading: true
    };
  },
  methods: {
    changeSeek(val) {
      this.setSeek(this.duration * (val/100));
    },
    changeVolume(val) {
      this.setVolume(val/100);
    },
    mutePlayer() {
      if (this.mutedPlayer) {
        this.unmute();
        this.mutedPlayer = false;
      } else {
        this.mute();
        this.mutedPlayer = true;
      }
    }
  },
  computed: {
    getCurrentTime() {
      return '' + Math.floor(this.seek/3600) +
          ':' +
          Math.floor((this.seek%3600)/60).toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
          }) +
          ':' +
          Math.floor(this.seek%60).toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
          });
    },
    getDuration() {
      return + Math.floor(this.duration/3600) +
          ':' +
          Math.floor((this.duration%3600)/60).toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
          }) +
          ':' +
          Math.floor(this.duration%60).toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
          });
    }
  },
  watch: {
    duration() {
      if (this.duration > 0) {
        this.audioLoading = false;

        // with this we should be able to also check the buffer loaded for the audio for the future
        // https://stackoverflow.com/questions/37769535/is-there-a-way-to-indicate-audio-download-progress-buffer-when-using-howler-js
        // console.log('AAAAAA', this._data._howl._sounds[0]._node.buffered.length)
      } else {
        this.audioLoading = true;
      }
    }
  }
};
</script>
<style scoped>
/* all of this increase size of the main audio slider */
/* div /deep/ .v-slider__track-fill {
   border-radius: 3px !important
}
div /deep/ .v-slider__track-background {
   border-radius: 3px !important
}
div /deep/ .v-slider__track-container {
   height: 7px !important;
}
div /deep/ .v-slider__thumb {
    height: 13px !important;
    width: 13px !important;
  } */
</style>
