import 'regenerator-runtime/runtime';
import apiRequest from './apiRequest';
import { authHeader } from '../utils';

export const adminService = {
  updateAccesses,
  sendmail,
  getAllSharings
};

/**
 * Admin service used for the update user access.
 * @param {uid} uid user identifier,
 * @param {accesses} array of related accesses.
 */
function updateAccesses(id, accesses) {
  const request = {
    method: 'PUT',
    headers: authHeader(),
    body: {
      id,
      accesses
    }
  };
  return apiRequest('/api/admin/accesses', request);
};

/**
 * Sends email with the {subject} and {text} to recivers defined by {userIDs}.
 * @param {*} userIDs list of users ID
 * @param {*} subject subject of the email
 * @param {*} text text of the email.
 */
function sendmail(userIDs, subject, text) {
  const request = {
    method: 'POST',
    headers: authHeader(),
    body: {
      userIDs,
      subject,
      text
    }
  };
  return apiRequest('/api/admin/sendmail', request);
}

/**
 * Gets all sharings.
 */
function getAllSharings() {
  const request = {
    method: 'GET',
    headers: authHeader()
  };
  return apiRequest('/api/admin/sharings', request);
}
