<template>
  <div class="mt-2">
    <v-tabs color='amber' grow mobile-breakpoint>
      <v-tab class="px-2" title="Všechny zdroje ..."><v-icon class="mx-1">mdi-all-inclusive</v-icon><span class="d-none d-sm-flex">Vše</span></v-tab>
      <v-tab-item>
        <resource-media-tab filter='none' :noAccesses="noAccesses"></resource-media-tab>
      </v-tab-item>
      <v-tab title="Zdroje obsahující audio ..."><v-icon class="mx-1">mdi-music</v-icon><span class="d-none d-sm-flex">Audio</span></v-tab>
      <v-tab-item>
        <resource-media-tab filter='Audio' :noAccesses="noAccesses"></resource-media-tab>
      </v-tab-item>
      <v-tab title="Zdroje obsahující video ..."><v-icon class="mx-1">mdi-video</v-icon><span class="d-none d-sm-flex">Video</span></v-tab>
      <v-tab-item>
        <resource-media-tab filter='Video' :noAccesses="noAccesses"></resource-media-tab>
      </v-tab-item>
      <v-tab title="Zdroje obsahující dokumenty (např. prezenatce, studia apod.) ..."><v-icon class="mx-1">mdi-file-document-outline</v-icon><span class="d-none d-sm-flex">Dokumenty</span></v-tab>
      <v-tab-item>
        <resource-media-tab filter='Document' :noAccesses="noAccesses"></resource-media-tab>
      </v-tab-item>
   </v-tabs>
  </div>
</template>

<script>
import ResourceMediaTab from './ResourceMediaTab.vue';
import { mapActions } from 'vuex';
import { userService } from '../services/user.service';
import { sharingService } from '../services/sharings.service';

export default {
  components: { ResourceMediaTab },
  data() {
    return {
      noAccesses: false
    };
  },
  methods: {
    ...mapActions('resources', {
      storeSelected: 'storeSelected',
      storeIDs: 'storeIDs'
    }),
    ...mapActions('account', {
      updateUser: 'updateUser'
    }),
    ...mapActions('resources', {
      storeSharingCounts: 'storeSharingCounts'
    })
  },
  created() {
    // this is used to display notification about regitracion evaluation process
    // calling this method we also update the profile of user from strapi
    userService.getUserProfile(this.$route.params.id).then((user) => {
      this.noAccesses = user.noAccesses;
      // this will update user in the store. We user this user to display various
      // element of the user profile on the page
      this.updateUser(user);
    });

    sharingService.counts()
        .then((result) => {
          this.storeSharingCounts(result);
        });
  }
};

</script>
<style scoped>
.v-tab {
  min-width: 50px; /* This is needed for showing 4 tabs on smaler displays */
}
</style>
